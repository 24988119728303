import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";

import AppLayoutContainer from "../components/AppLayoutContainer";
import Dashboard from "../dashboard/Dashboard";
import TaskList from "../task/TaskList";
import TaskDetails from "../task/TaskDetails";
import ProjectItemList from "../item/ProjectItemList";
import ProjectList from "../project/ProjectList";
import ProjectDetails from "../project/ProjectDetails";
import ProjectDraft from "../project/ProjectDraft";
import ProjectTaskDetails from "../project/ProjectTaskDetails";
import Profile from "../profile/Profile";
import RoleList from "../role/RoleList";
import RoleDetails from "../role/RoleDetails";
import NotFound from "../components/NotFound";

import routes from "../utils/routes";
import FreelancerList from "../freelancer/FreelancerList";
import FreelancerDetails from "../freelancer/components/FreelancerDetails";

import UserList from "../users/UserList";
import { useUserPermissions } from "../utils/customHooks";
import ProjectArchived from "../project/ProjectArchived";
import Template from "../template/Template";
import TemplateDetails from "../template/TemplateDetails";
import DelayedItemList from "../item/DelayedItemList";
import InvoiceListing from "../invoicing/InvoiceListing";
import { useSelector } from "react-redux";
import constants from "../utils/constants";
import MemberDashboard from "../dashboard/MemberDashboard";
import AssessmentList from "../assessment/AssessmentList";
import AssessmentDetails from "../assessment/AssessmentDetails";
import OpenAi from "../openAi/OpenAi";
import OpenAiDashboard from "../openAi/OpenAiDashboard";
import OpenAiTemplates from "../openAi/OpenAiTemplates";
import OpenAiTaskList from "../openAi/OpenAiTaskList";
import GenerateMetaTag from "../openAi/GenerateMetaTag";
import GenerateSocialBookmarking from "../openAi/GenerateSocialBookmarking";
import GenerateBlog from "../openAi/GenerateBlog";
import GenerateQuoraQnA from "../openAi/GenerateQuoraQnA";
import OpenAiSocialMediaCopy from "../openAi/OpenAiSocialMediaCopy";
import OpenAiPaidCopy from "../openAi/OpenAiPaidCopy";
import OpenAiChat from "../openAi/OpenAiChat";
import CreateProject from "../project/CreateProject";
import Notifications from "../notifications/Notifications";
import KeywordGenerator from "../openAi/KeywordGenerator";
import Translator from "../openAi/Translator";
import GenerateBlogOutline from "../openAi/GenerateBlogOutline";
import GenerateBlogNew from "../openAi/GenerateBlogNew";
import GenerateLinkedin from "../openAi/GenerateLinkedin";
import GenerateInstagram from "../openAi/GenerateInstagram";
import GenerateTwitterAd from "../openAi/GenerateTwitterAd";
import BulkContentList from "../bulkContentCreation/BulkContentList";
import ContentDetails from "../bulkContentCreation/ContentDetails";
import ContentTaskDetails from "../bulkContentCreation/ContentTaskDetails";
import Testing from "../bulkContentCreation/Testing";
import GenerateReports from "../bulkContentCreation/GenerateReports";

const { ROLE_IDS } = constants;

const AppRoutes = () => {
  const mPermissions = useUserPermissions();
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const mRoutes = useMemo(() => {
    const { module } = mPermissions;
    const {
      dashboard,
      writerInvoice,
      requestorInvoice,
      taskDelayedList,
      onboardingRequest,
      taskList,
      users,
      database,
      projectList,
      projectDrafts,
      roles,
      assessments,
      openAi,
      bulkContent,
    } = module;

    const appRoutes = [
      {
        path: routes.TASK,
        element: <TaskList pathname={routes.TASK} />,
      },
      {
        path: routes.TASK_ID,
        element: <TaskDetails />,
      },
      {
        path: routes.USERS,
        element: <UserList />,
      },

      {
        path: routes.FREELANCER,
        element: <FreelancerList pathname={routes.FREELANCER} />,
      },
      {
        path: routes.FREELANCER_ID,
        element: <FreelancerDetails />,
      },
      {
        path: routes.PROJECT_TASK,
        element: <ProjectItemList />,
      },
      {
        path: routes.PROJECT,
        element: <ProjectList />,
      },
      {
        path: routes.PROJECT_DRAFT,
        element: <ProjectDraft />,
      },
      {
        path: routes.PROJECT_ARCHIVED,
        element: <ProjectArchived />,
      },
      {
        path: routes.PROJECT_DETAIL,
        element: <ProjectDetails />,
      },
      {
        path: routes.PROJECT_TASK_DETAIL,
        element: <ProjectTaskDetails />,
      },
      {
        path: routes.PROFILE,
        element: <Profile />,
      },

      {
        path: routes.TEMPLATE,
        element: <Template />,
      },
      {
        path: routes.TEMPLATE_DETAIL,
        element: <TemplateDetails />,
      },
      {
        path: routes.NOTIFICATION,
        element: <Notifications />,
      },
      // {
      //   path: routes.OPENAI,
      //   element: <OpenAi />,
      // },
      /* {
        path: routes.BULK_CONTENT_CREATION,
        element: <BulkContentList />,
      }, */
      {
        path: routes.CONTENT_DETAIL,
        element: <ContentDetails />,
      },
      {
        path: routes.CONTENT_TASK_DETAIL,
        element: <ContentTaskDetails />,
      },
      {
        path: routes.TESTING,
        element: <Testing />,
      },
      {
        path: routes.GENERATE_REPORTS,
        element: <GenerateReports />,
      },
    ];
    if (dashboard?.show) {
      appRoutes.push({
        path: routes.DASHBOARD,
        element:
          loggedInUser?.role_id === ROLE_IDS.FREELANCER ? (
            <MemberDashboard />
          ) : (
            <Dashboard />
          ),
      });
    }
    if (taskDelayedList?.show) {
      appRoutes.push({
        path: routes.PROJECT_TASK_DELAYED,
        element: <DelayedItemList />,
      });
    }
    if (writerInvoice?.show) {
      appRoutes.push({
        path: routes.INVOICE_WRITER,
        element: <InvoiceListing />,
      });
    }
    if (requestorInvoice?.show) {
      appRoutes.push({
        path: routes.INVOICE_REQUESTOR,
        element: <InvoiceListing />,
      });
    }
    if (roles?.show) {
      appRoutes.push({
        path: routes.ROLES,
        element: <RoleList />,
      });
      appRoutes.push({
        path: routes.ROLE_DETAILS,
        element: <RoleDetails />,
      });
    }
    if (assessments?.show) {
      appRoutes.push({
        path: routes.ASSESSMENTS,
        element: <AssessmentList />,
      });
      appRoutes.push({
        path: routes.ASSESSMENT_DETAILS,
        element: <AssessmentDetails />,
      });
      appRoutes.push({
        path: routes.ASSESSMENT_DETAILS + "/:id",
        element: <AssessmentDetails />,
      });
    }

    if (openAi?.openAiContentGeneration?.show) {
      appRoutes.push({
        path: routes.OPEN_AI,
        element: <OpenAiDashboard />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_DASHBOARD,
        element: <OpenAiDashboard />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_TEMPLATES,
        element: <OpenAiTemplates />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_TASKS,
        element: <OpenAiTaskList />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_SEO_META_TAG,
        element: <GenerateMetaTag />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_KEYWORD_GENERATOR,
        element: <KeywordGenerator />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_TRANSLATOR,
        element: <Translator />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_SOCIAL_BOOKMARKING,
        element: <GenerateSocialBookmarking />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_BLOG,
        element: <GenerateBlog />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_QUORA_QNA,
        element: <GenerateQuoraQnA />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_BLOG_OUTLINE,
        element: <GenerateBlogOutline />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_BLOG_NEW,
        element: <GenerateBlogNew />,
      });

      appRoutes.push({
        path: routes.OPEN_AI_LINKEDIN_PAID,
        element: <GenerateLinkedin />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_INSTAGRAM_PAID,
        element: <GenerateInstagram />,
      });
      appRoutes.push({
        path: routes.OPEN_AI_TWITTER_PAID,
        element: <GenerateTwitterAd />,
      });
      // appRoutes.push({
      //   path: routes.OPEN_AI_SOCIAL_MEDIA_COPY,
      //   element: <OpenAiSocialMediaCopy />,
      // });

      appRoutes.push({
        path: routes.OPEN_AI_PAID_AD_COPY,
        element: <OpenAiPaidCopy />,
      });
    }

    if (openAi?.openAiChat?.show) {
      appRoutes.push({
        path: routes.OPEN_AI_CHAT,
        element: <OpenAiChat />,
      });
    }

    if (mPermissions?.project?.create) {
      appRoutes.push({
        path: routes.CREATE_PROJECT_FLOW,
        element: <CreateProject />,
      });
    }

    /* if (bulkContent?.show) {
      appRoutes.push({
        path: routes.BULK_CONTENT_CREATION,
        element: <BulkContentList />,
      });
    } */

    if (bulkContent?.bulkProjectList?.show) {
      appRoutes.push({
        path: routes.BULK_CONTENT_CREATION,
        element: <BulkContentList />,
      });
    }

    if (bulkContent?.projectReport?.show) {
      appRoutes.push({
        path: routes.GENERATE_REPORTS,
        element: <BulkContentList />,
      });
    }

    appRoutes.map((route) => {
      route.path = route.path.replace(routes.APP, "");
      return route;
    });

    return appRoutes;
  }, [loggedInUser?.role_id, mPermissions]);

  return (
    <AppLayoutContainer>
      <Routes>
        {mRoutes.map((routeProps, i) => {
          //console.log(mRoutes, "mRoutes");
          return <Route key={i} {...routeProps} />;
        })}

        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </AppLayoutContainer>
  );
};

export default AppRoutes;
