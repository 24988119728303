import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Popover,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LeftOutlined,
  UserAddOutlined,
  UploadOutlined,
  DownloadOutlined,
  SearchOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import moment from "moment-timezone";

import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
// import ProjectTaskModal from "./components/ProjectTaskModal";

// import projectTaskListDetails from "../projectTaskListDetails";
import {
  disabledFutureDate,
  getAvatarColor,
  getNameInitials,
  getStringToOptions,
  showNotification,
} from "../utils/commonFunctions";
import User from "../models/User";
import constants from "../utils/constants";
import { useUserPermissions } from "../utils/customHooks";

// import "./ProjectDetails.scss";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { itemActions } from "../item/duck/itemReducer";
import PlusIcon from "../assets/images/plusIcon.svg";
import KanbanView from "../assets/images/KanbanView.svg";
import ListView from "../assets/images/ListView.svg";
import Insights from "../assets/images/Insights.png";

import CalendarIcon from "../assets/images/Calendar.svg";

import MyTaskFilter from "../components/MyTaskFilter";

import ProjectTaskModal from "../project/components/ProjectTaskModal";
import SelectManagerModal from "../project/components/SelectManagerModal";
import projectTaskListDetails from "../project/projectTaskListDetails";
import ShareWithModal from "../project/components/ShareWithModal";
import SelectClientModal from "../project/components/SelectClientModal";
import ProjectListView from "../project/components/ProjectListView";
import ProjectInsightDetails from "../project/components/ProjectInsightDetails";
import { projectActions } from "../project/duck/projectReducer";
import ContentTaskList from "./components/ContentTaskList";
import ContentTaskCard from "./components/ContentTaskCard";
import GenerateProjectListView from "./components/GenerateProjectListView";
import appUrl from "../config/appUrl";

const fieldsLabelToDisplay = [
  "Choose the industry of your project",
  "Choose the sub industry",
  "Choose language of the content",
  "Choose your preferred tone of content",
];

const {
  PROJECT_TASK_STATUS,
  PROJECT_STATUS,
  TASK_STATUS_OPTIONS,
  version,
  SEARCH_TYPE,
  FILTER_TYPE,
} = constants;
const defaultFilterType = FILTER_TYPE?.CREATED_DATE?._id;
const defaultView = "kanban";

const GenerateReports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { pathname } = useLocation();

  // const activeView = localStorage.getItem("projectView") || defaultView;
  // const deafultStatus = activeView === defaultView ? "incomplete" : null;

  const [showCreateTask, setShowCreateTask] = useState(false);
  const [showSelectManager, setShowSelectManager] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [showShareWithModal, setShowShareWithModal] = useState(false);
  // const [listBoardKey, setListBoardKey] = useState(null);
  // const [view, setView] = useState(activeView);
  const [state, setState] = useState({
    // status_description: "all",
    filterType: defaultFilterType,
    version: null,
  });
  const [filter, setFilter] = useState({});
  const [fileList, setFileList] = useState([]);
  const [bulkUploadLoading, setBulkUploadLoading] = useState(false);

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const mPermissions = useUserPermissions();

  // const projectObj = useSelector(({ project }) => project.map[id]);
  // const item = useSelector(({ item }) => item);
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios({
      url: appUrl.BULK_AI_PROJECT_GENERATE_REPORT,
      // params: params
    })
      .then((response) => {
        setLoader(false);
        if (response?.data?.meta?.success) {
          setData(response?.data?.data);
          // console.log(response?.data?.data?.version)
          // setState({...state, version: response?.data?.data?.version})
          showNotification("success", response?.data?.meta?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        showNotification("error", "Failed to fetch report");
      });
  }, []);

  const loadMore = (params) => {
    setLoading(true);
    axios({
      url: appUrl.BULK_AI_PROJECT_GENERATE_REPORT,
      params: params,
    })
      .then((response) => {
        setLoading(false);
        if (response?.data?.meta?.success) {
          setData(response?.data?.data);
          // console.log(response?.data?.data?.version)
          // setState({...state, version: response?.data?.data?.version})
          showNotification("success", response?.data?.meta?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        showNotification("error", "Failed to fetch report");
      });
  };

  const onReset = () => {
    setState({
      filterType: defaultFilterType,
    });
    loadMore();
  };

  // const reduxFilters = useMemo(
  //   () => item?.filters?.[pathname],
  //   [item?.filters, pathname]
  // );

  // useEffect(() => {
  //   if (reduxFilters) {
  //     if (view === "list") {
  //       setListBoardKey(reduxFilters);
  //       let parsedData = JSON.parse(reduxFilters);
  //       let filterType = FILTER_TYPE?.CREATED_BY?._id;
  //
  //       if (parsedData?.assigned_freelancer) {
  //         filterType = FILTER_TYPE?.WRITER?._id;
  //         parsedData.user = parsedData?.assigned_freelancer;
  //       } else if (parsedData?.current_assignee) {
  //         filterType = FILTER_TYPE?.ASSIGNED_TO?._id;
  //         parsedData.user = parsedData?.current_assignee;
  //       } else if (parsedData?.created_by) {
  //         parsedData.user = parsedData?.created_by;
  //       }
  //       if (parsedData?.start_date) {
  //         parsedData.start_date = moment(parsedData?.start_date);
  //         filterType = FILTER_TYPE?.CREATED_DATE?._id;
  //       }
  //       if (parsedData?.end_date) {
  //         parsedData.end_date = moment(parsedData?.end_date);
  //         filterType = FILTER_TYPE?.CREATED_DATE?._id;
  //       }
  //       const data = {
  //         task_name: "",
  //         filterType,
  //         ...parsedData,
  //       };
  //
  //       setState(data);
  //       setFilter(data);
  //     } else {
  //       let parsedData = JSON.parse(reduxFilters);
  //       if (parsedData?.start_date) {
  //         parsedData.start_date = moment(parsedData?.start_date);
  //       }
  //       if (parsedData?.end_date) {
  //         parsedData.end_date = moment(parsedData?.end_date);
  //       }
  //       const filter_status = parsedData?.status_description;
  //       let status_description = "";
  //       if (filter_status === "completed") {
  //         status_description = "incomplete";
  //       }
  //       if (filter_status === "approved") {
  //         status_description = "approved";
  //       }
  //
  //       const data = {
  //         task_name: "",
  //         ...parsedData,
  //         status_description,
  //       };
  //
  //       setState(data);
  //       setFilter(data);
  //     }
  //   }
  // }, []);

  // const projectTemplateForms = useMemo(
  //   () => projectObj?.template?.forms,
  //   [projectObj?.template?.forms]
  // );

  // const buttonValues = useMemo(() => {
  //   const { project_status } = state;
  //   const length = project_status?.length;
  //   let text = [];
  //   let value;
  //
  //   if (length === 0 || length === Object.keys(PROJECT_STATUS)?.length) {
  //     text = "All Version";
  //     value = 2;
  //     // meta?.totalCount;
  //   } else {
  //     value = project_status?.reduce((acc, value) => acc + (value ?? 0), 0);
  //     Object.values(PROJECT_STATUS).forEach((obj) => {
  //       if (project_status?.includes(obj._id)) text.push(obj.name);
  //     });
  //     text = text?.join(", ");
  //   }
  //
  //   return { text, value };
  // }, [state]);

  // const assignedManager = useMemo(
  //   () => new User().fromMap(projectObj?.assigned_manager),
  //   [projectObj?.assigned_manager]
  // );
  // const createdBy = useMemo(
  //   () => new User().fromMap(projectObj?.created_by),
  //   [projectObj?.created_by]
  // );
  // const assignedEditor = useMemo(
  //   () => new User().fromMap(projectObj?.assigned_editor),
  //   [projectObj?.assigned_editor]
  // );

  // const onFilter = useCallback(() => {
  //   setFilter((prevFilter) => ({ ...prevFilter, ...state }));
  // }, [state]);

  const onFilter = () => {
    // setFilter((prevFilter) => ({ ...prevFilter, ...state }));
    let params = {};
    if (state?.project_id) {
      params.project_id = state.project_id;
    }
    if (state?.status_description) {
      params.status_description = state.status_description;
    }
    if (state?.name) {
      params.name = state.name;
    }
    if (state?.version) {
      params.version = state.version;
    }
    if (state?.start_date) {
      params.start_date = new Date(state.start_date);
    }
    if (state?.end_date) {
      params.end_date = new Date(state.end_date);
    }
    // `?start_date=${params.start_date}&&start_date=${params.end_date}`
    loadMore(params);
  };

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;
      let newState = {
        ...state,
        [name]: value,
      };
      if (name === "filterType") {
        newState.user = null;
      }

      setState(newState);
    },
    [state]
  );

  // useEffect(() => {
  //   dispatch(projectActions.onGetOneRequest({ _id: id }));
  // }, [id, dispatch]);

  // const canEditManager = useMemo(
  //   () =>
  //     (assignedManager._id && loggedInUser?._id === assignedManager._id) ||
  //     loggedInUser?.role_id === constants.ROLE_IDS.SUPER_ADMIN ||
  //     loggedInUser?.role_id === constants.ROLE_IDS.ADMIN,
  //   [assignedManager, loggedInUser]
  // );
  // const isProjectEditable = useMemo(() => {
  //   let editable = true;
  //
  //   if (
  //     projectObj?.archive ||
  //     projectObj?.status_description === PROJECT_STATUS.CLOSED._id
  //   ) {
  //     editable = false;
  //   }
  //   return editable;
  // }, [projectObj?.archive, projectObj?.status_description]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  // const sharedWith = useMemo(() => {
  //   const mUser = [];
  //   projectObj?.sharedWith?.forEach((user) => {
  //     const userObj = new User().fromMap(user);
  //     mUser.push(userObj);
  //   });
  //   return mUser;
  // }, [projectObj?.sharedWith]);
  //
  // useEffect(() => {
  //   if (
  //     projectObj?.assigned_manager &&
  //     isProjectEditable &&
  //     !projectObj?.loading &&
  //     projectObj?.count?.total_items === 0
  //   )
  //     setShowCreateTask(true);
  // }, [
  //   isProjectEditable,
  //   projectObj?.assigned_manager,
  //   projectObj?.count?.total_items,
  //   projectObj?.loading,
  // ]);

  // // check if project is fetching
  // if (!projectObj?._id && projectObj?.loading) {
  //   return <Loading />;
  // }
  if (loader) {
    return <Loading />;
  }

  // check if project exist
  // else if (!projectObj?._id) {
  //   return <NotFound subTitle={"Sorry, this project does not exist."} />;
  // }
  console.log(state);
  return (
    <div className="content-body app-container project-details-container content-details">
      {/*  project details card start */}
      <div className="">
        <Row className="mb20" align="middle">
          <Row
            style={{ position: "relative" }}
            className="fw"
            justify={"space-between"}
          >
            <div className="page-heading set-text-icon">
              <LeftOutlined
                style={{
                  position: "relative",
                  left: 0,
                  top: 10,
                  fontSize: 15,
                  cursor: "pointer",
                }}
                onClick={onGoBack}
              />
              {/* {projectObj.name} */}
              Generate Report
            </div>
          </Row>
        </Row>

        <Row align="middle" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={20}>
            {/* project details section start */}
            <Col className="mt5" span={24}>
              {/* Industry Name | Sub-Industry Name | Language | Content Tone */}
              <p
                style={{
                  // maxWidth: 600,
                  whiteSpace: "pre-wrap",
                }}
              ></p>
            </Col>
            {/* project details section  end */}
          </Col>
        </Row>
      </div>

      {/*  project details card end */}
      {/*  project filter row start */}
      <div
        style={{ marginTop: "20px" }}
        className="project-insights-container bg-white p-10"
      >
        <ContentTaskCard data={data} />
      </div>

      <Row className="fw mt20" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Task Name"
            prefix={<SearchOutlined />}
            value={state?.name}
            onChange={handleChange("name")}
          />
        </Col>
        <>
          <Col xs={24} sm={12} md={12} lg={6} xl={4} xxl={3}>
            <Select
              className="new_select fw"
              size="large"
              placeholder="Version"
              dropdownMatchSelectWidth={false}
              value={state?.version}
              clearIcon={
                <CloseCircleOutlined
                  style={{
                    color: "#fff",
                    background: "#000",
                    fontSize: 13,
                  }}
                />
              }
              onChange={handleChange("version")}
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              showSearch
            >
              {data?.version?.map((option, i) => (
                <Select.Option key={i} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={4} xxl={3}>
            <Select
              className="new_select fw"
              size="large"
              placeholder="Project"
              dropdownMatchSelectWidth={false}
              value={state?.search_type}
              clearIcon={
                <CloseCircleOutlined
                  style={{
                    color: "#fff",
                    background: "#000",
                    fontSize: 13,
                  }}
                />
              }
              onChange={handleChange("project_id")}
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              showSearch
            >
              {data?.projectList?.map((option, i) => (
                <Select.Option key={option?._id} value={option?._id}>
                  {option?.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={5} xxl={4}>
            <MyTaskFilter
              handleChange={handleChange}
              state={{ project_id: "", ...state }}
            />
          </Col>
        </>

        <Col xs={24} sm={8} md={8} lg={6} xl={3} xxl={2}>
          <Select
            className="new_select fw"
            size="large"
            placeholder="Status"
            dropdownMatchSelectWidth={false}
            value={state?.status_description}
            clearIcon={
              <CloseCircleOutlined
                style={{
                  color: "#fff",
                  background: "#000",
                  fontSize: 13,
                }}
              />
            }
            onChange={handleChange("status_description")}
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
          >
            {Object.values(TASK_STATUS_OPTIONS).map((status) => (
              <Select.Option key={status._id} value={status._id}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={8} md={8} lg={4} xl={3} xxl={2}>
          <Button
            type="primary"
            className="new_button fw"
            size="large"
            onClick={onFilter}
            disabled={loading}
            icon={<RedoOutlined spin={loading} />}
          >
            Search
          </Button>
        </Col>
        <Col xs={24} sm={8} md={8} lg={4} xl={3} xxl={2}>
          <Button
            className="new_button fw"
            size="large"
            type="primary-outline"
            onClick={onReset}
          >
            Reset
          </Button>
        </Col>
      </Row>

      {/*  project filter row end */}
      <hr className="line pri-box-shadow" />

      <GenerateProjectListView
        // projectId={id}
        filter={filter}
        data={data}
        // listBoardKey={listBoardKey}
        // setListBoardKey={setListBoardKey}
      />

      {/*/!* create project task/assignment start *!/*/}
      {/*<ProjectTaskModal*/}
      {/*  visible={showCreateTask}*/}
      {/*  projectId={id}*/}
      {/*  handleModal={*/}
      {/*    ""*/}
      {/*    // handleCreateProjectTaskModal*/}
      {/*  }*/}
      {/*/>*/}
      {/* create project task/assignment end */}
      {/* select manager start */}
      {/*{canEditManager && (*/}
      {/*  <>*/}
      {/*    <SelectManagerModal*/}
      {/*      visible={showSelectManager}*/}
      {/*      projectId={id}*/}
      {/*      handleModal={() => setShowSelectManager(false)}*/}
      {/*      isProjectEditable={isProjectEditable}*/}
      {/*    />*/}
      {/*    <SelectClientModal*/}
      {/*      visible={showSelectClient}*/}
      {/*      projectId={id}*/}
      {/*      handleModal={() => setShowSelectClient(false)}*/}
      {/*      isProjectEditable={isProjectEditable}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
      {/* select manager end */}
      {/* Share with other start */}
      {/*{*/}
      {/*  <ShareWithModal*/}
      {/*    visible={showShareWithModal}*/}
      {/*    sharedWith={sharedWith}*/}
      {/*    projectId={id}*/}
      {/*    handleModal={() => setShowShareWithModal(false)}*/}
      {/*    isProjectEditable={isProjectEditable}*/}
      {/*  />*/}
      {/*}*/}
      {/* Share with other end */}
    </div>
  );
};

export default React.memo(GenerateReports);
