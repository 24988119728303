import { Input, Col, Row, Button } from "antd";
import React, { useState, useCallback, useMemo } from "react";
import logo from "../assets/images/logoBlack.svg";
import logoCreator from "../assets/images/writelyForCreator.svg";
import logoRequestor from "../assets/images/writelyForRequestor.svg";
import "./login.scss";
import { showNotification, validEmail } from "../utils/commonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import routes from "../utils/routes";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logoFile = useMemo(() => {
    switch (location.state?.from?.pathname) {
      case routes.CLIENT_LOGIN:
        return logoRequestor;

      case routes.FREELANCER_LOGIN:
        return logoCreator;

      default:
        return logo;
    }
  }, [location.state?.from]);

  const [email, setEmail] = useState("");
  const [err, handleError] = useState({ email: null });
  const [loading, setLoader] = useState(false);

  const handleRequest = useCallback(() => {
    if (validEmail(email)) {
      handleForgotPassword();
    } else {
      let emailError = null;
      if (email?.trim() === "") {
        emailError = "Email cannot be blank";
      } else if (!validEmail(email)) {
        emailError = "Email is not valid";
      }
      handleError({ email: emailError });
    }
  }, [email]);

  const handleForgotPassword = async () => {
    try {
      const userRes = await axios.post(AppUrl.FORGOT_PASSWORD, { email });
      let userData = userRes.data;

      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setLoader(false);
      } else {
        showNotification(
          "success",
          "Successfully sent reset link to the registered email."
        );
        setLoader(false);
        navigate(routes.LOGIN, { replace: true });
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  return (
    <div className="loginContainer">
      <div
        className="logo-container pointer"
        onClick={() => navigate(routes.ROOT)}
      >
        <img src={logoFile} alt="Writely" className="logo" />
      </div>
      <div className="container-step-1">
        <Row className="info mt20 mb20">
          Retrieve your account. Your will get password reset link on your
          registered email.
        </Row>

        <Col className="mb15">
          <Input
            className="fw"
            placeholder="Enter your email address"
            size="large"
            onChange={(e) => {
              setEmail(e.target.value);
              handleError({ email: null });
            }}
            onPressEnter={handleRequest}
            value={email}
          />
          <Row className="error ml5">{err.email}</Row>
          <Row justify="end">
            <span className="extra-content" onClick={() => navigate(-1)}>
              Back to Login
            </span>
          </Row>
        </Col>

        <Row className="mb10">
          <Button
            type="primary"
            block
            size="large"
            loading={loading}
            onClick={handleRequest}
            className="signup-btn mb20"
          >
            Submit
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
