import MSaga from "../../helper/MSaga";
import { itemActions } from "./itemReducer";
import appUrl from "../../config/appUrl";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { paramsToString, showNotification } from "../../utils/commonFunctions";
const { onGetAllSuccess, onGetAllFailure, onRatingsRequest, onUpdateOneSuccess, onUpdateOneFailure } = itemActions;

// Handle Your Todo request start
const handleGetAllApi = (params = {}) => {
  const suffix = params?.taskType ? "allTask" : "";
  return axios({
    method: "GET",
    url: `${appUrl.ITEMS}/${suffix}`,
    params,
  });
};

function* handleGetAll(action) {
  delete action.payload?.pathname;
  const filter = paramsToString(action.payload);
  try {
    const response = yield call(handleGetAllApi, action.payload);
    const { data } = response;
    data.filter = filter;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onGetAllFailure(data));
    } else {
      const offset = action.payload?.offset || 0;
      data.isReset = offset < 1;
      yield put(onGetAllSuccess(data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Get All", error);

    error.filter = paramsToString(action.payload);

    yield put(onGetAllFailure(error));
  }
}

// Handle item api start

// Handle ratings  start
const handleRatingsApi = (data = {}) => {
  return axios({
    method: "PUT",
    url: `${appUrl.ITEMS}/ratings/${data?._id}`,
    data,
  });
};

function* handleRatingsRequest(action) {
  try {
    const response = yield call(handleRatingsApi, action.payload);
    const { data } = response;
    data._id = action.payload?._id;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onUpdateOneFailure(data));
    } else {
      yield put(onUpdateOneSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("MSaga Update One", error);
    error._id = action.payload?._id;
    yield put(onUpdateOneFailure(error));
  }
}
// Handle ratings  end

const itemSaga = new MSaga({
  name: "item",
  apiEndPoint: appUrl.ITEMS,
  actions: itemActions,
  workers: {
    handleGetAll,
  },
  messages: {
    create: {
      success: "Task Created Successfully",
      error: "Error while creating task",
    },
    updateOne: {
      success: "Task Updated",
      error: "",
    },
    deleteOne: {
      success: "Task Deleted",
      error: "",
    },
  },
});

function* watchItemSaga() {
  yield fork(itemSaga.getWatchSaga());
  yield takeLatest(onRatingsRequest.type, handleRatingsRequest)
}

export default watchItemSaga;
