const KEYS = Object.freeze({
  ID: "_id",
  FID: "fid",
  INDEX: "index",
  LABEL: "label",
  VALUE: "value",
  OPTIONS: "options",
  MANDATORY: "mandatory",
  SECTION_ID: "section_id",
  TYPE: "type",
  READ_ONLY: "read_only",
  HELP_TEXT: "help_text",
});

class Field {
  _id;
  type;
  isReadOnly;
  helpText;
  label;
  value;
  options;
  isMandatory;
  sectionId;
  index;

  #getStringToOption = (string = "") => string.split("|");

  #getOptionToString = () => this.options.join("|");

  toMap = () => {
    const map = {};
    map[KEYS.ID] = this._id;
    map[KEYS.FID] = this._id;
    map[KEYS.READ_ONLY] = this.isReadOnly;
    map[KEYS.TYPE] = this.type;
    map[KEYS.LABEL] = this.label;
    map[KEYS.MANDATORY] = this.isMandatory;
    map[KEYS.INDEX] = this.index;
    map[KEYS.OPTIONS] = this.#getOptionToString();
    map[KEYS.SECTION_ID] = this.sectionId;
    map[KEYS.HELP_TEXT] = this.helpText;

    return map;
  };

  fromMap(map = {}) {
    if (map[KEYS.ID] || map[KEYS.FID]) this._id = map[KEYS.ID] ?? map[KEYS.FID];
    if (map[KEYS.READ_ONLY]) this.isReadOnly = map[KEYS.READ_ONLY];
    if (map[KEYS.TYPE]) this.type = map[KEYS.TYPE];
    if (map[KEYS.LABEL]) this.label = map[KEYS.LABEL];
    if (map[KEYS.MANDATORY]) this.isMandatory = map[KEYS.MANDATORY];
    if (map[KEYS.INDEX]) this.index = map[KEYS.INDEX];
    if (map[KEYS.OPTIONS])
      this.options = this.#getStringToOption(map[KEYS.OPTIONS]);
    if (map[KEYS.SECTION_ID]) this.sectionId = map[KEYS.SECTION_ID];
    if (map[KEYS.HELP_TEXT]) this.helpText = map[KEYS.HELP_TEXT];

    return this;
  }
}

const TYPES = Object.freeze({
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  DROPDOWN: "dropdown",
});

Field.TYPES = TYPES;
export default Field;
