import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row, Select, Button, Input, Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import { itemActions } from "../../item/duck/itemReducer";
import "./SelectFreelancerModal.scss";
import constants from "../../utils/constants";
import "./SelectFreelancerModal.scss";

const { PROJECT_TASK_STATUS, RATINGS_TAGS } = constants;
const { COMPLETED } = PROJECT_TASK_STATUS;

const TaskRatingModel = (props) => {
  const { visible, projectTaskId, editable } = props;

  const prevProps = useRef();
  const dispatch = useDispatch();

  const itemObj = useSelector(({ item }) => item.map[projectTaskId]);

  const [state, setState] = useState({
    tags: [],
  });
  const [error, setError] = useState({});

  const disabled = useMemo(
    () => itemObj?.status_description !== COMPLETED,
    [itemObj?.status_description]
  );

  useEffect(() => {
    if (visible) {
      if (prevProps.current?.loading && !itemObj?.loading) {
        if (!itemObj?.error) {
          props.handleModal();
        }
      } else if (itemObj?.ratings && !itemObj?.loading) {
        const { ratings, tags, feedback } = itemObj;
        setState({ ratings, tags, feedback });
      }
    } else {
      setState({ tags: [] });
      setError({});
    }

    return () => {
      prevProps.current = {
        visible: visible,
        loading: itemObj?.loading,
      };
    };
  }, [visible, itemObj?.loading, itemObj?.error, props, itemObj]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const handleTag = useCallback((tag) => {
    setState((prestate) => ({
      ...prestate,
      tags: prestate?.tags?.includes(tag)
        ? prestate?.tags?.filter((item) => item !== tag)
        : [...prestate?.tags, tag],
    }));
    setError({});
  }, []);

  const hasError = useCallback(() => {
    const { tags, ratings } = state;
    const error = {};

    if (!tags?.length) {
      error.tags = "Please select atleast one tag";
    }
    if (!ratings) {
      error.ratings = "Please provide rating for this task";
    }

    setError(error);

    return !!Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const { tags, ratings, feedback } = state;

      const payload = {
        _id: projectTaskId,
        tags,
        ratings,
      };
      if (feedback) {
        payload.feedback = feedback;
      }
      dispatch(itemActions.onRatingsRequest(payload));
    }
  }, [dispatch, hasError, projectTaskId, state]);

  const renderFooter = useMemo(() => {
    return !disabled ? (
      <Row className="p10">
        {editable && (
          <Button
            className="new_button mr5 bg-lime-500"
            type="primary"
            onClick={onSubmit}
            size="large"
            loading={itemObj?.loading}
          >
            Rate
          </Button>
        )}
        <Button
          className="new_button"
          type="primary-outline"
          onClick={props?.handleModal}
          size="large"
        >
          Skip
        </Button>
      </Row>
    ) : (
      <></>
    );
  }, [disabled, editable, onSubmit, itemObj?.loading, props?.handleModal]);

  return (
    <CustomModal
      className="select-freelancer-modal-container"
      visible={visible}
      title={"Feedback"}
      footer={renderFooter}
      onCancel={props.handleModal}
      width={650}
    >
      {/* price start */}
      <Col className="mb20">
        <Col className="mb5">
          <label className="label">Tags</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col>
          {itemObj?.tags?.length && disabled ? (
            <div className="popular-tags mt20">
              {itemObj?.tags?.map((tag) => (
                <div className="tag active" key={tag}>
                  <p>{tag}</p>
                </div>
              ))}
            </div>
          ) : (
            <>
              <Select
                className="fw"
                size="large"
                placeholder="Search Tag"
                mode="multiple"
                value={state?.tags}
                onChange={handleChange("tags")}
                allowClear
                showSearch
                disabled={itemObj?.loading}
              >
                {RATINGS_TAGS.map((tag) => (
                  <Select.Option key={tag} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
              <Row className="error mt5">{error?.tags}</Row>

              <div className="popular-tags mt20">
                {RATINGS_TAGS.slice(0, 4).map((tag) => (
                  <div
                    className={`tag ${
                      state?.tags?.includes(tag) ? "active" : ""
                    }`}
                    key={tag}
                    onClick={() => handleTag(tag)}
                  >
                    <p>{tag}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </Col>
      </Col>
      {/* price end */}

      {/* rate start */}
      <Col className="mb20">
        <Col className="mb5">
          <label className="label">Rating</label>
          <sup style={{ color: "red" }}>*</sup>
        </Col>

        <Col>
          <Rate
            disabled={itemObj?.loading || disabled}
            value={state?.ratings}
            onChange={handleChange("ratings")}
          />

          <Row className="error mt5">{error?.ratings}</Row>
        </Col>
      </Col>

      {/* rate end */}

      {/* Comment start */}
      <Col>
        <Col className="mb5">
          <label className="label">Comment</label>
        </Col>

        <Col>
          {itemObj?.feedback && disabled ? (
            itemObj?.feedback
          ) : (
            <>
              <Input.TextArea
                className="fw"
                placeholder="Feedback about the writer"
                autoSize={{ minRows: 4, maxRows: 6 }}
                value={state?.feedback}
                onChange={handleChange("feedback")}
                disabled={itemObj?.loading}
                allowClear
              />

              <Row className="error mt5">{error?.feedback}</Row>
            </>
          )}
        </Col>
      </Col>
      {/* Comment end */}
    </CustomModal>
  );
};

export default TaskRatingModel;
