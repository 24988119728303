import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "./duck/projectReducer";
import { paramsToString } from "../utils/commonFunctions";
import "./ProjectDraft.scss";
import MyTable from "../components/MyTable";
import ProjectDraftRow from "./components/ProjectDraftRow";
import { Button, Col, Input, Row } from "antd";
import { useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

const ProjectDraft = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [boardKey, setBoardKey] = useState("{draft: true}");
  const [state, setState] = useState({ search: "", callDebounce: true });

  const project = useSelector(({ project }) => project);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = project.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [project, boardKey]);

  const filters = useMemo(
    () => project?.filters?.[location?.pathname],
    [location?.pathname, project?.filters]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search } = state;

        const params = {
          pathname: location?.pathname,
          draft: true,
          offset,
          limit,
        };
        if (search) params.search = search;
        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, location?.pathname, meta?.totalCount, state]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({
        callDebounce: false,
        ...JSON.parse(filters),
      });
    } else {
      loadMore();
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!!list && !loading && state?.callDebounce) {
        loadMore();
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [state]);

  const handleChange = (name) => (event) => {
    let value = event?.target?.value ?? event;

    setState((preState) => ({
      ...preState,
      [name]: value,
      callDebounce: true,
    }));
  };

  const columns = useMemo(
    () => [
      { key: 1, title: "S.No.", style: { width: 0 } },
      { key: 2, title: "Name" },
      { key: 3, title: "Type" },
      { key: 4, title: "Created At" },
      { key: 6, title: "Action", style: { width: 0 } },
    ],
    []
  );

  return (
    <div className="content-body app-container project-draft-container">
      <Row justify="start" gutter={[10, 10]}>
        <Col xs={24} sm={10} md={8} lg={5} xl={4} xxl={3}>
          <Button className="new_button fw" type="primary" size="large">
            <Row align="middle" wrap={false}>
              <span className="project-counter project-counter-btn">
                {meta?.totalCount || 0}
              </span>
              &nbsp; Draft Projects
            </Row>
          </Button>
        </Col>
        <Col xs={24} sm={14} md={16} lg={12} xl={8} xxl={8}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search Project Name"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <ProjectDraftRow serialNo={i + 1} key={i} id={item} />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default React.memo(ProjectDraft);
