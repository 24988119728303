import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./ProjectItemList.scss";

import { projectActions } from "../project/duck/projectReducer";
import { paramsToString } from "../utils/commonFunctions";
import { itemActions } from "./duck/itemReducer";
import ProjectItemRow from "./components/ProjectItemRow";
import MyTable from "../components/MyTable";
import { useLocation } from "react-router-dom";

const DelayedItemList = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [state, setState] = useState({ search: "" });
  const [boardKey, setBoardKey] = useState("");
  const [isReset, setReset] = useState(false);

  const item = useSelector(({ item }) => item);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = item.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [boardKey, item.boards]);

  const filters = useMemo(
    () => item?.filters?.[pathname],
    [item?.filters, pathname]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search, project_id } = state;

        const params = {
          pathname,
          offset: offset,
          limit: limit,
          delayed: true,
        };
        if (search) params.search = search;
        if (project_id) params.project_id = project_id;

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, pathname, state]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({ ...JSON.parse(filters) });
    }
  }, []);

  useEffect(() => {
    if ((!boardKey && !filters) || isReset) {
      setReset(false);
      loadMore();
    }
  }, [boardKey, filters, isReset, loadMore]);

  const projectObj = useSelector(({ project }) => {
    const projectMap = project.map;

    const boards = project.boards;

    const mBoard = boards[JSON.stringify({ draft: false, archive: false })];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let projects = list.map((projectId) => projectMap[projectId]);
    projects = projects.filter((project) => project);

    return { projects, meta, loading };
  });

  const loadMoreProjects = useCallback(
    (offset = 0, limit = 10) => {
      if (projectObj?.loading) return;
      if (
        offset === 0 ||
        projectObj?.meta?.totalCount > projectObj?.projects?.length
      ) {
        const params = {
          draft: false,
          archive: false,
          offset,
          limit,
        };

        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      projectObj?.loading,
      projectObj?.meta?.totalCount,
      projectObj?.projects?.length,
    ]
  );

  useEffect(() => {
    if (!projectObj?.meta) {
      loadMoreProjects();
    } else if (
      !projectObj?.loading &&
      projectObj?.projects?.length < projectObj?.meta?.totalCount
    ) {
      loadMoreProjects(projectObj?.projects?.length);
    }
  }, [
    loadMoreProjects,
    projectObj?.loading,
    projectObj?.meta,
    projectObj?.meta?.totalCount,
    projectObj?.projects?.length,
  ]);

  const onResetFilter = useCallback(() => {
    setState({ search: "" });
    setBoardKey("");
    setReset(true);
  }, []);

  const handleChange = (name) => (event) => {
    let value = event?.target?.value ?? event;

    setState((preState) => ({ ...preState, [name]: value }));
  };

  const onFilterOption = useCallback((input, option) => {
    const project = option.props.project;
    const projectName = project?.name.toLowerCase();
    const mInput = input.toLowerCase();

    return projectName.indexOf(mInput) >= 0;
  }, []);

  const columns = [
    { key: 1, title: "Task Name" },
    { key: 2, title: "Project" },
    { key: 3, title: "Created By" },
    { key: 4, title: "Assigned To" },
    { key: 5, title: "Assigned Writer" },
    { key: 6, title: "Due Date" },
  ];

  return (
    <div className="content-body app-container project-item-list-container">
      <Row className="fw mt20" gutter={[10, 10]}>
        <Button className="new_button" type="primary" size="large">
          <Row align="middle" wrap={false}>
            <span className="project-counter project-counter-btn">
              {meta?.totalCount || 0}
            </span>
            &nbsp;Dealyed Task
          </Row>
        </Button>
        <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search Your Task Here"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
        <Col xs={24} sm={8} md={8} lg={5} xl={3} xxl={2}>
          <Select
            className="new_select fw"
            placeholder="Project"
            // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            size="large"
            dropdownMatchSelectWidth={false}
            value={state?.project_id}
            onChange={handleChange("project_id")}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={onFilterOption}
            showSearch
            allowClear
          >
            {projectObj?.projects?.map((project) => {
              return (
                <Select.Option
                  key={project?._id}
                  value={project?._id}
                  project={project}
                >
                  {project?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={7} md={8} lg={4} xl={2} xxl={2}>
          <Button
            className="new_button fw"
            type="primary"
            size="large"
            onClick={() => loadMore()}
          >
            Submit
          </Button>
        </Col>
        <Col xs={24} sm={7} md={8} lg={4} xl={2} xxl={2}>
          <Button
            className="new_button fw"
            type="primary-outline"
            size="large"
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <ProjectItemRow key={i} id={item} showWriter showDueDate />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default DelayedItemList;
