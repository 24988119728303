import MSaga from "../../helper/MSaga";
import appUrl from "../../config/appUrl";

import { templateActions } from "./templateReducer";

const templateSaga = new MSaga({
  name: "template",
  apiEndPoint: appUrl.TEMPLATE,
  actions: templateActions,
});

export default templateSaga.getWatchSaga();
