import React from 'react';
import { useDrag } from 'react-dnd';
import './formstyle.css';
import {
  AlignLeftOutlined,
  CalendarOutlined,
  CloudUploadOutlined,
  DownCircleOutlined,
  FontColorsOutlined,
  GlobalOutlined,
  NumberOutlined
} from '@ant-design/icons';
import { Row } from 'antd';

export default function FieldTypeList(props) {
  // eslint-disable-next-line prefer-const
  let { fields } = props

  return (
    <Row style={{ height: 'calc(50vh)', overflow: 'auto', marginTop: 20, marginLeft: 20 }}>
      {
        fields.map(f => <Field key={f.id} name={f.name} type={f.type} id={f.id} helpText={f.help_text} options={f.options} icon={chooseIcon(f.type)} />)
      }
    </Row>
  )
}

const chooseIcon = type => {
  switch (type) {
  case 'text': return <FontColorsOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'number': return <NumberOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'dropdown': return <DownCircleOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'textarea': return <AlignLeftOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'date': return <CalendarOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'url': return <GlobalOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  case 'file': return <CloudUploadOutlined style={{ fontSize: '20px', color: 'rgba(0,0,0,.65)' }}/>
  default: break
  }
}

const Field = ({ name, type, id, helpText, options, icon }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: 'field', id, dataType: type, helpText, options },
    type: 'field',
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
          console.log(dropResult)
        // alert(`You dropped ${item.name} into Section:  ${dropResult}`)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ opacity,
      // marginBottom: '20px',
      height: '40px',
      background: 'white',
      lineHeight: '40px',
      borderRadius: '5px',
      boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)',
      cursor: 'grab',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingLeft: '20px',
      textOverflow: 'ellipsis' }}
    >
      {icon}
      <span style={{ fontSize: '18px', margin: '0px 20px', verticalAlign: 'middle', color: 'rgba(0,0,0,.65)', textTransform: 'capitalize' }}>{name}</span>
    </div>
  )
}
