import MSlice from "../../helper/MSlice";

const itemSlice = new MSlice({
  name: "item",
  reducers: {
    onRatingsRequest: MSlice.handleLoading,
  }
});

export const itemActions = itemSlice.getActions();

export default itemSlice.getReducer();
