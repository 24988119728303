import { Tag } from "antd";
import constants from "../utils/constants";
const { PROJECT_STATUS, PROJECT_TASK_STATUS } = constants;

export const statusTransformer = (status = "", size = 12, textOnly = false) => {
  switch (status) {
    case PROJECT_TASK_STATUS.INITIATE:
      return textOnly ? (
        "To-Do"
      ) : (
        <Tag
          className="custom_tag"
          style={{ fontSize: size, textAlign: "center", width: "9rem" }}
          color="#583a94"
        >
          To-Do
        </Tag>
      );

    case PROJECT_TASK_STATUS.IN_PROGRESS:
      return textOnly ? (
        "In Progress"
      ) : (
        <Tag
          className="custom_tag"
          style={{ fontSize: size, textAlign: "center", width: "9rem" }}
          color="#f3af4b"
        >
          In Progress
        </Tag>
      );

    case PROJECT_TASK_STATUS.IN_REVIEW:
      return textOnly ? (
        "In Review"
      ) : (
        <Tag
          className="custom_tag"
          style={{ fontSize: size, textAlign: "center", width: "9rem" }}
          color="#ea605a"
        >
          In Review
        </Tag>
      );

    case PROJECT_TASK_STATUS.COMPLETED:
      return textOnly ? (
        "Completed"
      ) : (
        <Tag
          className="custom_tag"
          style={{ fontSize: size, textAlign: "center", width: "9rem" }}
          color="#00af82"
        >
          Completed
        </Tag>
      );
    case PROJECT_TASK_STATUS.APPROVED:
      return textOnly ? (
        "Accepted"
      ) : (
        <Tag
          className="custom_tag"
          style={{ fontSize: size, textAlign: "center", width: "9rem" }}
          color="#234f1e"
        >
          Accepted
        </Tag>
      );

    default:
      break;
  }
};
export const statusBgColor = (status = "") => {
  switch (status) {
    case PROJECT_TASK_STATUS.INITIATE:
      return "#583a94";

    case PROJECT_TASK_STATUS.IN_PROGRESS:
      return "#f3af4b";

    case PROJECT_TASK_STATUS.IN_REVIEW:
      return "#ea605a";

    case PROJECT_TASK_STATUS.COMPLETED:
      return "#00af82";

    case PROJECT_TASK_STATUS.APPROVED:
      return "#234f1e";

    default:
      break;
  }
};

export const projectStatusTransformer = (status = "", size = 12) => {
  switch (status) {
    case PROJECT_STATUS.QUEUED._id:
      return (
        <Tag
          style={{ fontSize: size, width: "9rem", textAlign: "center" }}
          color="#F3AF4B"
        >
          {PROJECT_STATUS.QUEUED.name}
        </Tag>
      );

    case PROJECT_STATUS.IN_PROGRESS._id:
      return (
        <Tag
          style={{ fontSize: size, width: "9rem", textAlign: "center" }}
          color="#00AF82"
        >
          {PROJECT_STATUS.IN_PROGRESS.name}
        </Tag>
      );

    case PROJECT_STATUS.CLOSED._id:
      return (
        <Tag
          style={{ fontSize: size, width: "9rem", textAlign: "center" }}
          color="#EA605A"
        >
          {PROJECT_STATUS.CLOSED.name}
        </Tag>
      );

    default:
      return <Tag style={{ fontSize: size }}>{status}</Tag>;
  }
};
