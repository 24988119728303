import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Col, Row, Skeleton, Tag, Tooltip } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import "./ToDoListContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardProjectColors,
  getAvatarColor,
  getNameInitials,
  paramsToString,
} from "../../utils/commonFunctions";
import { itemActions } from "../../item/duck/itemReducer";
import EmptyContainer from "../../components/EmptyContainer";
import moment from "moment-timezone";
import { statusTransformer } from "../../components/StatusTransformer";
import User from "../../models/User";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import constants from "../../utils/constants";

const { TASK_TYPE } = constants;

const OtherTaskListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [boardKey, setBoardKey] = useState("{}");

  const itemMap = useSelector(({ item }) => item.map);
  const { list, meta, loading } = useSelector(({ item }) => {
    const boards = item.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const onTaskDetails = useCallback(
    (id, project_id) => {
      const navigateTo = routes.PROJECT_TASK_DETAIL.replace(":id", id).replace(
        ":projectId",
        project_id
      );
      navigate(navigateTo);
    },
    [navigate]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 4) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset: offset,
          limit: limit,
          taskType: TASK_TYPE.OTHER_TASK._id,
        };

        const mBoardKey = paramsToString(params);
        setBoardKey(mBoardKey);
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount]
  );

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className="container pri-box-shadow">
      <div className="title-container pri-box-shadow">
        <p className="header-text">Other task ({meta?.totalCount})</p>
      </div>
      <div className="todo-list-container">
        {!meta?.totalCount && !loading && <EmptyContainer />}
        {loading ? (
          <Skeleton active={loading} paragraph={{ rows: 9 }} />
        ) : (
          <>
            {list?.map((id, index) => {
              const itemObj = itemMap?.[id];
              const createdBy = new User().fromMap(itemObj?.created_by);
              const assignedBy = new User().fromMap(itemObj?.assigned_by);

              return (
                <div
                  className="task pointer"
                  key={id}
                  onClick={() => onTaskDetails(id, itemObj?.project_id?._id)}
                >
                  <Tooltip
                    title={
                      itemObj?.project_id?.name?.length > 35
                        ? itemObj?.project_id?.name
                        : ""
                    }
                  >
                    <Tag
                      className="overflow-ellipses-tag"
                      color={dashboardProjectColors[index]}
                    >
                      {itemObj?.project_id?.name}
                    </Tag>
                  </Tooltip>
                  <Row
                    align="middle"
                    justify="space-between"
                    className="mt15 mb15"
                    wrap={false}
                  >
                    <Row align="stretch">
                      <Col>
                        <Tooltip
                          title={
                            itemObj?.fields?.[0]?.value?.length > 30
                              ? itemObj?.fields?.[0]?.value
                              : ""
                          }
                        >
                          <p className="overflow-ellipses-tag pri-color">
                            {itemObj?.fields?.[0].value}
                            <span className="low-opacity">
                              &nbsp; #{itemObj?.task_id}
                            </span>
                          </p>
                        </Tooltip>
                        <span className="low-opacity">
                          <CalendarOutlined className="mr10" /> Created on{" "}
                          {moment(itemObj?.created_at).isValid()
                            ? moment(itemObj?.created_at).format("MMM Do, YYYY")
                            : "-"}
                        </span>
                      </Col>
                    </Row>

                    {statusTransformer(itemObj?.status_description)}
                  </Row>
                  <Row justify="start" gutter={24}>
                    <Col>
                      <span className="low-opacity mb5">Created By</span>
                      <Row align="middle">
                        <Col className="mr5">
                          <Avatar
                            size={30}
                            shape="circle"
                            style={{
                              backgroundColor: getAvatarColor(createdBy?._id),
                            }}
                          >
                            {getNameInitials(createdBy?.fullName)}
                          </Avatar>
                        </Col>

                        <Row className="name-container" align="stretch">
                          <Col>
                            <p className="nl">{createdBy?.fullName}</p>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    {itemObj?.assigned_by && (
                      <Col>
                        <span className="low-opacity mb5">Assigned By</span>
                        <Row align="middle">
                          <Col className="mr5">
                            <Avatar
                              size={30}
                              shape="circle"
                              style={{
                                backgroundColor: getAvatarColor(
                                  assignedBy?._id
                                ),
                              }}
                            >
                              {getNameInitials(assignedBy?.fullName)}
                            </Avatar>
                          </Col>

                          <Row className="name-container" align="stretch">
                            <Col>
                              <p className="nl">{assignedBy?.fullName}</p>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default OtherTaskListContainer;
