import { Badge, Row } from "antd";
import moment from "moment-timezone";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NameWithAvatar from "../../components/NameWithAvatar";
import User from "../../models/User";
import constants from "../../utils/constants";
import routes from "../../utils/routes";
import { notificationActions } from "../duck/notificationReducer";
const { NOTIFICATION_TYPES } = constants;

const NotificationTransformer = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mNotification = useSelector(
    ({ notification }) => notification?.map?.[item]
  );

  const { author, meta, model_id } = mNotification;
  const mAuthor = useMemo(() => new User().fromMap(author), [author]);

  let message = mNotification?.body?.replaceAll(
    author?._id,
    author?.first_name
  );

  message = message?.replaceAll(model_id?._id, model_id?.first_name);

  if (meta?.project_id) {
    message = message?.replaceAll(
      meta?.project_id?._id,
      meta?.project_id?.name
    );
  }
  if (meta?.task_id) {
    message = message?.replaceAll(
      meta?.task_id?._id,
      `#${meta?.task_id?.task_id}`
    );
  }

  const handleClick = useCallback(() => {
    let navigateTo;

    switch (meta?.type) {
      case NOTIFICATION_TYPES?.ASSIGNEDTASK:
      case NOTIFICATION_TYPES?.MENTIONINTASK:
      case NOTIFICATION_TYPES?.TASKCOMPLETION:
        navigateTo = routes.PROJECT_TASK_DETAIL.replace(
          ":id",
          meta?.task_id?._id
        ).replace(":projectId", meta?.task_id?.project_id);
        break;

      case NOTIFICATION_TYPES?.PROJECTASSIGNED:
      case NOTIFICATION_TYPES?.BULKTASKUPLOAD:
      case NOTIFICATION_TYPES?.SHAREDWITHPROJECT:
        navigateTo = routes.PROJECT_DETAIL.replace(
          ":id",
          meta?.project_id?._id
        );
        break;

      case NOTIFICATION_TYPES?.STARTASSESSMENT:
        navigateTo = `${routes.DASHBOARD}?tab=assessment`;
        break;
      case NOTIFICATION_TYPES?.SUBMITASSESSMENT:
        navigateTo = `${routes.FREELANCER_ID.replace(
          ":id",
          author?._id
        )}?tab=assessment`;
        break;

      default:
        break;
    }
    if (!meta?.is_read) {
      dispatch(
        notificationActions.onUpdateOneRequest({
          _id: item,
          is_read: true,
        })
      );
    }

    if (navigateTo) {
      navigate(navigateTo);
    }
    // window.document.querySelectorAll("#notification-popup")?.[0]?.parentElement?.parentElement?.classList?.toggle('ant-popover-hidden');

  }, [
    author?._id,
    dispatch,
    item,
    meta?.is_read,
    meta?.project_id?._id,
    meta?.task_id?._id,
    meta?.task_id?.project_id,
    meta?.type,
    navigate,
  ]);

  return (
    <div
      className={`notification pointer ${!meta?.is_read ? "unread" : ""}`}
      key={mNotification?._id}
      onClick={handleClick}
      style={{ position: "relative" }}
    >
      <Row align="top" className="fw" wrap={false}>
        <NameWithAvatar user={mAuthor} avatarOnly avatarSize={45} />
        <div className="ml20 fw">
          <p className="sec-bold of-ellipse-line-2">{message}</p>
          <span className="pri-light">
            {moment(mNotification?.timestamp)?.format("Do MMM YYYY - HH:MM A")}
          </span>
        </div>
        {!meta?.is_read && (
          <Badge
            color="#0C66C2"
            style={{ position: "absolute", left: 15, top: "20%" }}
          />
        )}
      </Row>
    </div>
  );
};

export default NotificationTransformer;
