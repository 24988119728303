const rolePermissions = {
  project: {
    showAll: false,
    create: false,
  },
  task: {
    showAll: false,
    create: false,
  },

  module: {
    dashboard: {
      name: "Dashboard",
      show: false,
      editable: false,
    },
    onboardingRequest: {
      name: "Onboarding Request",
      show: false,
      editable: false,
    },
    taskList: {
      name: "Task List",
      show: false,
      editable: false,
    },
    taskDelayedList: {
      name: "Task Delayed",
      show: false,
      editable: false,
    },
    users: {
      name: "Users",
      show: false,
      editable: false,
    },
    database: {
      name: "Freelancer",
      show: false,
      editable: false,
    },
    projectList: {
      name: "Project List",
      show: false,
      editable: false,
    },
    projectDrafts: {
      name: "Project Drafts",
      show: false,
      editable: false,
    },
    projectArchived: {
      name: "Project Archived",
      show: false,
      editable: false,
    },
    writerInvoice: {
      name: "Writer Invoice",
      show: false,
      editable: false,
    },
    requestorInvoice: {
      name: "Requestor Invoice",
      show: false,
      editable: false,
    },
    roles: {
      name: "Role Management",
      show: false,
      editable: false,
    },
    assessments: {
      name: "Assessment QNA",
      show: false,
      editable: false,
    },
    openAi: {
      name: "Writely Bot",
      show: false,
      editable: false,
      openAiContentGeneration: {
        name: "Content Generation",
        show: false,
        editable: false,
      },
      openAiChat: {
        name: "Chat Bot",
        show: false,
        editable: false,
      },
    },

    bulkContent: {
      name: "Bulk Content",
      show: false,
      editable: false,

      bulkProjectList: {
        name: "Bulk Project List",
        show: false,
        editable: false,
      },

      projectReport: {
        name: "Project Report",
        show: false,
        editable: false,
      },
    },
  },
};

export default Object.freeze(rolePermissions);
