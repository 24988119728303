import { Tag } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import User from "../../models/User";
import { EditOutlined } from "@ant-design/icons";
import roleTransformer from "../../components/RoleTransformer";

const UserItemRow = (props) => {
  const { handleInviteUserModal, id, serialNo } = props;
  const userObj = useSelector(({ user }) => user.map?.[id]);
  const mUser = useMemo(() => new User().fromMap(userObj), [userObj]);
  return (
    <tr>
      <td style={{ textAlign: "center" }}>{serialNo}</td>
      <td>{mUser?.fullName}</td>
      <td>{mUser?.email}</td>
      <td> {roleTransformer(userObj?.role_id)}</td>
      <td>
        {userObj?.active ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <EditOutlined
          onClick={() => handleInviteUserModal(true, id)}
          className="pointer"
        />
      </td>
    </tr>
  );
};

export default UserItemRow;
