import React, { useMemo } from "react";
import { Card, Col, Skeleton } from "antd";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { arrayToMap } from "../../utils/commonFunctions";

const OpenAiTaskCard = (props) => {
  const { _id = "", loading = false } = props;

  const mTask = useSelector(({ openAi }) => openAi.map[_id]);

  const { taskName, contentType, content, filename, createdAt } =
    useMemo(() => {
      const fields = arrayToMap(mTask?.fields);
      const filename = mTask?.attachment?.filename;

      return {
        taskName:
          mTask?.name || fields?.task_name?.value || filename || "Untitled",
        contentType: fields?.content_type?.value,
        content: fields?.content?.value?.replace(/(<([^>]+)>)/gi, " "),
        filename: mTask?.attachment?.filename,
        createdAt: moment(mTask?.created_at).format("DD MMM YYYY"),
      };
    }, [mTask]);

  if (!mTask && !loading) return null;

  return (
    <Col
      className="task-card-container"
      xs={24}
      sm={12}
      md={12}
      lg={8}
      xl={6}
      xxl={4}
    >
      <Card
        className="task-item-card container-shadow h-56 overflow-hidden pointer fw"
        bordered={false}
        title={
          !loading && (
            <>
              <div>
                <div className="text-base font-semibold truncate mr-2">
                  {taskName}
                </div>

                <div className="text-sm font-medium opacity-70">
                  {createdAt}
                </div>
              </div>
            </>
          )
        }
        onClick={() => _id && props.onShowDetails?.(true, _id)}
      >
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 5 }}
        >
          <div className="mb-2.5">
            <div className="text-sm font-medium opacity-70 uppercase">
              Content Type
            </div>
            <div className="text-sm font-semibold">{contentType}</div>
          </div>

          {content ? (
            <div className="mb-2.5">
              <div className="text-sm font-medium opacity-70 uppercase">
                Content
              </div>

              <div className="text-sm font-semibold line-clamp-3">
                {content || "-"}
              </div>
            </div>
          ) : (
            <div className="mb-2.5">
              <div className="text-sm font-medium opacity-70 uppercase">
                Attachment
              </div>

              <div className="text-sm font-semibold line-clamp-3">
                {filename || "-"}
              </div>
            </div>
          )}
        </Skeleton>
      </Card>
    </Col>
  );
};

export default React.memo(OpenAiTaskCard);
