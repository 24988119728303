import React from "react";
import { useState, useCallback } from "react";
import { Button, Input, Row } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import routes from "../utils/routes";
import AppUrl from "../config/appUrl";
import "./login.scss";
import logo from "../assets/images/logoBlack.svg";
import {
  isAuthenticated,
  showNotification,
  validPassword,
} from "../utils/commonFunctions";

const ResetPassword = () => {
  const token = useLocation()?.search?.split("=")?.[1];
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [err, handleError] = useState({
    password: null,
    confirmPassword: null,
  });
  const [loading, setLoader] = useState(false);

  const resetPassword = useCallback(() => {
    if (validPassword(password) && confirmPassword === password) {
      resetPasswordRequest({ password });
    } else {
      let passErr = null;
      let confirmpassErr = null;
      if (password?.trim() === "") {
        passErr = "Password cannot be blank";
      } else if (!validPassword(password)) {
        passErr = "Password length should be of minimum 4 characters";
      }
      if (confirmPassword?.trim() === "") {
        confirmpassErr = "Confirm password cannot be blank";
      } else {
        confirmpassErr = "Password did not match";
      }

      handleError({ password: passErr, confirmPassword: confirmpassErr });
    }
  }, [password, confirmPassword]);

  const resetPasswordRequest = async () => {
    setLoader(true);
    try {
      const userRes = await axios.post(AppUrl.RESET_PASSWORD, {
        password,
        link: token,
      });
      let userData = userRes.data;
      if (userData?.meta?.success === false || userData?.meta?.status !== 200) {
        showNotification("error", userData?.meta?.message);
        setLoader(false);
      } else {
        showNotification("success", "Successfully set new password!");
        localStorage.setItem("accessToken", userData?.data?.token);
        navigate(routes.DASHBOARD, {
          state: { autoNavigate: true },
          replace: true,
        });
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  if (isAuthenticated()) {
    return (
      <Navigate to={routes.DASHBOARD} state={{ autoNavigate: true }} replace />
    );
  }

  return (
    <div className="loginContainer">
      <div className="logo-container pointer">
        <img src={logo} alt="Writely" className="logo" />
      </div>

      <div className="container-step-1">
        <Row className="info mt20 mb20">Set your new password</Row>

        <Row className="input-container mb20">
          <label>New Password</label>
          <Input
            placeholder="New Password"
            size="large"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              handleError({ password: null });
            }}
            value={password}
            onPressEnter={resetPassword}
            autoFocus
          />
          <Row className="error">{err.password}</Row>
        </Row>
        <Row className="input-container mb20">
          <label>Confirm Password</label>
          <Input
            placeholder="Confirm Password"
            size="large"
            type="password"
            onChange={(e) => {
              setconfirmPassword(e.target.value);
              handleError({ password: null });
            }}
            value={confirmPassword}
            onPressEnter={resetPassword}
          />
          <Row className="error">{err.confirmPassword}</Row>
        </Row>

        {/* Buttons Container Start */}
        <Button
          type="primary"
          block
          onClick={resetPassword}
          loading={loading}
          size="large"
          className="signup-btn mb20"
        >
          Reset
        </Button>

        {/* Buttons Container End */}
      </div>
    </div>
  );
};

export default ResetPassword;
