import moment from "moment";
import User from "./User";
import Attachment from "./Attachment";

const KEYS = Object.freeze({
  ID: "_id",
  BODY: "body",
  MODEL_ID: "model_id",
  ON_MODEL: "onModel",
  STATUS: "status",
  CREATED_AT: "timestamp",
  CREATED_BY: "author",
  ATTACHMENTS: "attachments",
  FILE: "file",
  META: "meta",
});

class Comment {
  _id;
  body = "";
  modelId = "";
  onModel = "";
  attachments = [];
  createdAt = moment();
  createdBy = new User();
  meta = {};

  toMap = () => {
    const map = {};
    map[KEYS.ID] = this._id;

    return map;
  };

  fromMap = (map = {}) => {
    if (map[KEYS.ID]) this._id = map[KEYS.ID];
    if (map[KEYS.BODY]) this.body = map[KEYS.BODY];
    if (map[KEYS.META]) this.meta = map[KEYS.META];
    if (map[KEYS.CREATED_AT]) this.createdAt = moment(map[KEYS.CREATED_AT]);
    if (map[KEYS.CREATED_BY]) this.createdBy.fromMap(map[KEYS.CREATED_BY]);
    if (map[KEYS.MODEL_ID])
      this.modelId = new User().fromMap(map[KEYS.MODEL_ID]);
    if (map[KEYS.ON_MODEL]) this.onModel = map[KEYS.ON_MODEL];
    if (Array.isArray(map[KEYS.ATTACHMENTS])) {
      this.attachments = map[KEYS.ATTACHMENTS].map((attachment) => {
        const mAttachment = new Attachment();
        mAttachment.fromMap(attachment);
        return mAttachment._id ? mAttachment : null;
      });

      this.attachments = this.attachments.filter((attachment) => attachment);
    }
  };
}

export default Comment;
