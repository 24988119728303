import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import CustomModal from "../../components/CustomModal";

const OPTIONS = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];

const QNAModal = (props) => {
  const { visible, selectedObj, showModal, handleQNA } = props;

  const modalTitle = useMemo(
    () => (selectedObj ? "Edit Question" : "Add Question"),
    [selectedObj]
  );

  const [qnaObj, setQNAObj] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setError({});
    setQNAObj({
      question: selectedObj?.question ?? "",
      options: selectedObj?.options ?? {},
      correct_option: selectedObj?.correct_option,
      time: selectedObj?.time,
      marks: selectedObj?.marks,
    });
  }, [
    visible,
    selectedObj?.correct_option,
    selectedObj?.marks,
    selectedObj?.options,
    selectedObj?.question,
    selectedObj?.time,
  ]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value;
      value = e?.target?.value ?? e;

      if (name === "time") {
        value = value.replace(/[^.0-9]/g, "");
        value = Number(value);
      } else if (name === "marks") {
        value = value.replace(/[^.0-9]/g, "");
      }

      setQNAObj((preState) => ({ ...preState, [name]: value }));

      setError({});
    },
    []
  );
  const handleOption = useCallback(
    (name) => (e) => {
      let value;
      value = e?.target?.value ?? e;

      setQNAObj((preState) => ({
        ...preState,
        options: {
          ...preState?.options,
          [name]: value,
        },
      }));
      setError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { question, options, time, marks, correct_option } = qnaObj;
    question = question?.trim?.();

    const error = {};

    if (!question) {
      error.question = "Question cannot be blank";
    }
    OPTIONS?.slice(0, 1).forEach((option, i) => {
      const trimmedOption = options?.[option]?.trim?.();
      if (!trimmedOption) {
        error[option] = "Please provide atleast one option in this field";
      }
    });

    if (!correct_option) {
      error.correct_option = "Select correct option";
    }

    if (typeof time !== "number") {
      error.time = "Please enter time";
    } else if (Number(time) <= 0) {
      error.time = "Time must be greater than 0";
    }
    if (typeof time !== "number") {
      error.marks = "Please enter marks";
    } else if (isNaN(Number(marks)) || Number(marks) <= 0) {
      error.marks = "Marks must be greater than 0";
    }

    setError(error);
    return Object.keys(error).length;
  }, [qnaObj]);

  const add = useCallback(() => {
    if (!hasError()) {
      let payload = { ...qnaObj };
      payload.marks = Number(payload.marks);
      if (selectedObj && selectedObj.index !== undefined)
        payload.index = selectedObj.index;
      handleQNA(payload);
      showModal(false);
    }
  }, [handleQNA, hasError, qnaObj, selectedObj, showModal]);

  let { question, options, time, correct_option, marks } = qnaObj;

  return (
    <CustomModal
      visible={visible}
      title={modalTitle}
      onCancel={showModal}
      width={800}
      footer={
        <>
          <Button onClick={showModal}>Close</Button>
          <Button type="primary" onClick={add}>
            {selectedObj ? "Update" : "Add"}
          </Button>
        </>
      }
    >
      <div>
        {/* Question start */}
        <Row align="middle" className="mb10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Question </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <Input.TextArea
              className="input input-area"
              placeholder={"Your question here?"}
              value={question}
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={handleChange("question")}
            />

            <Row className="error mt5">{error?.question}</Row>
          </Col>
        </Row>
        {/* Question end */}

        {/* Option start */}
        {OPTIONS.map((option) => (
          <Row align="middle" className="mt5" key={option}>
            <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
              <label className="label">{option}</label>
            </Col>

            <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
              <Input
                className="custom-input"
                placeholder={`${option} here`}
                type="text"
                value={options?.[option]}
                onChange={handleOption(option)}
              />

              <Row className="error mt5">{error?.[option]}</Row>
            </Col>
          </Row>
        ))}
        {/* Option end */}

        {/* Answer start */}
        <Row align="middle" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Correct Option</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <Select
              className="custom-select fw"
              size="large"
              value={correct_option}
              placeholder="Select correct option"
              onChange={handleChange("correct_option")}
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
            >
              {OPTIONS.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{error?.correct_option}</Row>
          </Col>
        </Row>
        {/* Answer end */}

        {/* time start */}
        <Row align="middle" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Time (in seconds)</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <Input
              className="custom-input"
              placeholder="30"
              type="text"
              value={time}
              onChange={handleChange("time")}
            />
            <Row className="error mt5">{error?.time}</Row>
          </Col>
        </Row>
        {/* time end */}

        {/* Marks start */}
        <Row align="middle" className="mt10">
          <Col span={6} xs={24} sm={24} lg={6} xl={5} xxl={4}>
            <label className="label">Marks </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col span={18} xs={24} sm={24} lg={18} xl={19} xxl={20}>
            <Input
              placeholder="4"
              className="custom-input"
              type="text"
              value={marks}
              onChange={handleChange("marks")}
            />
            <Row className="error mt5">{error?.marks}</Row>
          </Col>
        </Row>
        {/* Marks end */}
      </div>
    </CustomModal>
  );
};

export default QNAModal;
