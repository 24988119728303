import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import constants from "../utils/constants";
import { Button, DatePicker, Popover, Select, Tag } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { disabledFutureDate } from "../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { paramsToString } from "../utils/commonFunctions";
import { userActions } from "../users/duck/userReducer";
import User from "../models/User";
import roleTransformer from "./RoleTransformer";

const { FILTER_TYPE, ROLE_IDS } = constants;

const MyTaskFilter = (props) => {
  const { handleChange = () => {}, state = {} } = props;

  const dispatch = useDispatch();
  const prevProps = useRef();

  const [usersBoardKey, setUsersBoardKey] = useState("");

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const usersObj = useSelector(({ user }) => {
    const userMap = user.map;

    const boards = user.boards;
    const mBoard = boards[usersBoardKey];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let users = list.map((userId) => userMap[userId]);
    users = users.filter((user) => user);

    return { users, meta, loading };
  });

  const roleIds = useMemo(() => {
    const ids = [
      ROLE_IDS.EDITOR,
      ROLE_IDS.MANAGER,
      ROLE_IDS.SUPER_ADMIN,
      ROLE_IDS.ADMIN,
      ROLE_IDS.CLIENT,
    ];
    if (loggedInUser?.role_id !== ROLE_IDS.FREELANCER) {
      ids.push(ROLE_IDS.FREELANCER);
    }

    return ids;
  }, [loggedInUser?.role_id]);

  const loadMoreUsers = useCallback(
    (offset = 0, limit = 30) => {
      if (
        offset === 0 ||
        usersObj?.meta?.totalCount > usersObj?.users?.length
      ) {
        const { project_id, filterType } = state;
        const params = {
          role_id: roleIds,
          offset,
          limit,
        };
        if (project_id) {
          params.project_id = project_id;
          if (filterType !== FILTER_TYPE.CREATED_DATE?._id) {
            params.filter_type = filterType;
          }
        }

        setUsersBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      roleIds,
      state,
      usersObj?.meta?.totalCount,
      usersObj?.users?.length,
    ]
  );

  useEffect(() => {
    loadMoreUsers(usersObj?.users?.length, usersObj?.meta?.totalCount);
  }, [usersObj?.meta?.totalCount, usersObj?.users?.length]);

  useEffect(() => {
    if (
      state?.project_id ||
      (prevProps.current?.project_id && !state?.project_id)
    ) {
      loadMoreUsers();
    }

    return () => {
      prevProps.current = {
        project_id: state?.project_id,
      };
    };
  }, [state?.project_id, state?.filterType]);

  const onUserFilterOption = useCallback((input, option) => {
    const user = option.props.user;
    const fullName = user.fullName.toLowerCase();
    const email = user.email.toLowerCase();
    const mInput = input.toLowerCase();

    return fullName.indexOf(mInput) >= 0 || email.indexOf(mInput) >= 0;
  }, []);

  return (
    <Popover
      getPopupContainer={(triggerNode) => triggerNode}
      bordered={false}
      title={null}
      trigger="click"
      placement="bottom"
      content={
        <div style={{ display: "flex", gap: 15 }}>
          <Select
            size="large"
            placeholder={"Status"}
            dropdownMatchSelectWidth={false}
            value={state?.filterType}
            onChange={handleChange("filterType")}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
              <Select.Option key={FILTER_TYPE?.CREATED_DATE?._id} value={FILTER_TYPE?.CREATED_DATE?._id}>
                {FILTER_TYPE?.CREATED_DATE?.name}
              </Select.Option>
          </Select>
          {/*{state?.filterType === FILTER_TYPE?.CREATED_DATE?._id ? (*/}
            <>
              <DatePicker
                style={{ width: 170 }}
                onChange={handleChange("start_date")}
                placeholder="From"
                value={state?.start_date}
                disabledDate={disabledFutureDate}
                inputReadOnly={true}
                allowClear
              />
              <DatePicker
                style={{ width: 170 }}
                onChange={handleChange("end_date")}
                placeholder="To"
                value={state?.end_date}
                disabledDate={disabledFutureDate}
                inputReadOnly={true}
                allowClear
              />
            </>
          {/*) : (*/}
          {/*  <Select*/}
          {/*    style={{*/}
          {/*      marginRight: "15px",*/}
          {/*    }}*/}
          {/*    size="large"*/}
          {/*    placeholder="Select user"*/}
          {/*    loading={usersObj?.loading}*/}
          {/*    disabled={usersObj?.loading}*/}
          {/*    value={state?.user}*/}
          {/*    dropdownMatchSelectWidth={false}*/}
          {/*    onChange={handleChange("user")}*/}
          {/*    optionFilterProp="children"*/}
          {/*    getPopupContainer={(trigger) => trigger.parentNode}*/}
          {/*    filterOption={onUserFilterOption}*/}
          {/*    allowClear*/}
          {/*    showSearch*/}
          {/*  >*/}
          {/*    {usersObj?.users.map((userMap, index) => {*/}
          {/*      const user = new User().fromMap(userMap);*/}
          {/*      return (*/}
          {/*        <Select.Option key={index} value={user._id} user={user}>*/}
          {/*          <span className="ml10">{user.fullName || user.email}</span>*/}

          {/*          <span className="ml20">*/}
          {/*            <Tag>*/}
          {/*              {userMap?.role?.name ??*/}
          {/*                roleTransformer?.(user?.role_id)}*/}
          {/*            </Tag>*/}
          {/*          </span>*/}
          {/*        </Select.Option>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </Select>*/}
          {/*)}*/}
        </div>
      }
    >
      <Button
        className="new_button fw"
        icon={<FilterOutlined />}
        type="primary"
        size="large"
      >
        <span className="f15">
          Filter
          {state?.filterType &&
          (state?.user || state?.start_date || state?.start_date)
            ? ` : ${
                Object.values(FILTER_TYPE)?.find(
                  (filter) => filter?._id === state?.filterType
                )?.name
              }`
            : ""}
        </span>
      </Button>
    </Popover>
  );
};

export default MyTaskFilter;
