import MSaga from "../../helper/MSaga";
import { notificationActions } from "./notificationReducer";
import appUrl from "../../config/appUrl";

const notificationSaga = new MSaga({
  name: "notification",
  apiEndPoint: appUrl.NOTIFICATIONS,
  actions: notificationActions,
  messages: {
    updateOne: {
      success: "",
      error: "",
    },
  },
});

export default notificationSaga.getWatchSaga();
