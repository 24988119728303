import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";

import { loggedInUserActions } from "./loggedInUserReducer";

import { showNotification } from "../../utils/commonFunctions";
import appUrl from "../../config/appUrl";
import constants from "../../utils/constants";

const {
  loginRequest,
  updateLoggedInUserRequest,
  updatePassword,
  onHandleUpdatePasswordSuccess,
  onRegisterFreelancerRequest,
  onGetUserDetailsRequest,
  onHandleSuccess,
  onHandleError,
  loginGoogle
} = loggedInUserActions;

// login user start
const loginApi = (data) => {
  return axios({
    method: "POST",
    url: appUrl.LOGIN_URL,
    data,
  });
};
// login user start
const loginGoogleApi = (data) => {
  return axios({
    method: "POST",
    url: appUrl.GOOGLE_LOGIN_URL,
    mode: 'cors',
    data,
  });
};

function* login(action) {
  try {
    const response = yield call(loginApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      const { token, user } = data.data;

      localStorage.setItem(constants.ACCESS_TOKEN, token);
      yield put(onHandleSuccess(user));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("login ", error);
    yield put(onHandleError(error));
  }
}
function* loginSSOGoogle(action) {
  try {
    console.log('Calling')
    const response = yield call(loginGoogleApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      const { token, user } = data.data;

      localStorage.setItem(constants.ACCESS_TOKEN, token);
      yield put(onHandleSuccess(user));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("login ", error);
    yield put(onHandleError(error));
  }
}
// login user end

// register freelancer start
const registerFreelancerApi = (data) => {
  return axios({
    method: "POST",
    url: `${appUrl.USERS}/freelancer`,
    data: data,
  });
};

function* registerFreelancerDetails(action) {
  try {
    const response = yield call(registerFreelancerApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      yield put(onHandleSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("registerFreelancerDetails ", error);
    yield put(onHandleError(error));
  }
}
// register freelancer end

// get users details end
const getUserDetailsApi = () => {
  return axios({
    method: "GET",
    url: `${appUrl.USERS}/getCurrentUser`,
  });
};

function* getUserDetails(action) {
  try {
    const response = yield call(getUserDetailsApi);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      yield put(onHandleSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("getUserDetails ", error);
    yield put(onHandleError(error));
  }
}
// get users details end

// Update User start
const updateUserDetailsApi = (data) => {
  return axios({
    method: "PUT",
    url: `${appUrl.USERS}/${data?._id}`,
    data,
  });
};

function* updateUserDetails(action) {
  try {
    const response = yield call(updateUserDetailsApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      showNotification("success", "Profile updated successfully");

      yield put(onHandleSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("registerFreelancerDetails ", error);
    yield put(onHandleError(error));
  }
}
//Update User end

// Update user password start
const updateUserPasswordApi = (data) => {
  return axios({
    method: "POST",
    url: `${appUrl.AUTH}/change-password`,
    data,
  });
};

function* updateUserPassword(action) {
  try {
    const response = yield call(updateUserPasswordApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      localStorage.setItem("accessToken", data?.data.token);
      showNotification("success", "Password updated successfully");
      yield put(onHandleUpdatePasswordSuccess());
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("updateUserPassword ", error);
    yield put(onHandleError(error));
  }
}
// register freelancer end

function* loggedInUserSaga() {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(loginGoogle.type, loginSSOGoogle);
  yield takeLatest(onRegisterFreelancerRequest.type, registerFreelancerDetails);
  yield takeLatest(onGetUserDetailsRequest.type, getUserDetails);
  yield takeLatest(updateLoggedInUserRequest.type, updateUserDetails);
  yield takeLatest(updatePassword.type, updateUserPassword);
}

export default loggedInUserSaga;
