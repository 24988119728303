import React from "react";
import { Row, Empty } from "antd";

import "./EmptyContainer.scss";

const EmptyContainer = (props) => {
  return (
    <Row className="empty-container fw" align="middle" justify="center">
      <Empty
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        description={props.description}
      />
    </Row>
  );
};

export default React.memo(EmptyContainer);
