import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../duck/projectReducer";
import { Doughnut, HorizontalBar, Bar } from "react-chartjs-2";
import NewTickImage from "../../assets/images/tick_new.svg";
import TickImage from "../../assets/images/tick.svg";
import ClipBoardImg from "../../assets/images/clipboard.svg";
import { Col, Empty, Row, Skeleton } from "antd";
import {
  abbreviateNumber,
  getStringToOptions,
} from "../../utils/commonFunctions";

import "./ProjectInsightDetails.scss";
import constants from "../../utils/constants";

const { CONTENT_TYPE } = constants;

const doughnutOptions = {
  responsive: false,
  legend: {
    position: "bottom",

    labels: {
      usePointStyle: true,
      boxWidth: 6,
      generateLabels: (chart) => {
        const datasets = chart.data.datasets;
        return datasets[0].data.map((data, i) => ({
          text: `${chart.data.labels[i]} (${data})`,
          fillStyle: datasets[0].backgroundColor[i],
        }));
      },
    },
  },
};
const hBarOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        stacked: true,
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: "#0000",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 2,
        },
      },
    ],
  },
};
const options = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: "#0000",
        },
      },
    ],
  },
};

const ProjectInsightDetails = (props) => {
  const { projectId } = props;

  const dispatch = useDispatch();
  const projectObj = useSelector(({ project }) => project.map?.[projectId]);

  const {
    total_task_count,
    task_in_progress_count,
    task_approved_count,
    onTimeCompletionCount,
    offTimeCompletionCount,
    tasksPerContent,
    tasksPerWord,
    contentType_count,
  } = projectObj?.insight ?? {};

  const graphData = useMemo(() => {
    let labels = [];
    let data = [];

    getStringToOptions(CONTENT_TYPE)?.forEach((type) => {
      labels.push(type);
      let value = contentType_count?.reduce(
        (acc, cObj) =>
          cObj?._id?.toLocaleLowerCase() === type?.toLocaleLowerCase()
            ? acc + cObj?.count
            : acc,
        0
      );

      data.push(value);
    });

    return { labels, data };
  }, [contentType_count]);

  useEffect(() => {
    dispatch(projectActions.getInsightDetailsRequest({ _id: projectId }));
  }, [dispatch, projectId]);

  return (
    <div className="project-insights-container bg-white p-10">
      <Row className="items-container" gutter={[15, 15]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>{abbreviateNumber(Number(total_task_count || 0), 1)}</p>
                <span className="whitespace-nowrap">Total Task</span>
              </Col>

              <img width={35} src={ClipBoardImg} alt="" />
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>
                  {abbreviateNumber(Number(task_in_progress_count || 0), 1)}
                </p>
                <span className="whitespace-nowrap">In Progress</span>
              </Col>

              <img src={NewTickImage} alt="" />
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div className="card pri-box-shadow">
            <Row justify="space-between" wrap={false}>
              <Col>
                <p>{abbreviateNumber(Number(task_approved_count || 0), 1)}</p>
                <span className="whitespace-nowrap">Closed</span>
              </Col>

              <img width={35} src={TickImage} alt="" />
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="graph-container mt30 fw" gutter={[20, 20]}>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
          <div className="container pri-box-shadow ">
            <div className="title-container pri-box-shadow">
              <Row align="middle" justify="space-between">
                <p className="header-text">
                  Timely Completed Tasks Vs Delayed Tasks{" "}
                </p>
              </Row>
            </div>

            <div className="project-graph-container">
              {projectObj?.loading ? (
                <Skeleton
                  active={projectObj?.loading}
                  paragraph={{ rows: 5 }}
                />
              ) : (
                <div className="chart-container">
                  {!onTimeCompletionCount && !offTimeCompletionCount && (
                    <div
                      className="fw fh"
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        top: 150,
                        left: 0,
                        zIndex: 20,
                      }}
                    >
                      <Empty />
                    </div>
                  )}
                  <Doughnut
                    width={350}
                    height={300}
                    responsive={true}
                    data={{
                      labels: ["On Time completion", "Delayed completion"],
                      datasets: [
                        {
                          label: "Completed Task",
                          backgroundColor: ["#22CCE2", "#FF3D57"],
                          data: [onTimeCompletionCount, offTimeCompletionCount],
                        },
                      ],
                    }}
                    options={doughnutOptions}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
          <div className="container pri-box-shadow ">
            <div className="title-container pri-box-shadow">
              <Row align="middle" justify="space-between">
                <p className="header-text">Cost-Type Task Breakup</p>
              </Row>
            </div>

            <div className="project-graph-container">
              {projectObj?.loading ? (
                <Skeleton
                  active={projectObj?.loading}
                  paragraph={{ rows: 5 }}
                />
              ) : (
                <div className="chart-container fw pr10">
                  {total_task_count ? (
                    <HorizontalBar
                      height={300}
                      data={{
                        labels: ["Per Word", "Per Content"],
                        datasets: [
                          {
                            label: "Count",
                            data: [tasksPerWord, tasksPerContent],
                            backgroundColor: "#2A2A2A",
                            barThickness: 20,
                          },
                        ],
                      }}
                      options={hBarOptions}
                    />
                  ) : (
                    <Empty />
                  )}
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} xl={24}>
          <div className="container pri-box-shadow ">
            <div className="title-container pri-box-shadow">
              <Row align="middle" justify="space-between">
                <p className="header-text">Content Type Task breakup</p>
              </Row>
            </div>

            <div className="project-graph-container">
              {projectObj?.loading ? (
                <Skeleton
                  active={projectObj?.loading}
                  paragraph={{ rows: 5 }}
                />
              ) : (
                <div className="chart-container fw p10">
                  {total_task_count ? (
                    <Bar
                      height={300}
                      data={{
                        labels: graphData?.labels,
                        datasets: [
                          {
                            label: "Count",
                            data: graphData?.data,
                            backgroundColor: "#2A2A2A",
                            barThickness: 15,
                          },
                        ],
                      }}
                      options={options}
                    />
                  ) : (
                    <Empty />
                  )}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectInsightDetails;
