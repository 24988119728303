import React, { Component } from 'react'
import PropertyContent from './propertyContent'
import Conditions from './conditions'
import {Row, Tabs, Button, message} from 'antd'
import moment from 'moment-timezone'

const operatorSymbol = {
    'is' : '==',
    'is not' : '!=',
    'contains' : 'in',
    'is filled out' : '!=',
    'is not filled out' : '==',
    'is greater than' : '>',
    'is less than' : '<',
    'is greater than or equal to' : '>=',
    'is less than or equal to' : '<=',
    'has atleast one file' : "!=",
    'has no files' : "==",
}
const close = {
    fontSize: '20px',
    marginRight: '10px',
    float: 'right',
    marginTop: '10px',
    color: '#8697A8',
}
const btn = {width:'97px'}
const pos = {
    position: 'relative',
    bottom: '50px',
    right: '46px',
}

export default class PropertiesModal extends Component {

    state = {
        activeField : this.props.activeField
    }

    // componentDidUpdate(nxtprops){
    //     console.log(JSON.stringify(this.props.activeField) !== JSON.stringify(nxtprops.activeField))
    //     if(JSON.stringify(this.props.activeField) !== JSON.stringify(nxtprops.activeField)){
    //         console.log('heyyyy')
    //         this.setState({activeField:nxtprops.activeField})
    //     }
    // }

    // componentDidUpdate(prevpProps){
    //     if(this.props.activeField !== prevpProps.activeField){
    //         this.setState({activeField:this.props.activeField})
    //     }
    // }

    handleAliasName = (e) => {
        let field  = JSON.parse(JSON.stringify(this.state.activeField))
        field.alias_name = e.target.value 
        this.setState({activeField: field})
    }

    handleAliasHelpText = (e) => {
        let field  = JSON.parse(JSON.stringify(this.state.activeField))
        field.alias_help_text = e.target.value 
        this.setState({activeField: field})
    }

    handleFileFormat = (val) => {
        let field = Object.assign({}, this.state.activeField)
        field.options = val ? val : []
        this.setState({activeField: field})
    }

    
    handleOptions = (val) => {
        let field = Object.assign({}, this.state.activeField)
        field.options = val ? val : []
        this.setState({activeField: field})
    }

    // This functiion will set field properties to true/false
    setSwitch = (checked, type) => {
        let field  = Object.assign({}, this.state.activeField)
        switch(type){
            case 'multi_entry' : field.multi_entry = checked
                break;
            case 'mandatory' : field.mandatory = checked
                                if(checked) field.read_only = false
                break;
            case 'read_only' : field.read_only = checked
                                if(checked) field.mandatory = false
                break;
            case 'computed_field' : field.computed_field = checked
                break;
            case 'conditions' : field.conditions = checked
                // if(!checked) field.dependency.draw_data = {}
                break;
            default : break;
        }
        this.setState({activeField: field})
    }

    // This will add new OR condition(row)
    addOrCondition = () => {
        let field       = JSON.parse(JSON.stringify(this.state.activeField))

        if(field.dependency.draw_data.or && field.dependency.draw_data.or.length > 0){
            field.dependency.draw_data.or.push({
                and : [
                    this.conditionObject(field.id, true).drawData
                ]
            })
        }else{
            let or = []
            or.push({
                and : [
                    this.conditionObject(field.id, true).drawData
                ]
            })
            field.dependency.draw_data['or'] = or
        }
        if(field.dependency.logic.or && field.dependency.logic.or.length > 0){
            field.dependency.logic.or.push({
                and : [
                    this.conditionObject(field.id).logic
                ]
            })
        }else{
            let or = []
            or.push({
                and : [
                    this.conditionObject(field.id).logic
                ]
            })
            field.dependency.logic['or'] = or
        }
        
        //Code redundancy here, but its needed to synchronize affects and dependency
        let allFields   = JSON.parse(JSON.stringify(this.props.allFields))
        delete allFields[field.id]
        allFields[field.id] = field;

        this.props.updateFields(allFields)
        this.setState({activeField: field})
    }

    // This will add new AND condition(row within OR)
    addAndCondition = (index) => {
        let field = JSON.parse(JSON.stringify(this.state.activeField))
        field.dependency.draw_data.or[index].and.push(this.conditionObject(field.id, true).drawData)
        field.dependency.logic.or[index].and.push(this.conditionObject(field.id).logic)
        this.setState({activeField: field})
        
        //Code redundancy here, but its needed to synchronize affects and dependency
        // let allFields   = JSON.parse(JSON.stringify(this.props.allFields))
        // allFields       =  allFields.filter((f) => f.id !== field.id);
        // allFields.push(field)
        // this.props.updateFields(allFields)
    }


    // this will return the condition object that help to render condition html
    // By default first field would be selected
    conditionObject = (fieldId, handleAffects = false) => {
        let allFields   = JSON.parse(JSON.stringify(this.props.allFields))
        // let find        = allFields.find(f=>f.id === fieldId) //get active field object
        // allFields       = allFields.filter(f=>f.id !== fieldId)
        delete allFields[fieldId]
        if(handleAffects && Object.keys(allFields).length > 0){
            let af      = {...this.props.allFields}
            // let find    = af.find(f=>f.id === allFields[0].id)
            let find    = af[Object.keys(af)[0]]
            find.affects.push(fieldId)
            // console.log('-------------->', find)
            // this.props.updateFields(af)
        }
        
        let drawData = {
            active_control   : (Object.keys(allFields).length > 0) ? allFields[Object.keys(allFields)[0]].id : '',    //allFields[0].id : '',
            active_condition : (Object.keys(allFields).length > 0) ? (allFields[Object.keys(allFields)[0]].dataType === 'file' ? 'has no files' : 'is filled out') : '',
            value            : '',
            type             : (Object.keys(allFields).length > 0) ? allFields[Object.keys(allFields)[0]].dataType : '',
        }
        let logic = {}
        if(drawData.active_condition === 'contains'){
            logic = {            
                [operatorSymbol[drawData.active_condition]] : [
                    (drawData.value) ? drawData.value : null,
                    {
                        var : drawData.active_control
                    }
                ]
            }
        }else{
            logic = {            
                [operatorSymbol[drawData.active_condition]] : [
                    {
                        var : drawData.active_control
                    },
                    (drawData.value) ? drawData.value : null,
                ]
            }
        }
        
        let obj = {
            drawData,
            logic
        }
        return obj
    }

    // delete added AND condition
    deleteAndCondition = (affectId, orIndex, andIndex) => {
        // console.log(affectId, orIndex, andIndex)
        let field = JSON.parse(JSON.stringify(this.state.activeField))
        
        field.dependency.draw_data.or[orIndex].and.splice(andIndex, 1)
        if(field.dependency.draw_data.or[orIndex].and.length === 0){
            field.dependency.draw_data.or.splice(orIndex, 1)
            field.dependency.logic.or.splice(orIndex, 1)
        }
        if(field.dependency.draw_data.or.length === 0){
            field.dependency.draw_data = {}
            field.dependency.logic = {}
        }

        if(affectId){
            let allFields   = JSON.parse(JSON.stringify(this.props.allFields))
            // let found       = allFields.find(f=>f.id === affectId)
            let found       = allFields[affectId]
            // let affects     = found.affects.filter(a=>a !== field.id)
            // found.affects   = affects
            let index       = found.affects.findIndex(a=>a === field.id)
            if(index >= 0){
                found.affects.splice(index,1)
                // allFields   =  allFields.filter((f) => f.id !== field.id);
                delete allFields[field.id]
                // allFields.push(field)
                allFields[field.id] = field
                this.props.updateFields(allFields)
            }
        }
        this.setState({activeField: field})
    }

    handleField = (fieldId, prevFieldId, orIndex, andIndex) => {
        // console.log(fieldId, prevFieldId, orIndex, andIndex)
        let field       = JSON.parse(JSON.stringify(this.state.activeField))
    
        // let allFields   = [...this.props.allFields]
        let allFields   = {...this.props.allFields}
        // let found       = allFields.find(f=>f.id === fieldId)
        let found       = allFields[fieldId]
        
        // let prevField   = allFields.find(f=>f.id === prevFieldId)
        let prevField   = allFields[prevFieldId]
        let index       = prevField.affects.findIndex(f=>f === field.id)
        if(index >= 0){
            prevField.affects.splice(index, 1)
        }
        // if(found.affects.length > 0){
        //     let index = found.affects.findIndex(f=>f===field.id)
        //     if(index < 0){
        //         found.affects.push(field.id)
        //     }
        // }else{
        // }
        
        found.affects.push(field.id)

        field.dependency.draw_data.or[orIndex].and[andIndex].active_control = fieldId
        field.dependency.draw_data.or[orIndex].and[andIndex].type           = found.dataType
        
        let con = field.dependency.draw_data.or[orIndex].and[andIndex].active_condition
        field.dependency.logic.or[orIndex].and[andIndex][operatorSymbol[con]][con === 'contains' ? 1 : 0].var = fieldId

        // allFields   =  allFields.filter((f) => f.id !== field.id);
        delete allFields[field.id]
        // allFields.push(field)
        allFields[field.id] = field
        this.props.updateFields(allFields)
        this.setState({activeField: field})
    }

    handleOperator = (val, orIndex, andIndex) => {
        // console.log(val, orIndex, andIndex)
        let field = JSON.parse(JSON.stringify(this.state.activeField))
        field.dependency.draw_data.or[orIndex].and[andIndex].active_condition = val
        
        if(val === 'contains'){
            field.dependency.logic.or[orIndex].and[andIndex] = {
                [operatorSymbol[val]] : [
                    field.dependency.draw_data.or[orIndex].and[andIndex].value ? field.dependency.draw_data.or[orIndex].and[andIndex].value : null,
                    {var: field.dependency.draw_data.or[orIndex].and[andIndex].active_control}
                ]
            }
        }else{
            field.dependency.logic.or[orIndex].and[andIndex] = {
                [operatorSymbol[val]] : [
                    {var: field.dependency.draw_data.or[orIndex].and[andIndex].active_control},
                    field.dependency.draw_data.or[orIndex].and[andIndex].value ? field.dependency.draw_data.or[orIndex].and[andIndex].value : null
                ]
            }
        }
        
        this.setState({activeField: field})
    }

    handleValue = (e, orIndex, andIndex, type = null) => {
        let field = JSON.parse(JSON.stringify(this.state.activeField))
        let val;
        if(type){
            if(type === 'number') val       = e
            else if (type === 'date') val   = moment(e).format('DD/MM/YYYY')
            else if (type === 'text') val   = e.target.value
        }else{
            val = e.target.value
        }
        field.dependency.draw_data.or[orIndex].and[andIndex].value = val

        let con = field.dependency.draw_data.or[orIndex].and[andIndex].active_condition
        field.dependency.logic.or[orIndex].and[andIndex][operatorSymbol[con]][con === 'contains' ? 0 : 1] = val

        // console.log(val, orIndex, andIndex, type)
        this.setState({activeField: field})
    }

    updateProperty = () => {
        let fields = JSON.parse(JSON.stringify(this.props.allFields))
        // fields     =  fields.filter((f) => f.id !== this.state.activeField.id);
        delete fields[this.state.activeField.id]
        // fields.push(this.state.activeField)
        fields[this.state.activeField.id] = this.state.activeField
        this.props.updateFields(fields)
        this.props.togglePropertiesModal(null, false, null)
    }

    render() {
        const style = {
            position    : 'absolute',
            background  : '#F1EFEF',
            // width       : '50%',
            margin      : 'auto',
            // height      : '50%',
            left        : '25%',
            right       : '5%',
            zIndex      : 101,
            // marginBottom: '40px',
            boxShadow   : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            borderRadius: '5px',
            // overflow    : 'hidden',
            top         : '15%'//this.props.position.top
        }
        let {activeField} = this.state;
        // console.log(activeField)
        return (
            <React.Fragment>
                <Row className='overlay'></Row>
                <Row style={style}>
                    {/*<Icon type="close" style={close} onClick={e=>this.props.togglePropertiesModal(null, false, null)}/>*/}
                    <div className="arrow-up" style={{left:this.props.position.left}}></div>

                    <Tabs defaultActiveKey="properties" tabPosition='left' className='propertyTabs' style={{height:'400px'}}>
                        <Tabs.TabPane tab='Properties' key='properties'>
                            <PropertyContent
                                activeField         = {activeField} 
                                handleAliasName     = {this.handleAliasName} 
                                handleAliasHelpText = {this.handleAliasHelpText}
                                setSwitch           = {this.setSwitch}
                                handleFileFormat    = {this.handleFileFormat}
                                handleOptions       = {this.handleOptions}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Conditions' key='conditions' style={{overflow:'auto',height:'325px'}}>
                            <Conditions 
                                allFields           = {this.props.allFields}
                                activeField         = {activeField}
                                setSwitch           = {this.setSwitch}
                                addOrCondition      = {this.addOrCondition}
                                addAndCondition     = {this.addAndCondition}
                                deleteAndCondition  = {this.deleteAndCondition}
                                handleField         = {this.handleField}
                                handleOperator      = {this.handleOperator}
                                handleValue         = {this.handleValue}
                                forProjects         = {true}
                            />
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab='Validation' key='validations'>
                            Content of tab 3
                        </Tabs.TabPane> */}
                    </Tabs>

                    <Row type='flex' justify='end' style={pos}>
                        <Button style={btn} className='mr-10' onClick={e=>this.props.togglePropertiesModal(null, false, null)}>Cancel</Button>
                        <Button style={btn} type="primary" onClick={this.updateProperty}>Done</Button>
                    </Row>

                </Row>

            </React.Fragment>
        )
    }
}