import React, { useMemo } from "react";
import {
  TeamOutlined,
  FileDoneOutlined,
  ExclamationCircleOutlined,
  StarOutlined,
  PoweroffOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { abbreviateNumber } from "../../utils/commonFunctions";

const FreelancerInsights = (props) => {
  const { meta = {} } = props;

  const cardData = useMemo(() => {
    const {
      totalCount,
      assessed_writers,
      not_assessed_writers,
      active_writers,
      not_active_writers,
      feedback_to_writer_by_editor,
    } = meta;
    return [
      {
        name: "Total Writers",
        value: abbreviateNumber(Number(totalCount || 0), 1),
        icon: <TeamOutlined className="f25" />,
      },
      {
        name: "Assessed Writers",
        value: abbreviateNumber(Number(assessed_writers || 0), 1),
        icon: <FileDoneOutlined className="f25" />,
      },
      {
        name: "Unassessed Writers",
        value: abbreviateNumber(Number(not_assessed_writers || 0), 1),
        icon: <ExclamationCircleOutlined className="f25" />,
      },
      {
        name: "Active Writers",
        subName: "(Last 30 Days)",
        value: abbreviateNumber(Number(active_writers || 0), 1),
        icon: <UserSwitchOutlined className="f25" />,
      },
      {
        name: "Inactive Writers",
        subName: "(Last 30 Days)",
        value: abbreviateNumber(Number(not_active_writers || 0), 1),
        icon: <PoweroffOutlined className="f25" />,
      },
      {
        name: "Feedback Given by Editor",
        value: abbreviateNumber(Number(feedback_to_writer_by_editor || 0), 1),
        icon: <StarOutlined className="f25" />,
      },
    ];
  }, [meta]);

  return (
    <div className="project-insights-container mt20">
      <Row className="items-container fw" gutter={[10, 10]}>
        {cardData?.map((card) => (
          <Col key={card?.name} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
            <div className="card pri-box-shadow ">
              <Row align={"middle"} justify="space-between" wrap={false}>
                <Col>
                  <p>{card?.value}</p>
                  <span
                    style={{ position: "relative" }}
                    className="whitespace-nowrap"
                  >
                    {card?.name}
                  </span>
                  {card?.subName && (
                    <>
                      <br />
                      <span
                        style={{
                          position: "absolute",
                          bottom: -16,
                          fontStyle: "italic",
                        }}
                        className="whitespace-nowrap"
                      >
                        {card?.subName}
                      </span>
                    </>
                  )}
                </Col>
                {card?.icon}
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FreelancerInsights;
