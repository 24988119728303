import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import AppTable from "../components/AppTable";
import RoleRowItem from "./components/RoleRowItem";

import { roleActions } from "./duck/roleReducer";

import { paramsToString } from "../utils/commonFunctions";

import "./components/Role.scss";
import Loading from "../components/Loading";

const RoleList = () => {
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("{}");

  const { list, meta, loading } = useSelector(({ role }) => {
    const mFilter = boardKey;
    const boards = role.boards;
    const mBoard = boards[mFilter];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset,
          limit,
        };

        setBoardKey(paramsToString(params));
        dispatch(roleActions.onGetAllRequest(params));
      }
    },
    [dispatch, list, meta]
  );

  useEffect(() => {
    if (!list) {
      loadMore();
    }
  }, [loadMore, list]);

  return (
    <Col className="content-body app-container ">
      <Row className="page-header fw" justify="space-between" align="middle">
        <Row className="page-heading">Role Management</Row>

        <Col />
      </Row>
      {!list && loading && <Loading />}

      <Row className="page-body fw" justify="center">
        {!loading && (
          <AppTable
            name={"Roles"}
            data={list}
            renderItem={(item, i) => <RoleRowItem key={i} id={item} />}
            totalItemCount={list?.length}
            loading={loading}
          />
        )}
      </Row>
    </Col>
  );
};

export default RoleList;
