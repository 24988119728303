import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Progress,
  Row,
  Skeleton,
  Tag,
  Tooltip,
} from "antd";

import { useNavigate } from "react-router-dom";
import "./ToDoListContainer.scss";
import routes from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardProjectColors,
  getAvatarColor,
  getNameInitials,
  paramsToString,
  taskProgressPercentage,
} from "../../utils/commonFunctions";
import { itemActions } from "../../item/duck/itemReducer";
import EmptyContainer from "../../components/EmptyContainer";
import { statusTransformer } from "../../components/StatusTransformer";
import User from "../../models/User";
import constants from "../../utils/constants";

const { TASK_TYPE } = constants;

const ToDoListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("{}");

  const itemMap = useSelector(({ item }) => item.map);
  const { list, meta, loading } = useSelector(({ item }) => {
    const boards = item.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const onTaskDetails = useCallback(
    (id, project_id) => {
      const navigateTo = routes.PROJECT_TASK_DETAIL.replace(":id", id).replace(
        ":projectId",
        project_id
      );
      navigate(navigateTo);
    },
    [navigate]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 4) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset: offset,
          limit: limit,
          taskType: TASK_TYPE.MY_TASK._id,
        };

        const mBoardKey = paramsToString(params);
        setBoardKey(mBoardKey);
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount]
  );

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className="container pri-box-shadow">
      <div className="title-container pri-box-shadow">
        <p className="header-text">Quick to - do List ({meta?.totalCount})</p>
      </div>
      <div className="todo-list-container">
        {!meta?.totalCount && !loading && <EmptyContainer />}
        {loading ? (
          <Skeleton active={loading} paragraph={{ rows: 9 }} />
        ) : (
          <>
            {list?.map((id, index) => {
              const itemObj = itemMap?.[id];
              const createdBy = new User().fromMap(itemObj?.created_by);
              const progressPercent = taskProgressPercentage(
                itemObj?.status_description
              );

              return (
                <div
                  className="task pointer"
                  key={id}
                  onClick={() => onTaskDetails(id, itemObj?.project_id?._id)}
                >
                  <Tooltip
                    title={
                      itemObj?.project_id?.name?.length > 35
                        ? itemObj?.project_id?.name
                        : ""
                    }
                  >
                    <Tag
                      color={dashboardProjectColors?.[index]}
                      className="overflow-ellipses-tag"
                    >
                      {itemObj?.project_id?.name}
                    </Tag>
                  </Tooltip>
                  <Tooltip
                    title={
                      itemObj?.fields?.[0]?.value?.length > 30
                        ? itemObj?.fields?.[0]?.value
                        : ""
                    }
                  >
                    <p className="overflow-ellipses-tag">
                      {itemObj?.fields?.[0]?.value}
                      <span className="low-opacity">
                        &nbsp; #{itemObj?.task_id}
                      </span>
                    </p>
                  </Tooltip>
                  <Row justify="space-between">
                    <Col>
                      <span className="low-opacity mb5">Created By</span>
                      <Row align="middle">
                        <Col className="mr5">
                          <Avatar
                            size={30}
                            shape="circle"
                            style={{
                              backgroundColor: getAvatarColor(createdBy?._id),
                            }}
                          >
                            {getNameInitials(createdBy?.fullName)}
                          </Avatar>
                        </Col>

                        <Row className="name-container" align="stretch">
                          <Col>
                            <p className="nl">{createdBy?.fullName}</p>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row justify="space-between">
                        <span style={{ fontSize: 14 }} className="low-opacity">
                          {statusTransformer(
                            itemObj?.status_description,
                            null,
                            true
                          )}
                        </span>
                        <span style={{ fontSize: 14 }} className="low-opacity">
                          {progressPercent}%
                        </span>
                      </Row>
                      <Progress
                        percent={progressPercent}
                        showInfo={false}
                        strokeColor="#000"
                        strokeWidth={6}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}

            <Button
              size="large"
              className="task-button"
              block
              onClick={() => navigate(routes.PROJECT_TASK)}
            >
              {Number(meta?.totalCount) - 4 > 0 ? (
                <span>
                  <strong>{Number(meta?.totalCount) - 4} </strong> Tasks More
                </span>
              ) : (
                <span>More Tasks</span>
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ToDoListContainer;
