import React, { useCallback, useEffect, useState } from "react";
import { Radio, Row, Spin } from "antd";
import "./Notifications.scss";
import EmptyContainer from "../components/EmptyContainer";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "./duck/notificationReducer";

import { paramsToString } from "../utils/commonFunctions";
import NotificationTransformer from "./components/NotificationTransformer";

const Notifications = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState("all");
  const [boardKey, setBoardKey] = useState("{}");

  const { list, meta, loading } = useSelector(({ notification }) => {
    const boards = notification.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          offset: offset,
          limit: limit,
        };
        if (tab === "unread") params.is_read = false;
        setBoardKey(paramsToString(params));
        dispatch(notificationActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, tab]
  );

  useEffect(() => {
    loadMore();
  }, [tab]);

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  return (
    <div className="content-body app-container notifications-container">
      <div className="notifications">
        <div className="view-buttons">
          <Radio.Group
            onChange={(e) => setTab(e?.target?.value)}
            defaultValue={"unread"}
            value={tab}
          >
            <Radio.Button value={"all"}>All Notifications</Radio.Button>
            <Radio.Button value={"unread"}>Unread</Radio.Button>
          </Radio.Group>
        </div>
        <div className="container" onScroll={handleScroll}>
          {list?.map((id) => (
            <NotificationTransformer key={id} item={id} />
          ))}
          {loading && (
            <Row justify="center" className="mb20">
              <Spin />
            </Row>
          )}
          {!meta?.totalCount && !loading && <EmptyContainer />}
        </div>
      </div>
    </div>
  );
};
export default Notifications;
