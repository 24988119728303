import React, { useCallback, useMemo } from "react";
import { Button, Col, Row, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoreOutlined, EllipsisOutlined } from "@ant-design/icons";

import Task from "../../models/Task";
import routes from "../../utils/routes";

import "./TaskRowItem.scss";

const TaskRowItem = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const taskMap = useSelector(({ task }) => task.map);
  const { _id, assignedUser, currentStep, createdAt } = useMemo(
    () => new Task().fromMap(taskMap[id]),
    [taskMap, id]
  );

  const onTaskDetails = useCallback(
    () => navigate(`${routes.TASK}/${_id}`),
    [navigate, _id]
  );

  if (!_id) {
    return <></>;
  }
  // const domains = assignedUser.domains;
  return (
    <Col className="task-row-item-card fw" onClick={onTaskDetails}>
      <Row className="card-container" align="middle" justify="space-between">
        <Row className="content-container" align="middle" justify="start">
          <span className="user-name">
            {assignedUser.firstName} {assignedUser.lastName}
          </span>

          {/* {!!domains.length && (
            <Row align="middle">
              <Row className="circle-icon" />

              <span className="domain">
                {domains?.length > 2 ? (
                  <Tooltip placement="top" title={domains.join(",")}>
                    <Tag className="mb5" key={domains[0]}>
                      {domains[0]}
                    </Tag>
                    <Tag className="mb5" key={domains[1]}>
                      {domains[1]}
                    </Tag>
                    <Tag className="mb5">
                      <EllipsisOutlined />
                    </Tag>
                  </Tooltip>
                ) : (
                  domains?.map((domain) => <Tag key={domain}>{domain}</Tag>)
                )}
                {}
              </span>
            </Row>
          )} */}
          <Row className="created-at-container ml20" justify="end">
            <span>Created on {createdAt.format("Do MMMM YYYY")}</span>
          </Row>
        </Row>
        <Row>
          <Row align="middle">
            <Row className="circle-icon" />

            <span className="domain">
              {currentStep && (
                <Tag color={currentStep?.color}>
                  {currentStep?.message || "-"}
                </Tag>
              )}
            </span>
          </Row>

          <Button className="more-detail-btn" icon={<MoreOutlined />} />
        </Row>
      </Row>
    </Col>
  );
};

export default React.memo(TaskRowItem);
