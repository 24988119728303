import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, Select, Button, Row, Col, Skeleton, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { userActions } from "../users/duck/userReducer";
import InviteFreelancerModal from "./components/InviteFreelancerModal";
import { getStringToOptions, paramsToString } from "../utils/commonFunctions";
import constants from "../utils/constants";
import Loading from "../components/Loading";
import PlusIcon from "../assets/images/plusIcon.svg";

import "./FreelancerList.scss";

import EmptyContainer from "../components/EmptyContainer";
import FreelancerCard from "./components/FreelancerCard";
import AssignAssessmentModal from "./components/AssignAssessmentModal";
import ListView from "../assets/images/ListView.svg";
import IdCard from "../assets/images/id-card.svg";
import MyTable from "../components/MyTable";
import { projectActions } from "../project/duck/projectReducer";
import { useLocation } from "react-router-dom";
import FreelancerInsights from "./components/FreelancerInsights";

const { INDUSTRY, EXPERTIES_CATEGORY, FILTER_TYPE } = constants;
const FreelancerList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [state, setState] = useState({});
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [boardKey, setBoardKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [view, setView] = useState("card");
  const [isReset, setReset] = useState(false);

  const handleModal = useCallback((show = false, id) => {
    show = typeof show === "boolean" && show;
    const mUser = show && id;
    setShowModal(show);
    selectUser(mUser);
  }, []);

  const user = useSelector(({ user }) => user);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = user.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [user, boardKey]);

  const filters = useMemo(
    () => user?.filters?.[location?.pathname],
    [location?.pathname, user?.filters]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search, assessment_status, industry, project_id, expertise } =
          state;
        const params = {
          pathname: location?.pathname,
          role_id: "freelancer",
          offset,
          limit,
        };
        if (search) params.search = search;
        if (project_id) {
          params.project_id = project_id;
          params.filter_type = FILTER_TYPE?.WRITER?._id;
        }
        if (assessment_status) params.assessment_status = assessment_status;
        if (industry) params.industry = industry;
        if (expertise) params.expertise = expertise;

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, location?.pathname, meta?.totalCount, state]
  );

  const projectObj = useSelector(({ project }) => {
    const projectMap = project.map;

    const boards = project.boards;

    const mBoard = boards[JSON.stringify({ draft: false, archive: false })];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let projects = list.map((projectId) => projectMap[projectId]);
    projects = projects.filter((project) => project);

    return { projects, meta, loading };
  });

  const loadMoreProjects = useCallback(
    (offset = 0, limit = 10) => {
      if (projectObj?.loading) return;
      if (
        offset === 0 ||
        projectObj?.meta?.totalCount > projectObj?.projects?.length
      ) {
        const params = {
          draft: false,
          archive: false,
          offset,
          limit,
        };
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      projectObj?.loading,
      projectObj?.meta?.totalCount,
      projectObj?.projects?.length,
    ]
  );

  useEffect(() => {
    if (!projectObj?.meta) {
      loadMoreProjects();
    } else if (
      !projectObj?.loading &&
      projectObj?.projects?.length < projectObj?.meta?.totalCount
    ) {
      loadMoreProjects(projectObj?.projects?.length);
    }
  }, [
    loadMoreProjects,
    projectObj?.loading,
    projectObj?.meta,
    projectObj?.meta?.totalCount,
    projectObj?.projects?.length,
  ]);

  const onResetFilter = useCallback(() => {
    setState({});
    setBoardKey("");
    setReset(true);
  }, []);

  const handleInviteUserModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShowInviteUser(show);
  }, []);

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({ ...JSON.parse(filters) });
    }
  }, []);

  useEffect(() => {
    if ((!boardKey && !filters) || isReset) {
      setReset(false);
      loadMore();
    }
  }, [boardKey, filters, isReset, loadMore]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  const onFilterOption = useCallback((input, option) => {
    const project = option.props.project;
    const projectName = project?.name.toLowerCase();
    const mInput = input.toLowerCase();

    return projectName.indexOf(mInput) >= 0;
  }, []);

  const columns = useMemo(() => [
    { key: 1, title: "S.No.", style: { width: 0 } },
    { key: 2, title: "Name", style: { width: 0 } },
    { key: 3, title: "Industry" },
    { key: 4, title: "Expertise" },
    { key: 5, title: "Registered On" },
    { key: 6, title: "Assessment Status", style: { width: 0 } },
  ], [])

  return (
    <div className="content-body app-container freelancer-list-container">
      <Row className="fw" justify={"end"} gutter={[10, 10]}>
        <Button
          className="new_button mr10"
          type="primary"
          onClick={() => handleInviteUserModal(true)}
          size="large"
          icon={<img src={PlusIcon} alt="plus icon" />}
        >
          &nbsp;Add New Writer
        </Button>

        <Col>
          <Tooltip title="Card View">
            <button
              className={`custom-button ${view === "card" ? "active" : ""
                }`}
              onClick={() => setView("card")}
            >
              <img width={25} src={IdCard} alt="idcard icon" />
            </button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Table View">
            <button
              className={`custom-button ${view === "table" ? "active" : ""}`}
              onClick={() => setView("table")}
            >
              <img width={25} src={ListView} alt="ListView icon" />
            </button>
          </Tooltip>
        </Col>

      </Row>
      <FreelancerInsights meta={meta} />
      <Row className="fw mt20" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={7}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Search First Name, Last Name or Email"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <Select
            className="new_select fw"
            size="large"
            placeholder={"Assessment Status"}
            dropdownMatchSelectWidth={false}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            value={state?.assessment_status}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={handleChange("assessment_status")}
            allowClear
          >
            <Select.Option value={"approved"}>Approved</Select.Option>
            <Select.Option value={"rejected"}>Rejected</Select.Option>
            <Select.Option value={"pending"}>Pending</Select.Option>
            <Select.Option value={"assessment_submitted"}>
              Assessment Submitted
            </Select.Option>
            <Select.Option value={"submission_pending"}>
              Submission Pending
            </Select.Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
          <Select
            className="new_select fw"
            size="large"
            dropdownMatchSelectWidth={false}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            placeholder={"Industry"}
            value={state?.industry}
            onChange={handleChange("industry")}
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            showSearch
          >
            {getStringToOptions(INDUSTRY).map((option, i) => (
              <Select.Option key={i} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={3}>
          <Select
            className="new_select fw"
            value={state?.expertise}
            dropdownMatchSelectWidth={false}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            placeholder={"Expertise"}
            onChange={handleChange("expertise")}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={loading}
            showSearch
            allowClear
          >
            {Object.keys(EXPERTIES_CATEGORY).map((key) => (
              <Select.Option key={key} value={key}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8} lg={5} xl={4} xxl={3}>
          <Select
            className="new_select fw"
            placeholder="Project"
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            size="large"
            dropdownMatchSelectWidth={false}
            value={state?.project_id}
            onChange={handleChange("project_id")}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={onFilterOption}
            showSearch
            allowClear
          >
            {projectObj?.projects?.map((project) => {
              return (
                <Select.Option
                  key={project?._id}
                  value={project?._id}
                  project={project}
                >
                  {project?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={8} md={8} lg={6} xl={4} xxl={2}>
          <Button
            type="primary"
            className="new_button fw"
            size="large"
            onClick={() => loadMore()}
          >
            Search
          </Button>
        </Col>
        <Col xs={24} sm={8} md={8} lg={6} xl={4} xxl={2}>
          <Button
            size="large"
            type="primary-outline"
            className="new_button fw"
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Col>

      </Row>
      {!loading && !meta?.totalCount && <EmptyContainer />}

      {!list && loading && <Loading />}

      {
        view === "card" && <div className="freelancers-container" onScroll={handleScroll}>
          {!!list?.length &&
            list?.map((id) => (
              <FreelancerCard
                key={id}
                user_id={id}
                handleModal={handleModal}
                view={view}
              />
            ))}

          {!!list?.length && loading && (
            <div className="freelancer-card pri-box-shadow">
              <Skeleton
                avatar
                paragraph={{
                  rows: 2,
                }}
                active={loading}
              />
            </div>
          )}
        </div>
      }

      {
        view === "table" && <Row className="fw mt20">
          <MyTable
            columns={columns}
            loading={loading}
            list={list}
            renderItem={(item, i) => (
              <FreelancerCard
                key={i}
                user_id={item}
                serialNo={i + 1}
                view={view}
              />
            )}
            totalCount={meta?.totalCount}
            loadMore={loadMore}
          />
        </Row>
      }

      <InviteFreelancerModal
        visible={showInviteUser}
        handleModal={handleInviteUserModal}
        filter={JSON.stringify({ role_id: "freelancer" })}
      />
      <AssignAssessmentModal
        visible={showModal}
        handleModal={handleModal}
        selectUser={selectUser}
        selectedUser={selectedUser}
      />
    </div >
  );
};

export default FreelancerList;
