import constants from "../utils/constants";
const { ROLE_IDS } = constants;
const { SUPER_ADMIN, ADMIN, FREELANCER, CLIENT, EDITOR, MANAGER } = ROLE_IDS;

const roleTransformer = (role = "", size = 12) => {
  switch (role) {
    case SUPER_ADMIN:
      return <span className="role">Super Admin</span>;

    case ADMIN:
      return <span className="role">Admin</span>;

    case FREELANCER:
      return <span className="role">Writer</span>;

    case CLIENT:
      return <span className="role">Client</span>;
    case EDITOR:
      return <span className="role">Editor</span>;
    case MANAGER:
      return <span className="role">Manager</span>;

    default:
      return <span className="role">{role}</span>;
  }
};
export default roleTransformer;
