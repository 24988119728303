import React, { useCallback, useEffect, useState } from "react";
import { Card, Checkbox, Col, Row } from "antd";

import constants from "../../utils/constants";

const { SOCIAL_MEDIA_PLATFORMS } = constants;

const SelectSocialMediaPlatform = ({
  value = [],
  loading,
  onChange = (value = []) => value,
}) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    return () => setState([]);
  }, []);

  const onTogglePlatform = useCallback(
    (platform = "") => {
      setState((prevState) => {
        let newState;
        if (prevState.includes(platform)) {
          newState = prevState.filter((s) => s !== platform);
        } else {
          newState = [...prevState, platform];
        }

        onChange(newState);
        return newState;
      });
    },
    [onChange]
  );

  const SocialMediaPlatform = useCallback(
    (platform = {}, i) => {
      const isActive = state.includes(platform._id);

      return (
        <Col
          key={i}
          className={`social-media-platform-card-container`}
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={6}
          xxl={4}
          onClick={() => !loading && onTogglePlatform(platform._id)}
        >
          <Card
            className={`social-media-platform-card container-shadow pointer fw ${
              isActive ? "active-card" : ""
            }`}
            bordered={false}
          >
            <Card.Meta
              title={
                <Row align="middle" justify="space-between">
                  <Row align="middle">
                    <img
                      className="social-media-logo h-7 w-7 mr-3"
                      src={platform.logo}
                      alt={platform?.name}
                    />

                    <span className="line-clamp-1">{platform.name}</span>
                  </Row>

                  <Col>
                    <Checkbox
                      checked={isActive}
                      onChange={(e) => e.stopPropagation()}
                    />
                  </Col>
                </Row>
              }
            />
          </Card>
        </Col>
      );
    },
    [state, loading, onTogglePlatform]
  );

  return (
    <Row className="social-media-platforms-container" gutter={[20, 20]}>
      {Object.values(SOCIAL_MEDIA_PLATFORMS).map(SocialMediaPlatform)}
    </Row>
  );
};

export default React.memo(SelectSocialMediaPlatform);
