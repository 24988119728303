import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Input,
  Progress,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import "./MemberDashboard.scss";
import BlogIcon from "../assets/images/Blog.svg";
import LinkedinIcon from "../assets/images/LinkedIn.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import {
  CalendarOutlined,
  SearchOutlined,
  LeftOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import {
  abbreviateNumber,
  getAvatarColor,
  getNameInitials,
  paramsToString,
} from "../utils/commonFunctions";
import { itemActions } from "../item/duck/itemReducer";
import User from "../models/User";
import ProjectItemRow from "../item/components/ProjectItemRow";
import { projectActions } from "../project/duck/projectReducer";
import MemberProfileDetails from "./components/MemberProfileDetails";
import AssessmentDetails from "./components/AssessmentDetails";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import constants from "../utils/constants";
import MyTable from "../components/MyTable";

const { PROFILE_FIELDS_KEY, CURRENCIES, TASK_STATUS_OPTIONS } = constants;
const TABS = ["overview", "profile", "assessment"];

const MemberDashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams?.get("tab");

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);
  const user = useSelector(
    ({ user }) => user.map?.[props?.assigned_freelancer]
  );
  const assigned_freelancer = props?.assigned_freelancer || loggedInUser?._id;

  const mUser = useMemo(
    () => (props?.assigned_freelancer ? user : loggedInUser),
    [loggedInUser, props?.assigned_freelancer, user]
  );

  const [isReset, setReset] = useState(false);
  const [boardKey, setBoardKey] = useState(
    JSON.stringify({ assigned_freelancer })
  );
  const [state, setState] = useState({
    search: "",
  });

  const item = useSelector(({ item }) => item);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = item.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [boardKey, item.boards]);

  const filters = useMemo(
    () => item?.filters?.[pathname],
    [item?.filters, pathname]
  );

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search, project_id, status_description } = state;

        const params = {
          pathname,
          assigned_freelancer,
          offset: offset,
          limit: limit,
        };

        if (search) params.search = search;
        if (project_id) params.project_id = project_id;
        if (status_description) params.status_description = status_description;
        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [
      assigned_freelancer,
      dispatch,
      list?.length,
      meta?.totalCount,
      pathname,
      state,
    ]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({ ...JSON.parse(filters) });
    }
  }, []);

  useEffect(() => {
    if ((assigned_freelancer && !meta) || isReset) {
      loadMore();
      setReset(false);
    }
  }, [assigned_freelancer, isReset, loadMore, meta]);

  const projectObj = useSelector(({ project }) => {
    const projectMap = project.map;

    const boards = project.boards;

    const mBoard = boards[JSON.stringify({ draft: false, archive: false })];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let projects = list.map((projectId) => projectMap[projectId]);
    projects = projects.filter((project) => project);

    return { projects, meta, loading };
  });

  const loadMoreProjects = useCallback(
    (offset = 0, limit = 10) => {
      if (projectObj?.loading) return;
      if (
        offset === 0 ||
        projectObj?.meta?.totalCount > projectObj?.projects?.length
      ) {
        const params = {
          draft: false,
          archive: false,
          offset,
          limit,
        };
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      projectObj?.loading,
      projectObj?.meta?.totalCount,
      projectObj?.projects?.length,
    ]
  );

  useEffect(() => {
    if (!projectObj?.meta) {
      loadMoreProjects();
    } else if (
      !projectObj?.loading &&
      projectObj?.projects?.length < projectObj?.meta?.totalCount
    ) {
      loadMoreProjects(projectObj?.projects?.length);
    }
  }, [
    loadMoreProjects,
    projectObj?.loading,
    projectObj?.meta,
    projectObj?.meta?.totalCount,
    projectObj?.projects?.length,
  ]);
  const onFilterOption = useCallback((input, option) => {
    const project = option.props.project;
    const projectName = project?.name.toLowerCase();
    const mInput = input.toLowerCase();

    return projectName.indexOf(mInput) >= 0;
  }, []);

  const userData = useMemo(() => new User().fromMap(mUser), [mUser]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((preState) => ({ ...preState, [name]: value }));
    },
    []
  );

  const onResetFilter = useCallback(() => {
    setState({
      search: "",
    });
    setBoardKey("");
    setReset(true);
  }, []);

  const profilePercentage = useMemo(() => {
    const totalFields = PROFILE_FIELDS_KEY?.length;
    const filledFields = PROFILE_FIELDS_KEY?.reduce((acc, field) => {
      let value = 0;
      const userField = mUser?.[field];
      if (!!userField) {
        value = 1;
      }

      return value + acc;
    }, 0);

    const percent = (filledFields / totalFields) * 100;
    return percent.toFixed();
  }, [mUser]);

  const handleTab = useCallback(
    (tab) => {
      setSearchParams({ tab });
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (!tab || !TABS?.includes(tab)) {
      handleTab("overview");
    }
  }, [handleTab, tab]);

  const columns = useMemo(
    () => [
      { key: 1, title: "Task Name" },
      { key: 2, title: "Project" },
      { key: 3, title: "Created By" },
      { key: 4, title: "Assigned To" },
      { key: 5, title: "Status" },
    ],
    []
  );

  return (
    <div className="content-body app-container member-dashboard-container">
      <div className="profile-container">
        <Row>
          <Col className=" ml10 mt15">
            {props?.showBackButton && (
              <LeftOutlined
                className="mr15"
                style={{
                  cursor: "pointer",
                }}
                onClick={onGoBack}
              />
            )}
            <Avatar
              size={55}
              style={{
                backgroundColor: getAvatarColor(userData._id),
              }}
            >
              {getNameInitials(
                userData.fullName ? userData.fullName : userData.email
              )}
            </Avatar>
          </Col>
          <Col className="ml10">
            <Row className="username">Hi! {userData?.firstName}</Row>
            <span className="sub-header">
              {userData.email} &nbsp;| &nbsp;
              <CalendarOutlined className="mr5 mb5 " />
              Reg on -{" "}
              {moment(userData?.created_at)
                .startOf("day")
                .format("DD MMM YYYY")}
            </span>
            <span className="sub-header mt5">
              Industry Expert - {userData?.industry.replaceAll("|", " | ")}
            </span>
            <span className="sub-header mt5">
              <Tooltip title="LinkedIn Profile">
                <img
                  src={LinkedinIcon}
                  alt="linkedin page"
                  className="pointer"
                  onClick={() => window.open(userData?.linkedinUrl, "_blank")}
                />
              </Tooltip>
              <Tooltip title="Blog / Website">
                <img
                  src={BlogIcon}
                  alt="linkedin page"
                  className="ml10 pointer"
                  onClick={() => window.open(userData?.website, "_blank")}
                />
              </Tooltip>
            </span>
            <Row className="mt20" align="middle">
              <Col>
                <span className="progress-text">
                  {profilePercentage}% of your profile is complete
                </span>
                <Progress
                  percent={profilePercentage}
                  showInfo={false}
                  strokeColor="#2a2a2a"
                  strokeWidth={7}
                  trailColor="#D9D9D9"
                />
              </Col>
              <Button
                className="new_button ml20"
                type="primary-outline"
                size="large"
                onClick={() => handleTab("profile")}
              >
                {profilePercentage < 100 ? "Complete profile" : "View profile"}
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="stats-container pri-box-shadow">
        <div className="stats">
          <span className="value">{meta?.totalCount || 0}</span>
          <span className="label">Total Task</span>
        </div>
        <div className="stats">
          <span className="value">
            {meta?.totalCount - meta?.approved_count || 0}
          </span>
          <span className="label">In progress</span>
        </div>
        <div className="stats">
          <span className="value">{meta?.approved_count || 0}</span>
          <span className="label">Closed</span>
        </div>
        <div className="stats">
          <span className="value">
            {abbreviateNumber(meta?.total_word_count, 1) ?? 0}
          </span>
          <span className="label">Total Word Count</span>
        </div>
        <div className="stats">
          <span className="value">
            {CURRENCIES.INR.SYMBOL}
            {abbreviateNumber(Number(meta?.user_revenue || 0), 1)}
          </span>
          <span className="label">Amount Invoiced</span>
        </div>
        <div className="stats">
          <span className="value">{mUser?.avg_rating?.toFixed(1) || 0}</span>
          <span className="label">Average Rating</span>
        </div>
      </div>
      <div className="view-buttons mt30">
        <Radio.Group
          onChange={(e) => handleTab(e?.target?.value)}
          defaultValue={"overview"}
          value={tab}
        >
          <Radio.Button value={"overview"}>Overview</Radio.Button>
          <Radio.Button value={"profile"}>Profile</Radio.Button>
          <Radio.Button value={"assessment"}>Assessment</Radio.Button>
        </Radio.Group>
      </div>

      {tab === "overview" && (
        <>
          <Row className="mt30" gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
              <Input
                className="new_search fw"
                size="large"
                placeholder="Search Your Task Here"
                prefix={<SearchOutlined />}
                value={state?.search}
                onChange={handleChange("search")}
                allowClear
              />
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={4} xxl={4}>
              <Select
                className="new_select fw"
                size="large"
                placeholder="Status"
                // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
                clearIcon={
                  <CloseCircleOutlined
                    style={{ color: "#fff", background: "#000", fontSize: 13 }}
                  />
                }
                dropdownMatchSelectWidth={false}
                value={state?.status_description}
                onChange={handleChange("status_description")}
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                allowClear
              >
                {Object.values(TASK_STATUS_OPTIONS).map((status) => (
                  <Select.Option key={status._id} value={status._id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={4} xxl={4}>
              <Select
                className="new_select fw"
                placeholder="Project"
                // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
                clearIcon={
                  <CloseCircleOutlined
                    style={{ color: "#fff", background: "#000", fontSize: 13 }}
                  />
                }
                size="large"
                dropdownMatchSelectWidth={false}
                value={state?.project_id}
                onChange={handleChange("project_id")}
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={onFilterOption}
                showSearch
                allowClear
              >
                {projectObj?.projects?.map((project) => {
                  return (
                    <Select.Option
                      key={project?._id}
                      value={project?._id}
                      project={project}
                    >
                      {project?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={3} xxl={2}>
              <Button
                className="new_button fw"
                type="primary"
                size="large"
                onClick={() => loadMore()}
              >
                Submit
              </Button>
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={3} xxl={2}>
              <Button
                className="new_button fw"
                type="primary-outline"
                size="large"
                onClick={onResetFilter}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row className="fw mt30">
            <MyTable
              columns={columns}
              loading={loading}
              list={list}
              renderItem={(item, i) => <ProjectItemRow key={i} id={item} />}
              totalCount={meta?.totalCount}
              loadMore={loadMore}
            />
          </Row>
        </>
      )}
      {tab === "profile" && <MemberProfileDetails user={mUser} />}
      {tab === "assessment" && <AssessmentDetails user={mUser} />}
    </div>
  );
};

export default MemberDashboard;
