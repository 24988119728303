import React, { useEffect, useRef, useState, useCallback } from "react";
import { Row, Col, Button, Input } from "antd";
import routes from "../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { isValidMobileNumber } from "../utils/commonFunctions";
import "./profile.scss";
import ChangeProfilePassword from "./components/ChangeProfilePassword";
import profileSideImg from "../assets/images/profile_page.svg";

import { useNavigate } from "react-router-dom";
import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";

const Profile = () => {
  const prevProp = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error } = useSelector(({ loggedInUser }) => loggedInUser);

  const user = useSelector(({ loggedInUser }) => loggedInUser.data);

  // const [resume, setResume] = useState([]);
  const [state, setState] = useState({});
  const [show, setShow] = useState(false);
  const [formError, setFormError] = useState({});

  const onGoBack = useCallback(() => {
    navigate(routes.DASHBOARD);
  }, [navigate]);

  const preload = useCallback(() => {
    let {
      first_name,
      last_name,
      contact_number,
      address,
      email,
      gst_num,
      // gst_vat,
    } = user;

    setState({
      first_name,
      last_name,
      email,
      contact_number,
      address,
      gst_num,
      // gst_vat,
    });
    // if (user?.resumeUrl) {
    //   const resumeFile = JSON.parse(JSON.stringify(user?.resumeUrl));
    //   const file = {
    //     ...resumeFile,
    //     name: resumeFile?.filename,
    //   };
    //   setResume([file]);
    // }
  }, [user]);

  useEffect(() => {
    if (prevProp.current?.loading && !loading) {
      if (!error) {
        onGoBack();
      }
    } else if (user) {
      setFormError({});
      preload();
    }

    return () => {
      prevProp.current = { loading };
    };
  }, [error, loading, onGoBack, preload, user]);

  const handleModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShow(show);
  }, []);

  const hasError = useCallback(() => {
    let {
      first_name,
      contact_number,
      // last_name,
      // website,
      // linkedin_URL,
      // sub_industry,
      // industry,
      // expertise,
      // address,
    } = state;
    let error = {};
    if (!first_name) {
      error.first_name = "Please enter your first name";
    }
    // if (!last_name) {
    //   error.last_name = "Please enter your last name";
    // }
    if (contact_number && !isValidMobileNumber(contact_number)) {
      error.contact_number = "Please enter a valid contact number";
    }

    // if (!address) {
    //   error.address = "Please enter your address";
    // }
    // if (!resume.length) {
    //   error.resume = "Please upload you resume";
    // }

    // if (website && !isValidWebsiteURL(website)) {
    //   error.website = "Please enter a valid URL";
    // }
    // if (!linkedin_URL) {
    //   error.linkedin_URL = "Please enter linkedin URL";
    // } else if (!isValidLinkdinURL(linkedin_URL)) {
    //   error.linkedin_URL = "Please enter a valid linkedin URL";
    // }

    // if (!industry?.length) {
    //   error.industry = "Please select an industry";
    // }
    // if (!sub_industry) {
    //   error.sub_industry = "Please select a sub industry";
    // }
    // if (!expertise) error.expertise = "Please select any option";

    setFormError(error);

    return !!Object.keys(error).length;
  }, [state]);

  let {
    first_name,
    last_name,
    contact_number,
    address,
    email,
    gst_num,
    // gst_vat,
    // website,
    // sub_industry,
    // industry,
    // expertise,
    // linkedin_URL,
    // resumeUrl,
    // resource_cost_per_word,
    // active,
    // additional_details,
  } = state;

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let updatedData = {
        _id: user._id,
        first_name,
        last_name,
        contact_number,
        gst_num,
        address,
        // gst_vat,
        // active,
        // sub_industry,
        // industry: industry?.join("|"),
        // expertise,
        // linkedin_URL,
        // resumeUrl,
        // additional_details,
        // resource_cost_per_word,
        // website,
      };
      dispatch(loggedInUserActions.updateLoggedInUserRequest(updatedData));
    }
  }, [
    address,
    contact_number,
    dispatch,
    first_name,
    gst_num,
    hasError,
    last_name,
    user._id,
  ]);

  // const beforeUploadResume = useCallback((file) => {
  //   const supportedFileType = [
  //     "image/png",
  //     "image/jpg",
  //     "image/jpeg",
  //     "application/msword",
  //     "application/pdf",
  //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //   ];
  //   let isAcceptable = supportedFileType.includes(file?.type);
  //   if (!isAcceptable)
  //     showNotification(
  //       "error",
  //       "Please upload JEPG, JGP, PNG, PDF or Word file only"
  //     );

  //   return isAcceptable || Upload.LIST_IGNORE;
  // }, []);

  // const uploadResume = useCallback(
  //   ({ file }) => {
  //     setFormError({});
  //     setResume([{ ...file, status: "uploading" }]);
  //     const formData = new FormData();
  //     formData.append("model_id", user._id);
  //     formData.append("file", file);
  //     formData.append("onModel", "Resume");
  //     axios({
  //       method: "post",
  //       url: AppUrl.ATTACHMENTS,
  //       data: formData,
  //     })
  //       .then((response) => {
  //         if (
  //           response?.data?.meta?.status === 200 &&
  //           response?.data?.meta?.success === true
  //         ) {
  //           let resData = response.data?.data;

  //           let file = Array.isArray(resData) ? resData : [];
  //           if (file?.length > 0) {
  //             file?.forEach((p) => {
  //               let picObj = {
  //                 _id: p?._id,
  //                 name: p?.filename,
  //                 size: p?.size,
  //                 type: p?.mimetype,
  //                 url: p?.thumb_location,
  //               };

  //               setResume([{ ...picObj, status: "success" }]);
  //               setState((prestate) => ({
  //                 ...prestate,
  //                 resumeUrl: picObj?._id,
  //               }));
  //             });
  //           }
  //         } else {
  //           setResume([{ ...file, status: "error" }]);

  //           showNotification("error", "Error while uploading file.");
  //         }
  //       })
  //       .catch((error) => {
  //         setResume([{ ...file, status: "error" }]);

  //         showNotification("error", "Some thing went wrong");
  //       });
  //   },
  //   [user._id]
  // );

  // const onRemoveResume = useCallback((file) => setResume([]), []);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  return (
    <Col className="content-body app-container profile-container">
      <Row className="page-header mb10" justify="space-between">
        <Row className="page-heading">Profile</Row>
      </Row>

      <Row className="page-content" align="top" justify="space-between">
        <Col className="form-container" xl={12} md={24} sm={24} xs={24}>
          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>First Name</label>
              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search"
                placeholder="Jhon"
                value={first_name}
                onChange={handleChange("first_name")}
                disabled={loading}
                size="large"
              />

              <Row className="error mt5">{formError?.first_name}</Row>
            </Col>
          </Row>
          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Last Name</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search"
                placeholder="Doe"
                value={last_name}
                onChange={handleChange("last_name")}
                disabled={loading}
                size="large"
              />
            </Col>
          </Row>
          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Email Address</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              {email}
            </Col>
          </Row>

          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Contact no</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search"
                placeholder="Your Contact Number"
                value={contact_number}
                onChange={handleChange("contact_number")}
                disabled={loading}
                size="large"
              />

              <Row className="error mt5">{formError?.contact_number}</Row>
            </Col>
          </Row>
          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Address</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input.TextArea
                className="new_search fw"
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="Address"
                value={address}
                onChange={handleChange("address")}
                disabled={loading}
              />

              <Row className="error mt5">{formError?.address}</Row>
            </Col>
          </Row>
          <Row className="mb15" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>GST Number</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                size="large"
                className="new_search"
                type="text"
                placeholder="GSTIN Number"
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                value={gst_num}
                onChange={handleChange("gst_num")}
                disabled={loading}
              />
            </Col>
          </Row>
          {/* 
          <Row className="mb15" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>GST/VAT</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                size="large"
                className="new_search"
                type="text"
                value={gst_vat}
                onChange={handleChange("gst_vat")}
                disabled={loading}
              />
            </Col>
          </Row>
          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Linkedin</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search fw"
                placeholder="Linkedin URL"
                value={linkedin_URL}
                onChange={handleChange("linkedin_URL")}
                disabled={loading}
                size="large"
              />

              <Row className="error mt5">{formError?.linkedin_URL}</Row>
            </Col>
          </Row> */}
          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Website</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                className="new_search fw"
                placeholder="Website URL"
                value={website}
                onChange={handleChange("website")}
                disabled={loading}
                size="large"
              />
              <Row className="error mt5">{formError?.website}</Row>
            </Col>
          </Row> */}
          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Expertise</label>

              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Select
                style={{
                  width: 410,
                }}
                className="custom-select fw"
                size="large"
                value={expertise}
                placeholder="Choose your expertise"
                onChange={handleChange("expertise")}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={loading}
                showSearch
              >
                {Object.keys(EXPERTIES_CATEGORY).map((key) => (
                  <Select.Option key={key} value={key}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
              <Row className="error mt5">{formError?.expertise}</Row>
            </Col>
          </Row> */}
          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Industry</label>

              <sup style={{ color: "red" }}>*</sup>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Select
                style={{
                  width: 410,
                }}
                className="custom-select fw"
                size="large"
                mode="multiple"
                value={industry}
                placeholder="Choose an industry"
                onChange={handleChange("industry")}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={loading}
                showSearch
              >
                {getStringToOptions(INDUSTRY).map((option, i) => (
                  <Select.Option key={i} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
              <Row className="error mt5">{formError?.industry}</Row>
            </Col>
          </Row> */}
          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Sub Industry</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Select
                style={{
                  width: 410,
                }}
                className="custom-select fw"
                size="large"
                value={sub_industry}
                placeholder="Choose a sub industry"
                onChange={handleChange("sub_industry")}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={loading}
                showSearch
              >
                {getStringToOptions(SUB_INDUSTRY).map((option, i) => (
                  <Select.Option key={i} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
              <Row className="error mt5">{formError?.sub_industry}</Row>
            </Col>
          </Row> */}
          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Cost Per Word</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Input
                size="large"
                className="new_search"
                type="number"
                value={resource_cost_per_word}
                disabled
              />
              <Row className="error mt5">
                {formError?.resource_cost_per_word}
              </Row>
            </Col>
          </Row> */}

          {/* <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Additional Details</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <TextArea
                className="new_search"
                value={additional_details}
                onChange={handleChange("additional_details")}
                autoSize={{ minRows: 3, maxRows: 6 }}
                disabled={loading}
              />
            </Col>
          </Row> */}
          {/* <Row className="mb20" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}>
              <label>Resume</label>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Upload
                accept={".jpeg, .jpg, .png, .pdf, .docx, .doc"}
                listType="picture"
                fileList={resume}
                beforeUpload={beforeUploadResume}
                customRequest={uploadResume}
                onRemove={onRemoveResume}
                disabled={loading}
              >
                {resume.length < 1 && (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload>
              <Row className="error mt5">{formError?.resume}</Row>
            </Col>
          </Row> */}

          <Row className="mb10" align="middle">
            <Col lg={6} md={24} sm={24} xs={24}></Col>
            <Col lg={18} md={24} sm={24} xs={24}>
              <Row>
                <Col>
                  <Button
                    className="mr20 new_button"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>

                <Col>
                  <Button
                    type="primary-outline"
                    className="new_button"
                    size="large"
                    onClick={() => handleModal(true)}
                  >
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col className="side-image-container" xs={0} sm={0} md={0} xl={12}>
          <Row className="image-container fw" align="middle" justify="center">
            <img
              className="image"
              src={profileSideImg}
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Row>
        </Col>
      </Row>

      <ChangeProfilePassword visible={show} showModal={handleModal} />
    </Col>
  );
};

export default Profile;
