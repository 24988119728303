import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row } from "antd";

import EmptyContainer from "./EmptyContainer";
import MPagination from "./MPagination";

import "./AppTable.scss";

const AppTable = (props) => {
  const {
    boardKey,
    name = "",
    data = [],
    renderItem = (item) => item,
    totalItemCount,
    itemsCountPerPage = 4,
    loadMore = (offset = 0, limit = 30) => {},
    loading = false,
  } = props;
  const preProps = useRef();
  const [state, setState] = useState({
    currentPage: 1,
  });
  const { currentPage } = state;

  useEffect(() => {
    if (preProps.current?.boardKey !== boardKey) {
      setState((prevState) => ({ ...prevState, currentPage: 1 }));
    }

    return () => {
      preProps.current = {
        boardKey,
      };
    };
  }, [boardKey]);

  const { firstIndex, lastIndex } = useMemo(() => {
    const lastIndex = itemsCountPerPage * currentPage;
    const firstIndex = lastIndex - itemsCountPerPage;

    return { firstIndex, lastIndex };
  }, [itemsCountPerPage, currentPage]);

  const { items, firstItemIndex, lastItemIndex } = useMemo(() => {
    const firstItemIndex = firstIndex + 1;
    let lastItemIndex = lastIndex;
    let items = [];

    if (totalItemCount) {
      items = data.slice(firstIndex, lastIndex);

      if (
        data?.length > 0 &&
        items?.length < itemsCountPerPage &&
        data?.length < totalItemCount
      ) {
        !loading && loadMore?.(data?.length, lastIndex);
      } else {
        lastItemIndex = firstIndex + items.length;
      }
    }

    return { items, firstItemIndex, lastItemIndex };
  }, [
    firstIndex,
    lastIndex,
    data,
    loadMore,
    itemsCountPerPage,
    totalItemCount,
    loading,
  ]);

  const handleChange = useCallback((currentPage) => {
    setState((prevState) => ({ ...prevState, currentPage }));
  }, []);

  return (
    <Col className="app-table-container" span={24}>
      {!totalItemCount && <EmptyContainer />}

      <Row className="table-body">{items?.map(renderItem)}</Row>

      {!!totalItemCount && (
        <Row className="table-footer" align="middle" justify="space-between">
          <Row>
            Showing {firstItemIndex} to {lastItemIndex} of {totalItemCount}{" "}
            {name}
          </Row>

          <Row>
            <MPagination
              defaultActivePage={1}
              activePage={state.currentPage}
              totalItemsCount={totalItemCount}
              itemsCountPerPage={itemsCountPerPage}
              onChange={handleChange}
            />
          </Row>
        </Row>
      )}
    </Col>
  );
};

export default React.memo(AppTable);
