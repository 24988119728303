import { Button, Col, Input, Radio, Row, Select, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loggedInUserActions } from "../../loggedInUser/duck/loggedInUserReducer";
import { userActions } from "../../users/duck/userReducer";
import {
  getStringToOptions,
  isValidLinkdinURL,
  isValidMobileNumber,
  isValidWebsiteURL,
} from "../../utils/commonFunctions";
import { CaretDownOutlined } from "@ant-design/icons";
import constants from "../../utils/constants";
import "./MemberProfileDetails.scss";
const { INDUSTRY, EXPERTIES_CATEGORY } = constants;

const MemberProfileDetails = (props) => {
  const { user } = props;
  const loading = user.loading;

  const dispatch = useDispatch();

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  const [resume, setResume] = useState([]);
  const [state, setState] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [formError, setFormError] = useState({});
  const [bankFormError, setBankFormError] = useState({});
  const [views, setViews] = useState({});

  const preload = useCallback(() => {
    let {
      first_name,
      last_name,
      contact_number,
      address,
      sub_industry,
      industry,
      expertise,
      website,
      gst_num,
      email,
      // gst_vat,
      linkedin_URL,
      profile_type,
      resource_cost_per_word,
      role_id,
      profile_approved,
      active,
      additional_details,
      bank_details,
    } = user;
    setBankDetails(Object.assign({}, { gst_num }, bank_details));
    let pan_vat;
    let bank_paypal;
    if (bank_details?.vat_number) {
      pan_vat = "vat";
    } else if (bank_details?.pan_number) {
      pan_vat = "pan";
    }

    if (bank_details?.paypal_id) {
      bank_paypal = "paypal";
    } else if (bank_details?.account_number || bank_details?.ifsc) {
      bank_paypal = "bank";
    }
    setViews({
      pan_vat,
      bank_paypal,
    });

    setState({
      first_name,
      last_name,
      contact_number,
      address,
      sub_industry,
      industry: industry?.split("|"),
      expertise,
      email,
      // gst_vat,
      gst_num,
      linkedin_URL,
      website,
      profile_type,
      resource_cost_per_word,
      role_id,
      profile_approved,
      active,
      additional_details,
    });

    if (user?.resumeUrl) {
      const resumeFile = JSON.parse(JSON.stringify(user?.resumeUrl));
      const file = {
        ...resumeFile,
        name: resumeFile?.filename,
      };
      setResume([file]);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setFormError({});
      preload();
    }
  }, [preload, user]);

  const handleRadio = useCallback(
    (name) => (event) => {
      let value = event?.target?.value;
      setViews((pre) => ({
        ...pre,
        [name]: value,
      }));
      setBankFormError({});
    },
    []
  );

  const hasError = useCallback(() => {
    let {
      first_name,
      website,
      linkedin_URL,
      contact_number,
      industry,
      expertise,
      sub_industry,
      address,
    } = state;
    let error = {};
    if (!first_name) {
      error.first_name = "Please enter your first name";
    }

    if (contact_number && !isValidMobileNumber(contact_number)) {
      error.contact_number = "Please enter a valid contact number";
    }

    // if (!address) {
    //   error.address = "Please enter your address";
    // }
    // if (!resume.length) {
    //   error.resume = "Please upload you resume";
    // }

    if (website && !isValidWebsiteURL(website)) {
      error.website = "Please enter a valid URL";
    }
    if (linkedin_URL && !isValidLinkdinURL(linkedin_URL)) {
      error.linkedin_URL = "Please enter a valid linkedin URL";
    }

    if (!industry?.length) {
      error.industry = "Please select an industry";
    }

    // if (!sub_industry) {
    //   error.sub_industry = "Please select a sub industry";
    // }
    if (!expertise) error.expertise = "Please select any option";

    setFormError(error);

    return !!Object.keys(error).length;
  }, [state]);

  let {
    first_name,
    last_name,
    contact_number,
    website,
    address,
    sub_industry,
    industry,
    expertise,
    email,
    // gst_vat,
    gst_num,
    linkedin_URL,
    resumeUrl,
    resource_cost_per_word,
    active,
    additional_details,
  } = state;

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let updatedData = {
        first_name,
        last_name,
        _id: user._id,
        contact_number,
        active,
        address,
        gst_num,
        sub_industry,
        industry: industry?.join("|"),
        expertise,
        linkedin_URL,
        resumeUrl,
        additional_details,
        resource_cost_per_word,
        // gst_vat,
        website,
      };

      if (loggedInUser._id === updatedData._id) {
        dispatch(loggedInUserActions.updateLoggedInUserRequest(updatedData));
      } else {
        dispatch(userActions.onUpdateOneRequest(updatedData));
      }
    }
  }, [
    loggedInUser,
    active,
    additional_details,
    address,
    gst_num,
    contact_number,
    dispatch,
    expertise,
    first_name,
    hasError,
    industry,
    last_name,
    linkedin_URL,
    resource_cost_per_word,
    resumeUrl,
    sub_industry,
    user._id,
    website,
  ]);

  const hasBankError = useCallback(() => {
    let {
      bank_name,
      ifsc,
      account_number,
      account_holder_name,
      pan_number,
      account_type,
      vat_number,
      paypal_id,
    } = bankDetails;

    let { pan_vat, bank_paypal } = views;

    let error = {};

    if (!pan_vat) {
      error.pan_vat = "Please select either PAN or VAT";
    } else if (pan_vat === "pan" && !pan_number) {
      error.pan_number = "Please enter your PAN card number";
    } else if (pan_vat === "vat" && !vat_number) {
      error.vat_number = "Please enter your VAT number";
    }

    if (!bank_paypal) {
      error.paymnet_type = "Please select either Bank or PayPal";
    } else if (bank_paypal === "paypal" && !paypal_id) {
      error.paypal_id = "Please enter your PayPal ID";
    }
    if (bank_paypal === "bank") {
      if (!account_type) {
        error.account_type = "Please select your account type";
      }
      if (!account_number) {
        error.account_number = "Please enter your bank's account number ";
      }
      if (!account_holder_name) {
        error.account_holder_name = "Please enter name associated with bank";
      }
      if (!bank_name) {
        error.bank_name = "Please enter your bank name";
      }
    }

    setBankFormError(error);

    return !!Object.keys(error).length;
  }, [bankDetails, views]);

  const onSubmitBankDetails = useCallback(() => {
    if (!hasBankError()) {
      let {
        bank_name,
        ifsc,
        account_number,
        account_holder_name,
        account_type,
        pan_number,
        vat_number,
        paypal_id,
        bank_address,
        gst_num,
        bic_swift_code,
        routing_wire_no
      } = bankDetails;

      let updatedData = {};
      if (views?.pan_vat === "pan") {
        updatedData.pan_number = pan_number;
      } else if (views?.pan_vat === "vat") {
        updatedData.vat_number = vat_number;
      }

      if (views?.bank_paypal === "paypal") {
        updatedData.paypal_id = paypal_id;
      } else {
        updatedData = Object.assign({}, updatedData, {
          bank_name,
          account_number,
          account_holder_name,
          account_type,
          bank_address,
          bic_swift_code: bic_swift_code,
          routing_wire_no: routing_wire_no
        });
        if (account_type === "account_number") {
          updatedData.ifsc = ifsc;
          updatedData.gst_num = gst_num;
        }
      }

      let payload = {
        _id: user._id,
        bank_details: updatedData,
      };
      if (loggedInUser._id === updatedData._id) {
        dispatch(loggedInUserActions.updateLoggedInUserRequest(payload));
      } else {
        dispatch(userActions.onUpdateOneRequest(payload));
      }
    }
  }, [
    hasBankError,
    bankDetails,
    views?.pan_vat,
    views?.bank_paypal,
    user._id,
    loggedInUser._id,
    dispatch,
  ]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      if (name === "contact_number") {
        value = value && Number(value.replace(/[^0-9]/g, ""));
      }

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
  const handleBankDetails = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked ?? event;

      // if (name === "account_number") {
      //   value = value && Number(value.replace(/[^0-9]/g, ""));
      // }

      setBankFormError({});
      setBankDetails((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const prefixSelector = useMemo(
    () => (
      <Select
        style={{ width: 220, border: "none" }}
        size="large"
        bordered={false}
        value={bankDetails?.account_type}
        suffixIcon={<CaretDownOutlined className="mr15" />}
        placeholder="Account Type"
        onChange={handleBankDetails("account_type")}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={loading}
      >
        {[
          { _id: "account_number", name: "Account Number" },
          { _id: "iban_number", name: "IBAN Number" },
        ].map((obj) => (
          <Select.Option key={obj._id} value={obj._id}>
            {obj.name}
          </Select.Option>
        ))}
      </Select>
    ),

    [bankDetails?.account_type, handleBankDetails, loading]
  );

  return (
    <div className="details-container">
      <div className="profile-details">
        <div className="header pri-box-shadow">Freelancer Details</div>
        <div className="fields-container">
          <Row>
            <label>
              First Name
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <Input
              className="new-input"
              placeholder="Jhon"
              value={first_name}
              onChange={handleChange("first_name")}
              disabled={loading}
              size="large"
            />

            <Row className="error mt5">{formError?.first_name}</Row>
          </Row>

          <Row>
            <label>Last Name</label>
            <Input
              className="new-input"
              placeholder="Doe"
              value={last_name}
              onChange={handleChange("last_name")}
              disabled={loading}
              size="large"
            />

            {/* <Row className="error mt5">{formError?.last_name}</Row> */}
          </Row>
          <Row>
            <label>
              Email <sup style={{ color: "red" }}>*</sup>
            </label>
            <Input
              className="new-input"
              value={email}
              onChange={handleChange("email")}
              disabled
              size="large"
            />
          </Row>
          <Row>
            <label>Contact Number</label>
            <Input
              className="new-input"
              placeholder="Your Contact Number"
              value={contact_number}
              onChange={handleChange("contact_number")}
              disabled={loading}
              size="large"
            />

            <Row className="error mt5">{formError?.contact_number}</Row>
          </Row>
          <Row>
            <label>Address</label>
            <Input.TextArea
              autoSize={{ minRows: 6 }}
              className="new-input"
              placeholder="Address"
              value={address}
              onChange={handleChange("address")}
              disabled={loading}
            />

            {/* <Row className="error mt5">{formError?.address}</Row> */}
          </Row>
          <Row style={{ flexDirection: "column" }}>
            {/* <label>GST Number</label>
            <Input
              className="new-input"
              placeholder="GSTIN Number"
              value={gst_num}
              onChange={handleChange("gst_num")}
              disabled={loading}
              size="large"
            /> */}
            {/*
            <label className="mt10">GST/VAT</label>
            <Input
              size="large"
              className="new-input"
              type="text"
              onChange={handleChange("gst_vat")}
              value={gst_vat}
              disabled={loading}
              />
               <Row className="error mt5">{formError?.gst_vat}</Row> */}
            <label className="mt15">
              Expertise <sup style={{ color: "red" }}>*</sup>
            </label>
            <Select
              size="large"
              className="fw"
              value={expertise}
              suffixIcon={<CaretDownOutlined className="mr15" />}
              placeholder="Choose your expertise"
              onChange={handleChange("expertise")}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loading}
            >
              {Object.keys(EXPERTIES_CATEGORY).map((key) => (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{formError?.expertise}</Row>
            <label>Website</label>
            <Input
              className="new-input"
              placeholder="Your Blog or Website URL"
              value={website}
              onChange={handleChange("website")}
              disabled={loading}
              size="large"
            />
            <Row className="error mt5">{formError?.website}</Row>
          </Row>
          <Row className="full-width">
            <label>Linkedin</label>
            <Input
              className="new-input"
              placeholder="Linkedin URL"
              value={linkedin_URL}
              onChange={handleChange("linkedin_URL")}
              disabled={loading}
              size="large"
            />

            <Row className="error mt5">{formError?.linkedin_URL}</Row>
          </Row>

          {/* <Row className="full-width">
            <label>
              Expertise <sup style={{ color: "red" }}>*</sup>
            </label>
            <Select
              size="large"
              className="fw"
              value={expertise}
              suffixIcon={<CaretDownOutlined className="mr15" />}
              placeholder="Choose your expertise"
              onChange={handleChange("expertise")}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loading}
            >
              {Object.keys(EXPERTIES_CATEGORY).map((key) => (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{formError?.expertise}</Row>
          </Row> */}
          <Row className="full-width">
            <label>
              Industry <sup style={{ color: "red" }}>*</sup>
            </label>
            <Select
              className="fw"
              size="large"
              mode="multiple"
              value={industry}
              placeholder="Choose your industry"
              onChange={handleChange("industry")}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loading}
              showSearch
            >
              {getStringToOptions(INDUSTRY).map((option, i) => (
                <Select.Option key={i} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
            <Row className="error mt5">{formError?.industry}</Row>
          </Row>

          <Row className="full-width">
            <label>Additional Details</label>
            <Input.TextArea
              className="new-input"
              value={additional_details}
              onChange={handleChange("additional_details")}
              autoSize={{ minRows: 6, maxRows: 9 }}
              disabled={loading}
            />
          </Row>
          <Row className="full-width">
            <Button
              className="new_button"
              type="primary"
              loading={loading}
              onClick={onSubmit}
              size="large"
            >
              Submit
            </Button>
          </Row>
        </div>
      </div>

      <div className="profile-details">
        <div className="header">Payment Details</div>
        <div className="sub-header ">
          Please select a PAN or VAT number based on your region.
          <sup style={{ color: "red" }}>*</sup>
        </div>
        <div className="fields-container">
          <Row>
            <Radio.Group
              onChange={handleRadio("pan_vat")}
              value={views?.pan_vat}
            >
              <Space direction="vertical">
                <Radio value="pan"> PAN Number (For India)</Radio>
                <Radio value="vat"> VAT Number (For Other Countries)</Radio>
              </Space>
              <Row className="error">{bankFormError?.pan_vat}</Row>
            </Radio.Group>
            {views?.pan_vat === "pan" && (
              <>
                <Input
                  className="new-input"
                  placeholder="PAN card number"
                  value={bankDetails?.pan_number}
                  onChange={handleBankDetails("pan_number")}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                  disabled={loading}
                  size="large"
                />
                <Row className="error mt5">{bankFormError?.pan_number}</Row>
              </>
            )}
            {views?.pan_vat === "vat" && (
              <>
                <Input
                  className="new-input fw"
                  placeholder="VAT Number"
                  value={bankDetails?.vat_number}
                  onChange={handleBankDetails("vat_number")}
                  disabled={loading}
                  size="large"
                />
                <Row className="error mt5">{bankFormError?.vat_number}</Row>
              </>
            )}
          </Row>
          <Row className="full-width mt15">
            Please select account details or PayPal ID.
          </Row>

          <Row className="full-width">
            <Col span={24}>
              <Radio.Group
                onChange={handleRadio("bank_paypal")}
                value={views?.bank_paypal}
              >
                <Space direction="vertical">
                  <Radio value="bank">Account Details</Radio>
                  <Radio value="paypal"> PayPal Id</Radio>
                </Space>

                <Row className="error mt5">{bankFormError?.paymnet_type}</Row>
              </Radio.Group>
            </Col>
            {views?.bank_paypal === "paypal" && (
              <Row className="fw">
                <Col span={12}>
                  <Input
                    className="new-input"
                    placeholder="PayPal Id"
                    value={bankDetails?.paypal_id}
                    onChange={handleBankDetails("paypal_id")}
                    disabled={loading}
                    size="large"
                  />
                  <Row className="error mt5">{bankFormError?.paypal_id}</Row>
                </Col>
              </Row>
            )}
            {views?.bank_paypal === "bank" && (
              <Row gutter={[20]}>
                <Col sm={24} md={12} className="mb20">
                  <label>
                    Account Holder Name
                    <sup style={{ color: "red" }}>*</sup>
                  </label>

                  <Input
                    className="new-input"
                    placeholder="Jhon Doe"
                    value={bankDetails?.account_holder_name}
                    onChange={handleBankDetails("account_holder_name")}
                    disabled={loading}
                    size="large"
                  />

                  <Row className="error mt5">
                    {bankFormError?.account_holder_name}
                  </Row>
                </Col>
                <Col sm={24} md={12} className="mb20">
                  <label>
                    Bank Name
                    <sup style={{ color: "red" }}>*</sup>
                  </label>

                  <Input
                    className="new-input fw"
                    placeholder="State Bank of India"
                    value={bankDetails?.bank_name}
                    onChange={handleBankDetails("bank_name")}
                    disabled={loading}
                    size="large"
                  />

                  <Row className="error mt5">{bankFormError?.bank_name}</Row>
                </Col>
                <Col md={24} lg={12} className="mb20">
                  <label>
                    {`${
                      bankDetails?.account_type === "iban_number"
                        ? "IBAN"
                        : "Account"
                    }
                    Number`}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <Input
                    className="new-input-prefix fw"
                    placeholder={`Your ${
                      bankDetails?.account_type === "iban_number"
                        ? "IBAN"
                        : "Account"
                    } Number`}
                    value={bankDetails?.account_number}
                    onChange={handleBankDetails("account_number")}
                    disabled={loading}
                    addonBefore={prefixSelector}
                    size="large"
                  />

                  <Row className="error mt5">
                    {bankFormError?.account_type ||
                      bankFormError?.account_number}
                  </Row>
                </Col>
                {bankDetails?.account_type === "account_number" && (
                  <>
                    <Col xs={24} sm={24} lg={12} className="mb20">
                      <label>
                        IFSC Code
                      </label>

                      <Input
                        className="new-input"
                        placeholder="IFSC Code"
                        value={bankDetails?.ifsc}
                        onChange={handleBankDetails("ifsc")}
                        disabled={loading}
                        size="large"
                      />
                    </Col>
                    <Col xs={24} sm={24} lg={12} className="mb20">
                      <label>
                        <label>GST Number</label>
                      </label>
                      <Input
                        className="new-input"
                        placeholder="GSTIN Number"
                        value={bankDetails?.gst_num}
                        onChange={handleBankDetails("gst_num")}
                        disabled={loading}
                        size="large"
                      />
                    </Col>
                  </>
                )}
                <Col sm={24} md={12} className="mb20">
                  <label>
                    BIC / SWIFT CODE
                  </label>

                  <Input
                      className="new-input"
                      placeholder=""
                      value={bankDetails?.bic_swift_code}
                      onChange={handleBankDetails("bic_swift_code")}
                      disabled={loading}
                      size="large"
                  />

                </Col>

                <Col sm={24} md={12} className="mb20">
                  <label>
                    Routing Wire Number
                  </label>

                  <Input
                      className="new-input"
                      placeholder=""
                      value={bankDetails?.routing_wire_no}
                      onChange={handleBankDetails("routing_wire_no")}
                      disabled={loading}
                      size="large"
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} className="mb20">
                  <label>Bank Address</label>
                  <Input.TextArea
                      className="new-input fw"
                      value={bankDetails?.bank_address}
                      onChange={handleBankDetails("bank_address")}
                      autoSize={{ minRows: 3, maxRows: 7 }}
                      disabled={loading}
                  />
                </Col>

              </Row>
            )}
          </Row>

          <Row className="full-width">
            <Button
              className="new_button"
              type="primary"
              loading={loading}
              onClick={onSubmitBankDetails}
              size="large"
            >
              Update Details
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MemberProfileDetails;
