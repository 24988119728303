import React, { useCallback, useEffect, useState } from "react";
import { Card, Button, Input, Space, Tooltip } from "antd";
import { EditOutlined, RedoOutlined, LeftOutlined } from "@ant-design/icons";

import {
  Link,
  useNavigate,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"; // Importing the useHistory hook
import "./testing.scss";
import ChatBox from "./components/ChatBox";
import { useDispatch, useSelector } from "react-redux";
import { itemActions } from "../item/duck/itemReducer";
import axios from "axios";
import appUrl from "../config/appUrl";
import routes from "../utils/routes";
const { TextArea } = Input;

const Testing = (props) => {
  let dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [fieldsValue, setFieldsValue] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(itemActions.onGetOneRequest({ _id: id }));
  }, []);
  const itemObj = useSelector(({ item }) => item?.map[id]);

  useEffect(() => {
    if (itemObj) {
      const fields = {};
      itemObj?.fields?.forEach((field, i) => {
        fields[i] = field?.value ?? "";
      });
      console.log("----------", itemObj);
      setFieldsValue({
        prompt: itemObj?.fields?.[0]?.rows?.prompt,
        promptA: itemObj?.fields?.[0]?.rows?.promptA,
        promptB: itemObj?.fields?.[0]?.rows?.promptB,
        promptC: itemObj?.fields?.[0]?.rows?.promptC,
        promptD: itemObj?.fields?.[0]?.rows?.promptD,
        promptE: itemObj?.fields?.[0]?.rows?.promptE,
        promptF: itemObj?.fields?.[0]?.rows?.promptF,
        promptG: itemObj?.fields?.[0]?.rows?.promptG,
        promptH: itemObj?.fields?.[0]?.rows?.promptH,
        promptI: itemObj?.fields?.[0]?.rows?.promptI,
        promptJ: itemObj?.fields?.[0]?.rows?.promptJ,
        title: itemObj?.fields?.[0]?.rows?.title,
        description: itemObj?.fields?.[0]?.rows?.description,
        editTitle: true,
        editDescription: true,
        editPrompt: true,
        editPromptA: true,
        editPromptB: true,
        editPromptC: true,
        editPromptD: true,
        editPromptE: true,
        editPromptF: true,
        editPromptG: true,
        editPromptH: true,
        editPromptI: true,
        editPromptJ: true,
      });
      // setState(itemObj?.fields)
      if (!itemObj?.loading) {
        // setState({
        //   ...fields,
        //   cost_type: itemObj?.meta?.cost_type,
        //   cost: Number(itemObj?.meta?.cost),
        //   currency: itemObj?.meta?.currency ?? DefaultCurrency,
        // });
        // setError({});
      }
    }
  }, [itemObj, dispatch]);

  const fieldValueChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;
      console.log("name", name, value, fieldsValue);
      setFieldsValue({
        ...fieldsValue,
        [name]: { value: value, label: fieldsValue?.[name]?.label },
      });
    },
    [fieldsValue]
  );

  const fieldLabelChange = useCallback(
    (name) => (e) => {
      const label = e.target.value;
      setFieldsValue((prev) => ({
        ...prev,
        [name]: { ...prev[name], label },
      }));
    },
    [fieldsValue]
  );

  const enableEdit = (name) => {
    setFieldsValue({ ...fieldsValue, [name]: !fieldsValue[name] });
  };

  /*   const handleTextEdit = (e, innerObj) => {
    const newLabel = e.target.value;
    setFieldsValue((prev) => ({
      ...prev,
      innerObj: {
        ...prev.promptA,
        label: newLabel,
      },
    }));
  }; */

  const regenerateOneApi = (type, v) => {
    setLoading(true);
    setFieldsValue({ ...fieldsValue, [v]: true });
    console.log(fieldsValue?.[type]);
    axios({
      method: "POST",
      url: appUrl.BULK_AI_PROJECT_REGENERATE_ONE,
      data: { task_id: itemObj?._id, type, prompt: fieldsValue?.[type] },
    }).then((response) => {
      setFieldsValue({ ...fieldsValue, [v]: false });
      setLoading(false);
      dispatch(itemActions.onGetOneRequest({ _id: itemObj?._id }));
    });
  };
  const regenerateAll = () => {
    axios({
      method: "POST",
      url: appUrl.BULK_AI_PROJECT_REGENERATE_ALL,
      data: { task_id: itemObj?._id },
    }).then((response) => {
      dispatch(itemActions.onGetOneRequest({ _id: itemObj?._id }));
    });
  };
  const onSubmit = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: appUrl.BULK_AI_PROJECT_GENERATE_DOC,
      data: { task_id: itemObj?._id },
    }).then((response) => {
      // dispatch(itemActions.onGetOneRequest({ _id: itemObj?._id }));
      setLoading(false);
      navigate(routes.CONTENT_DETAIL.replace(":id", itemObj?.project_id?._id));
    });
  };

  console.log(fieldsValue, "fieldsValue");

  return (
    <div className="content-body testing-container project-task-details-page">
      <Card
          className="testing-card"
          style={{}}
          bodyStyle={{
            overflowY: "auto",
            flex: "1 1 auto",
          }}
          extra={
            <Space
                className="card-footer"
                style={{textAlign: "center", width: "100%"}}
            >
              <Button
                  disabled={loading}
                  onClick={onSubmit}
                  type="primary"
                  icon={<RedoOutlined spin={loading}/>}
              >
                Create Document
              </Button>
              <Button onClick={() => navigate(-1)} type="default">
                Cancel
              </Button>
            </Space>
          }
      >
        <div className="card-header-top">
          <LeftOutlined
              onClick={() => navigate(-1)}
              style={{marginRight: 16}}
              className="text-2xl pointer"
          />
          <h2 className="card-title">Your Draft - {itemObj?.name}</h2>
        </div>

        {/* SEO Title and Description */}
        <div className="card-header">
          <div className="textarea-heading">
            <h3 className="label">Task Instruction</h3>
            <Space>
              {/*<Tooltip title="Edit">*/}
              {/*  <Button onClick={()=>enableEdit("editTitle")} shape="circle" icon={<EditOutlined/>}/>*/}
              {/*</Tooltip>*/}
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("prompt", "loadingPrompt")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingPrompt}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("prompt")}
              value={fieldsValue?.prompt?.value}
              rows={3}
              placeholder="Write your Task Instruction here to ge better response..."
          />
          <div className="textarea-heading">
            <h3 className="label">SEO Title</h3>
            <Space>
              {/*<Tooltip title="Edit">*/}
              {/*  <Button onClick={()=>enableEdit("editTitle")} shape="circle" icon={<EditOutlined/>}/>*/}
              {/*</Tooltip>*/}
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("title", "loadingTitle")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingTitle}/>}
                />
              </Tooltip>
            </Space>
          </div>

          <TextArea
              onChange={fieldValueChange("title")}
              value={fieldsValue?.title?.value}
              rows={3}
              placeholder="Write your Title here..."
          />

          <div className="textarea-heading">
            <h3 className="label">SEO Description</h3>
            <Space>
              {/*<Tooltip title="Edit">*/}
              {/*  <Button onClick={()=>enableEdit("editDescription")} shape="circle" icon={<EditOutlined/>}/>*/}
              {/*</Tooltip>*/}
              <Tooltip title="Regenerate">
                <Button
                    onClick={() =>
                        regenerateOneApi("description", "loadingDescription")
                    }
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingDescription}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("description")}
              value={fieldsValue?.description?.value}
              rows={3}
              placeholder="Write your Description here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* edit content promptA */}
            {fieldsValue?.editPromptA ? (
                <h3 className="label">{fieldsValue?.promptA?.label}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptA?.label}
                    onChange={fieldLabelChange("promptA")}
                    onBlur={() => enableEdit("editPromptA")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptA");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptA")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptA", "loadingA")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingA}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptA")}
              value={fieldsValue?.promptA?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptB?.label}</h3> */}

            {/* edit content promptB */}
            {fieldsValue?.editPromptB ? (
                <h3 className="label">{fieldsValue?.promptB?.label}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptB?.label}
                    onChange={fieldLabelChange("promptB")}
                    onBlur={() => enableEdit("editPromptB")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptB");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptB")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptB", "loadingB")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingB}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptB")}
              value={fieldsValue?.promptB?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptC?.label}</h3> */}
            {/* edit content promptC */}
            {fieldsValue?.editPromptC ? (
                <h3 className="label">{fieldsValue?.promptC?.label}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptC?.label}
                    onChange={fieldLabelChange("promptC")}
                    onBlur={() => enableEdit("editPromptC")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptC");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptC")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptC", "loadingC")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingC}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptC")}
              value={fieldsValue?.promptC?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/*  <h3 className="label">{fieldsValue?.promptD?.label}</h3> */}
            {/* edit content promptD */}
            {fieldsValue?.editPromptD ? (
                <h3 className="label">{fieldsValue?.promptD?.label}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptD?.label}
                    onChange={fieldLabelChange("promptD")}
                    onBlur={() => enableEdit("editPromptD")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptD");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptD")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptD", "loadingD")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingD}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptD")}
              value={fieldsValue?.promptD?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptE ? (
                <h3 className="label">{fieldsValue?.promptE?.label}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptE?.label}
                    onChange={fieldLabelChange("promptE")}
                    onBlur={() => enableEdit("editPromptE")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptE");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptE")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptE", "loadingE")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingE}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptE")}
              value={fieldsValue?.promptE?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptF ? (
                <h3 className="label">{fieldsValue?.promptF?.label || "PromptF"}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptF?.label || "PromptF"}
                    onChange={fieldLabelChange("promptF")}
                    onBlur={() => enableEdit("editPromptF")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptF");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptF")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptF", "loadingF")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingF}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptF")}
              value={fieldsValue?.promptF?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptG ? (
                <h3 className="label">{fieldsValue?.promptG?.label || "PromptG"}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptG?.label || "PromptG"}
                    onChange={fieldLabelChange("promptG")}
                    onBlur={() => enableEdit("editPromptG")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptG");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptG")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptG", "loadingG")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingG}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptG")}
              value={fieldsValue?.promptG?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptH ? (
                <h3 className="label">{fieldsValue?.promptH?.label || "PromptH"}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptH?.label || "PromptH"}
                    onChange={fieldLabelChange("promptH")}
                    onBlur={() => enableEdit("editPromptH")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptH");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptH")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptH", "loadingH")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingH}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptH")}
              value={fieldsValue?.promptH?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptI ? (
                <h3 className="label">{fieldsValue?.promptI?.label || "PromptI"}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptI?.label || "PromptI"}
                    onChange={fieldLabelChange("promptI")}
                    onBlur={() => enableEdit("editPromptI")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptI");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptI")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptI", "loadingI")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingI}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptI")}
              value={fieldsValue?.promptI?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
        <div className="textarea-wrapper">
          <div className="textarea-heading">
            {/* <h3 className="label">{fieldsValue?.promptE?.label}</h3> */}
            {/* edit content promptE */}
            {fieldsValue?.editPromptJ ? (
                <h3 className="label">{fieldsValue?.promptJ?.label || "PromptJ"}</h3>
            ) : (
                <TextArea
                    type="text"
                    value={fieldsValue?.promptJ?.label || "PromptJ"}
                    onChange={fieldLabelChange("promptJ")}
                    onBlur={() => enableEdit("editPromptJ")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") enableEdit("editPromptJ");
                    }}
                    autoFocus
                    className="edit-text-cb"
                />
            )}

            <Space>
              <Tooltip title="Edit">
                <Button
                    onClick={() => enableEdit("editPromptJ")}
                    shape="circle"
                    icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Regenerate">
                <Button
                    onClick={() => regenerateOneApi("promptJ", "loadingJ")}
                    shape="circle"
                    icon={<RedoOutlined spin={fieldsValue?.loadingJ}/>}
                />
              </Tooltip>
            </Space>
          </div>
          <TextArea
              onChange={fieldValueChange("promptJ")}
              value={fieldsValue?.promptJ?.value}
              rows={5}
              className="textarea-element"
              placeholder="Write your content here..."
          />
        </div>
      </Card>

      <div className="sidebar-container">
        <ChatBox/>
      </div>
    </div>
  );
};

export default Testing;
