import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Input, Radio, Row, Select, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ProjectItemRow from "./components/ProjectItemRow";
import { itemActions } from "./duck/itemReducer";
import { paramsToString, showNotification } from "../utils/commonFunctions";
import {
  SearchOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./ProjectItemList.scss";
import { projectActions } from "../project/duck/projectReducer";
import constants from "../utils/constants";
import moment from "moment-timezone";
import { useMemo } from "react";
import MyTable from "../components/MyTable";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import fileDownload from "js-file-download";
import MyTaskFilter from "../components/MyTaskFilter";

const { ROLE_IDS, FILTER_TYPE, TASK_TYPE, TASK_STATUS_OPTIONS } = constants;

const defaultFilterType = FILTER_TYPE?.CREATED_BY?._id;

const ProjectItemList = () => {
  const dispatch = useDispatch();
  const preProps = useRef();
  const { pathname } = useLocation();

  const [boardKey, setBoardKey] = useState("");
  const [isReset, setReset] = useState(false);
  const [state, setState] = useState({
    search: "",
    taskType: TASK_TYPE.ALL._id,
    filterType: defaultFilterType,
  });

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  const item = useSelector(({ item }) => item);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = item.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [item, boardKey]);

  const filters = useMemo(
    () => item?.filters?.[pathname],
    [item?.filters, pathname]
  );

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const {
          search,
          project_id,
          status_description,
          taskType,
          filterType,
          user,
          start_date,
          end_date,
        } = state;

        const params = {
          pathname,
          offset: offset,
          limit: limit,
          taskType,
        };
        if (search) params.search = search;
        if (project_id) params.project_id = project_id;
        if (status_description) params.status_description = status_description;
        if (filterType === FILTER_TYPE?.WRITER?._id && user)
          params.assigned_freelancer = user;
        if (filterType === FILTER_TYPE.CREATED_BY?._id && user)
          params.created_by = user;
        if (filterType === FILTER_TYPE.ASSIGNED_TO?._id && user)
          params.current_assignee = user;

        if (filterType === FILTER_TYPE.CREATED_DATE?._id) {
          if (start_date)
            params.start_date = moment(start_date)
              .startOf("day")
              .utc()
              .format();

          if (end_date)
            params.end_date = moment(end_date).endOf("day").utc().format();
        }

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, pathname, state]
  );

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      let parsedData = JSON.parse(filters);
      let filterType = FILTER_TYPE?.CREATED_BY?._id;

      if (parsedData?.assigned_freelancer) {
        filterType = FILTER_TYPE?.WRITER?._id;
        parsedData.user = parsedData?.assigned_freelancer;
      } else if (parsedData?.current_assignee) {
        filterType = FILTER_TYPE?.ASSIGNED_TO?._id;
        parsedData.user = parsedData?.current_assignee;
      } else if (parsedData?.created_by) {
        parsedData.user = parsedData?.created_by;
      }

      if (parsedData?.start_date) {
        parsedData.start_date = moment(parsedData?.start_date);
        filterType = FILTER_TYPE?.CREATED_DATE?._id;
      }
      if (parsedData?.end_date) {
        parsedData.end_date = moment(parsedData?.end_date);
        filterType = FILTER_TYPE?.CREATED_DATE?._id;
      }
      setState({
        search: "",
        filterType,
        ...parsedData,
      });
    }
  }, []);

  useEffect(() => {
    if (
      (!boardKey && !filters) ||
      isReset ||
      (boardKey && preProps?.current?.taskType !== state?.taskType)
    ) {
      setReset(false);
      loadMore();
    }

    return () => {
      preProps.current = { taskType: state?.taskType };
    };
  }, [state?.taskType, boardKey, filters, isReset, loadMore]);

  const onResetFilter = useCallback(() => {
    setState((preState) => ({
      taskType: preState?.taskType,
      search: "",
      filterType: defaultFilterType,
    }));
    setBoardKey("");
    setReset(true);
  }, []);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;
      let newState = {
        ...state,
        [name]: value,
      };
      if (name === "project_id" || name === "filterType") {
        newState.user = null;
      }

      setState(newState);
    },
    [state]
  );

  const projectObj = useSelector(({ project }) => {
    const projectMap = project.map;

    const boards = project.boards;

    const mBoard = boards[JSON.stringify({ draft: false, archive: false })];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let projects = list.map((projectId) => projectMap[projectId]);
    projects = projects.filter((project) => project);

    return { projects, meta, loading };
  });

  const loadMoreProjects = useCallback(
    (offset = 0, limit = 10) => {
      if (projectObj?.loading) return;
      if (
        offset === 0 ||
        projectObj?.meta?.totalCount > projectObj?.projects?.length
      ) {
        const params = {
          draft: false,
          archive: false,
          offset,
          limit,
        };
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      projectObj?.loading,
      projectObj?.meta?.totalCount,
      projectObj?.projects?.length,
    ]
  );

  useEffect(() => {
    if (!projectObj?.meta) {
      loadMoreProjects();
    } else if (
      !projectObj?.loading &&
      projectObj?.projects?.length < projectObj?.meta?.totalCount
    ) {
      loadMoreProjects(projectObj?.projects?.length);
    }
  }, [
    loadMoreProjects,
    projectObj?.loading,
    projectObj?.meta,
    projectObj?.meta?.totalCount,
    projectObj?.projects?.length,
  ]);

  const onFilterOption = useCallback((input, option) => {
    const project = option.props.project;
    const projectName = project?.name.toLowerCase();
    const mInput = input.toLowerCase();

    return projectName.indexOf(mInput) >= 0;
  }, []);

  const handleDownload = useCallback(() => {
    axios({
      method: "GET",
      url: `${AppUrl.ITEMS}/export`,
      params: {
        taskType: state?.taskType,
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (response.data) {
          let filename = response.headers["content-type"].split("=");
          fileDownload(response.data, filename[1]);
          showNotification("success", "File downloaded.");
        } else {
          showNotification("error", "Error while downloading file.");
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Something went wrong");
      });
  }, [state?.taskType]);

  const columns = useMemo(
    () => [
      { key: 1, title: "Task Name" },
      { key: 2, title: "Project" },
      { key: 3, title: "Created By" },
      { key: 4, title: "Assigned To" },
      { key: 5, title: "Assigned Writer" },
      { key: 6, title: "Status" },
    ],
    []
  );

  return (
    <div className="content-body app-container project-item-list-container">
      <div className="stats-container pri-box-shadow">
        <div className="stats">
          <span className="value">{meta?.overAllTaskCount ?? 0}</span>
          <span className="label">Total Task</span>
        </div>
        <div className="stats">
          <span className="value"> {meta?.todoTaskCount ?? 0} </span>
          <span className="label">Your Todo</span>
        </div>
        {loggedInUser?.role_id !== ROLE_IDS.FREELANCER && (
          <div className="stats">
            <span className="value">{meta?.taskOfWriterCount ?? 0}</span>
            <span className="label">Writer</span>
          </div>
        )}
        {loggedInUser?.role_id !== ROLE_IDS.EDITOR && (
          <div className="stats">
            <span className="value">{meta?.taskOfEditorCount ?? 0}</span>
            <span className="label">Editor</span>
          </div>
        )}
        {loggedInUser?.role_id !== ROLE_IDS.MANAGER && (
          <div className="stats">
            <span className="value">{meta?.initCount ?? 0}</span>
            <span className="label">Manager</span>
          </div>
        )}

        <div className="stats">
          <span className="value">{meta?.completedCount ?? 0}</span>
          <span className="label">Completed</span>
        </div>
        <div className="stats">
          <span className="value">{meta?.closedCount ?? 0}</span>
          <span className="label">Closed</span>
        </div>
      </div>
      <Row className="fw mt30" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={11}>
          <Input
            className="new_search fw"
            placeholder="Search Your Task Here"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>
        {loggedInUser?.role_id !== ROLE_IDS.FREELANCER && (
          <Col xs={24} sm={12} md={8} lg={4} xl={3} xxl={2}>
            <Select
              className="new_select fw"
              size="large"
              placeholder="Status"
              // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
              clearIcon={
                <CloseCircleOutlined
                  style={{ color: "#fff", background: "#000", fontSize: 13 }}
                />
              }
              dropdownMatchSelectWidth={false}
              value={state?.status_description}
              onChange={handleChange("status_description")}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              allowClear
            >
              {Object.values(TASK_STATUS_OPTIONS).map((status) => (
                <Select.Option key={status._id} value={status._id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
        <Col xs={24} sm={12} md={8} lg={5} xl={3} xxl={2}>
          <Select
            className="new_select fw"
            placeholder="Project"
            // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            size="large"
            dropdownMatchSelectWidth={false}
            value={state?.project_id}
            onChange={handleChange("project_id")}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={onFilterOption}
            showSearch
            allowClear
          >
            {projectObj?.projects?.map((project) => {
              return (
                <Select.Option
                  key={project?._id}
                  value={project?._id}
                  project={project}
                >
                  {project?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        {loggedInUser?.role_id !== ROLE_IDS.FREELANCER && (
          <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={4}>
            <MyTaskFilter handleChange={handleChange} state={state} />
          </Col>
        )}
        <Col xs={24} sm={12} md={8} lg={4} xl={3} xxl={2}>
          <Button
            className="fw new_button"
            type="primary"
            size="large"
            onClick={() => loadMore()}
          >
            Submit
          </Button>
        </Col>
        <Col xs={24} sm={12} md={8} lg={3} xl={2} xxl={2}>
          <Button
            className="fw new_button"
            type="primary-outline"
            size="large"
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <div className="view-buttons mt30">
        <Row wrap={false} justify={"space-between"}>
          <Radio.Group
            onChange={handleChange("taskType")}
            defaultValue={TASK_TYPE.ALL._id}
            value={state?.taskType}
          >
            {Object.values(TASK_TYPE).map((type) => (
              <Radio.Button value={type._id} key={type._id}>
                {type.name}
              </Radio.Button>
            ))}
          </Radio.Group>

          <Button
            className="new_button mr20"
            type="primary"
            size="large"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Row>
      </div>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <ProjectItemRow key={i} id={item} showWriter />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default ProjectItemList;
