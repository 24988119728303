import MSlice from "../../helper/MSlice";
import Task from "../../models/Task";

const taskSlice = new MSlice({
  name: "task",
  Model: Task,
  reducers: {
    onAssignRequest: MSlice.handleLoading,
    onSubmitRequest: MSlice.handleLoading,
    onFeedbackRequest: MSlice.handleLoading,
  },
});

export const taskActions = taskSlice.getActions();

export default taskSlice.getReducer();
