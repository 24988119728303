import React, { useCallback, useMemo } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import Template from "../../models/Template";
import template from "../../assets/images/Template.jpeg";
import "./ProjectTemplateItem.scss";

const ProjectTemplateItem = (props) => {
  const { id, isSelected } = props;

  const templateMap = useSelector(({ template }) => template.map);
  const mTemplate = useMemo(
    () => new Template().fromMap(templateMap[id]),
    [templateMap, id]
  );

  const onSelect = useCallback(
    () => !props.disabled && props.onSelect(id),
    [id, props]
  );

  if (!mTemplate._id) {
    return <></>;
  }

  return (
    <Row
      className={`project-template-item-container ${
        isSelected ? "selected-template-item" : ""
      }`}
      justify="space-between"
      onClick={onSelect}
    >
      {/* image start */}
      <img className="template-image" src={template} alt={mTemplate.name} />
      {/* image end */}

      {/* name container end */}
      <Col className="detail-container">
        <span>{mTemplate.name}</span>
      </Col>
      {/* name container end */}
    </Row>
  );
};

export default React.memo(ProjectTemplateItem);
