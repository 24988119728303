import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { assessmentActions } from "./duck/assessmentReducer";
import { paramsToString } from "../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import routes from "../utils/routes";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import MyTable from "../components/MyTable";
import AssessmentRow from "./components/AssessmentRow";
import constants from "../utils/constants";
const { ASSESSMENTS_LEVEL } = constants;

const AssessmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [boardKey, setBoardKey] = useState("");
  const [state, setState] = useState({ search: "" });

  const { list, meta, loading } = useSelector(({ assessment }) => {
    const boards = assessment.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { search, level } = state;
        const params = {
          offset: offset,
          limit: limit,
        };
        if (search) params.search = search;
        if (level) params.level = level;
        setBoardKey(paramsToString(params));
        dispatch(assessmentActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, state]
  );

  useEffect(() => {
    if (!boardKey) {
      loadMore();
    }
  }, [boardKey]);

  const onResetFilter = useCallback(() => {
    setState({ search: "" });
    setBoardKey("");
  }, []);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );

  const columns = useMemo(
    () => [
      { key: 1, title: "S.No.", style: { width: 0 } },
      { key: 2, title: "Assessment Name" },
      { key: 3, title: "Level" },
      { key: 4, title: "Questions" },
      { key: 5, title: "Action", style: { width: 0 } },
    ],
    []
  );

  return (
    <div className="content-body app-container  project-draft-container">
      <Row className="page-header fw" justify="space-between" align="middle">
        <Row className="page-heading">Assessments</Row>

        <Row className="fw" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
            <Input
              className="new_search fw"
              size="large"
              placeholder="Assessment Name"
              prefix={<SearchOutlined />}
              value={state?.search}
              onChange={handleChange("search")}
              allowClear
            />
          </Col>
          <Col xs={24} sm={8} md={8} lg={6} xl={3} xxl={2}>
            <Select
              className="new_select fw"
              size="large"
              value={state?.level}
              clearIcon={
                <CloseCircleOutlined
                  style={{ color: "#fff", background: "#000", fontSize: 13 }}
                />
              }
              dropdownMatchSelectWidth={false}
              placeholder="Level"
              onChange={handleChange("level")}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={loading}
            >
              {ASSESSMENTS_LEVEL.map((level, i) => (
                <Select.Option key={i} title={level} value={level} />
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={8} md={8} lg={6} xl={3} xxl={2}>
            <Button
              type="primary"
              size="large"
              className="fw new_button"
              onClick={() => loadMore()}
            >
              Search
            </Button>
          </Col>
          <Col xs={24} sm={8} md={8} lg={6} xl={3} xxl={2}>
            <Button
              size="large"
              className="fw new_button"
              type="primary-outline"
              onClick={onResetFilter}
            >
              Reset
            </Button>
          </Col>
          <Row
            style={{
              marginLeft: "auto",
            }}
          >
            <Button
              type="primary"
              className="new_button"
              size="large"
              onClick={() => navigate(routes.ASSESSMENT_DETAILS)}
            >
              Add New Set
            </Button>
          </Row>
        </Row>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          renderItem={(item, i) => (
            <AssessmentRow key={i} serialNo={i + 1} id={item} />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default AssessmentList;
