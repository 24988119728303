import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Input, Button } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { validPassword } from "../../utils/commonFunctions";
import CustomModal from "../../components/CustomModal";
import { loggedInUserActions } from "../../loggedInUser/duck/loggedInUserReducer";

const ChangeProfilePassword = (props) => {
  const dispatch = useDispatch();
  const prevProp = useRef();
  const { visible, showModal } = props;

  const { loading, error } = useSelector(({ loggedInUser }) => loggedInUser);

  const [state, setState] = useState({});
  const [err, setErr] = useState({});

  const { oldPassword, newPassword, confirmPassword } = state;

  useEffect(() => {
    if (prevProp?.current?.loading && !loading) {
      if (!error) {
        showModal(false);
      }
    } else if (!prevProp?.current?.visible && visible) {
      setState({});
      setErr({});
    }
    return () => {
      prevProp.current = { loading, visible };
    };
  }, [visible, loading, showModal, error]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({
        ...preState,
        [name]: value,
      }));
      setErr({});
    },
    []
  );

  const hasError = useCallback(() => {
    let { oldPassword, newPassword, confirmPassword } = state;
    oldPassword = oldPassword?.trim?.();
    newPassword = newPassword?.trim?.();
    confirmPassword = confirmPassword?.trim?.();

    let error = {};

    if (!oldPassword) {
      error.oldPassword = "Please enter old password";
    }

    if (!newPassword) {
      error.newPassword = "Please enter new password";
    } else if (!validPassword(newPassword)) {
      error.newPassword = "Password length should be of minimum 4 characters";
    } else if (oldPassword === newPassword) {
      error.newPassword = "New Password cannot be same as old password";
    }

    if (!confirmPassword) {
      error.confirmPassword = "Please enter confirm password";
    } else if (!validPassword(confirmPassword)) {
      error.confirmPassword =
        "Password length should be of minimum 4 characters";
    } else if (newPassword !== confirmPassword) {
      error.confirmPassword = "New password and Confirm password not same";
    }

    setErr(error);
    return !!Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      let userData = {
        oldPassword,
        password: newPassword,
      };
      dispatch(loggedInUserActions.updatePassword(userData));
    }
  }, [dispatch, hasError, newPassword, oldPassword]);

  return (
    <CustomModal
      visible={visible}
      title="Change Password"
      okButtonText="Submit"
      cancelButtonText="Cancel"
      onCancel={showModal}
      footer={
        <Row className="p10">
          <Button
            type="primary"
            size="large"
            loading={loading}
            onClick={onSubmit}
          >
            Update
          </Button>
          <Button size="large" type="primary-outline" onClick={showModal}>
            Cancel
          </Button>
        </Row>
      }
      handleSuccess={onSubmit}
      loading={loading}
      width={600}
    >
      <div>
        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Old Password </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              size="large"
              name="oldPassword"
              value={oldPassword}
              onChange={handleChange("oldPassword")}
              autoComplete="new-password"
              allowClear
            />
            <Row className="error mt5">{err?.oldPassword}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">New Password </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              size="large"
              name="newPassword"
              value={newPassword}
              onChange={handleChange("newPassword")}
              allowClear
            />
            <Row className="error mt5">{err?.newPassword}</Row>
          </Col>
        </Row>

        <Row align="middle" className="mb10">
          <Col lg={6} md={24} sm={24} xs={24}>
            <label className="label">Confirm Password</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col lg={18} md={24} sm={24} xs={24}>
            <Input.Password
              size="large"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange("confirmPassword")}
              allowClear
            />
            <Row className="error mt5">{err?.confirmPassword}</Row>
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default ChangeProfilePassword;
