import MSaga from "../../helper/MSaga";
import { commentActions } from "./commentReducer";
import appUrl from "../../config/appUrl";

const commentSaga = new MSaga({
  name: "comment",
  apiEndPoint: appUrl.COMMENTS,
  actions: commentActions,
  messages: {
    create: {
      success: "",
    },
  },
});

export default commentSaga.getWatchSaga();
