import { Col, Input, Row, Button } from "antd";
import React, { useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loggedInUserActions } from "../loggedInUser/duck/loggedInUserReducer";
import {
  validPassword,
  validEmail,
  isAuthenticated,
  showNotification,
} from "../utils/commonFunctions";
import routes from "../utils/routes";
import logo from "../assets/images/logoBlack.svg";
import logoCreator from "../assets/images/writelyForCreator.svg";
import logoRequestor from "../assets/images/writelyForRequestor.svg";
import "./login.scss";
// import OtsuGoogle from "./OtsuGoogle";

import GoogleSignInBtn from "./component/GoogleSignInBtn";

import axios from "axios";
import appUrl from "../config/appUrl";

import constants from "../utils/constants";
import { useMemo } from "react";
const { ROLE_IDS } = constants;

const LoginContainer = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser);

  const isClientUrl = useMemo(
    () => location?.pathname === routes.CLIENT_LOGIN,
    [location?.pathname]
  );

  const { greetingUserText, logoFile } = useMemo(() => {
    let greetingUserText = "User";
    let logoFile = logo;
    switch (location?.pathname) {
      case routes.CLIENT_LOGIN:
        return { greetingUserText: "business", logoFile: logoRequestor };

      case routes.FREELANCER_LOGIN:
        return { greetingUserText: "writer's", logoFile: logoCreator };

      default:
        return { greetingUserText, logoFile };
    }
  }, [location?.pathname]);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({ email: null, password: null });
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);

  const hasError = useCallback(() => {
    const { email, password } = state;
    let error = {};

    if (!email?.trim()) {
      error.email = "Email cannot be blank";
    } else if (!validEmail(email)) {
      error.email = "Email is not valid";
    }
    if (!password?.trim()) {
      error.password = "Password cannot be blank";
    } else if (!validPassword(password)) {
      error.password = "Password length should be of minimum 4 characters";
    }

    setError(error);

    return !!Object.keys(error)?.length;
  }, [state]);

  const handleEmailSubmit = useCallback(() => {
    if (!hasError()) {
      const { email, password } = state;
      dispatch(loggedInUserActions.loginRequest({ email, password }));
    }
  }, [dispatch, hasError, state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((prestate) => ({
        ...prestate,
        [name]: value,
      }));
      setError({});
    },
    []
  );

  const submitGoogleCredentials = async (accessToken) => {
    let credentials = {
      accessToken: accessToken,
    };

    setLoadingGoogle(true);
    const loginRequest = (data) => {
      return axios({
        method: "POST",
        url: appUrl.GOOGLE_LOGIN_URL,
        mode: "cors",
        data: {
          access_token: accessToken,
          ...(isClientUrl && {
            role_id: constants.ROLE_IDS.CLIENT,
          }),
        },
      });
    };

    const response = await loginRequest(credentials);

    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      dispatch(loggedInUserActions.onHandleError(data));
    } else {
      const { token, user } = data.data;

      if (
        location?.pathname === routes.FREELANCER_LOGIN &&
        user?.role_id === ROLE_IDS.FREELANCER &&
        !user?.expertise
      ) {
        const data = {
          email: user?.email,
          user_id: user?._id,
          token,
        };
        navigate(routes.FREELANCER_SIGNUP, { state: { data }, replace: true });
      } else {
        if (user.profile_approved === "pending") {
          localStorage.setItem(constants.ACCESS_TOKEN, token);
          navigate(routes.DASHBOARD, {
            state: { autoNavigate: true },
            replace: true,
          });
        } else {
          localStorage.setItem(constants.ACCESS_TOKEN, token);
          dispatch(loggedInUserActions.onHandleSuccess(user));
        }
      }
    }
    setLoadingGoogle(false);
  };

  if (isAuthenticated()) {
    const pathname = location.state?.from;
    const role = loggedInUser?.data?.role?.role_id;

    if (pathname?.pathname) {
      return <Navigate to={pathname?.pathname} replace />;
    } else if (role === constants.ROLE_IDS.FREELANCER) {
      return (
        <Navigate
          to={routes.PROJECT_TASK}
          state={{ autoNavigate: true }}
          replace
        />
      );
    } else {
      return (
        <Navigate
          to={routes.DASHBOARD}
          state={{ autoNavigate: true }}
          replace
        />
      );
    }
  }

  return (
    <div className="loginContainer">
      <div className="logo-container pointer">
        <img src={logoFile} alt="Writely" className="logo" />
      </div>

      <div className="container-step-1">
        <Row className="info mt20 mb20">
          {location?.pathname !== routes.LOGIN
            ? `Welcome to Writely! Please log in with your ${greetingUserText} account`
            : "Welcome to Writely!  Please sign  in with your account"}
        </Row>

        <Row className="input-container mb20">
          <label>Email</label>
          <Input
            className="fw"
            placeholder="Enter your email address"
            size="large"
            onChange={handleChange("email")}
            onPressEnter={handleEmailSubmit}
            value={state?.email}
            disabled={loggedInUser.loading}
          />
          <Row className="error">{error?.email}</Row>
        </Row>

        <Row className="input-container mb20">
          <Col span={24}>
            <Row justify="space-between">
              <label>Password</label>
            </Row>
          </Col>
          <Input
            type="password"
            placeholder="Create a strong password"
            size="large"
            onChange={handleChange("password")}
            value={state?.password}
            onPressEnter={handleEmailSubmit}
            disabled={loggedInUser.loading}
          />

          <Row className="error">{error?.password}</Row>
          <Row className="fw" align="middle" justify="space-between">
            <span
              className="extra-content mt10"
              onClick={() => navigate(`${routes.SIGNUP}${location?.pathname}`)}
            >
              {location?.pathname !== routes.LOGIN && "Don't have an account"}
            </span>
            <span
              className="extra-content mt10"
              onClick={() =>
                navigate(routes.FORGOTPASSWORD, { state: { from: location } })
              }
            >
              Forgot Password
            </span>
          </Row>
        </Row>

        {/* Buttons Container Start */}
        <Button
          type="primary"
          block
          onClick={handleEmailSubmit}
          loading={loggedInUser.loading}
          size="large"
          className="signup-btn mb20"
        >
          Login
        </Button>
        <Row justify="center" className="mb20 bold">
          Or
        </Row>
        <GoogleSignInBtn
          text="Continue with Google"
          loading={isLoadingGoogle}
          onSignInSuccess={submitGoogleCredentials}
        />

        {/* <OtsuGoogle
          isLoadingGoogle={isLoadingGoogle}
          valueText={"Continue with Google"}
          submitGoogleCredentials={submitGoogleCredentials}
        /> */}
        {/* Buttons Container End */}
      </div>
    </div>
  );
};

export default LoginContainer;
