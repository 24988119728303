import React, { useMemo } from "react";
import { Card, Col, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";

import { abbreviateNumber } from "../../utils/commonFunctions";

const DashboardCounterCard = (props) => {
  const { _id, name, icon } = props;

  const dashboardData = useSelector(({ openAi }) => openAi?.dashboard?.data);

  const count = useMemo(() => dashboardData?.[_id] || 0, [dashboardData, _id]);

  return (
    <Col
      className="counter-card-container"
      xs={24}
      sm={12}
      md={12}
      lg={8}
      xl={6}
      xxl={4}
    >
      <Card className="counter-card container-shadow fw" bordered={false}>
        <Row>
          <Col span={18}>
            <Col className="text-6xl font-semibold mb20">
              {count > 999 ? (
                <Tooltip
                  title={count?.toLocaleString?.("en-In")}
                  showArrow={false}
                >
                  <span>{abbreviateNumber(count)}</span>
                </Tooltip>
              ) : (
                <span>{count}</span>
              )}
            </Col>

            <Col className="text-sm font-medium text-primary opacity-80 mt20">
              {name}
            </Col>
          </Col>

          <Col span={6}>
            <img className="counter-icon" src={icon} alt={name} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default React.memo(DashboardCounterCard);
