import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import MyTable from "../../components/MyTable";

import { openAiActions } from "../duck/openAiReducer";

import { arrayToMap, paramsToString } from "../../utils/commonFunctions";

const RecentlyGeneratedContentTable = () => {
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("{}");
  const { list, meta, loading } = useSelector(({ openAi }) => {
    const boards = openAi.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });
  const recentContentMap = useSelector(({ openAi }) =>
    Object.assign({}, openAi.recentContentMap)
  );

  const loadMore = useCallback(
    (offset = 0, limit = 10) => {
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const params = {
          offset: offset,
          limit: limit,
          tableName: "RecentlyGeneratedContentTable",
        };

        setBoardKey(paramsToString(params));
        dispatch(openAiActions.onGetAllRecentlyGeneratedContentRequest(params));
      }
    },
    [dispatch, list, meta, loading]
  );

  useEffect(() => {
    loadMore();
  }, []);

  const columns = useMemo(
    () => [
      { title: "Date" },
      { title: "Project Name" },
      { title: "Content Type" },
      { title: "Total Consumed Words" },
    ],
    []
  );

  const renderRowItem = useCallback(
    (item, i) => {
      const mContent = recentContentMap[item];
      const mTask = mContent?.task;

      const fields = arrayToMap(mTask?.fields);
      const attachment = mTask?.attachment;

      const taskName =
        mTask?.name ||
        fields?.task_name?.value ||
        attachment?.filename ||
        "Untitled";
      const contentType = fields?.content_type?.value;
      const createdAt = moment(mTask?.created_at).format("DD MMM YYYY");

      return (
        <tr key={`${item}-${i}`}>
          <td>{createdAt}</td>

          <td className="max-w-xs">{taskName}</td>

          <td>{contentType}</td>

          <td>{mContent?.total_word_count || 0} Words</td>
        </tr>
      );
    },
    [recentContentMap]
  );

  return (
    <Col className="recent-content-table-container mb-12">
      <MyTable
        columns={columns}
        list={list}
        renderItem={renderRowItem}
        totalCount={meta?.totalCount || 0}
        loadMore={loadMore}
        loading={loading}
      />
    </Col>
  );
};

export default React.memo(RecentlyGeneratedContentTable);
