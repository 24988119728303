import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import constants from "../utils/constants";

import { itemActions } from "../item/duck/itemReducer";
import {
  downloadFile,
  paramsToString,
  showNotification,
} from "../utils/commonFunctions";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PlusIcon from "../assets/images/plusIcon.svg";

import { projectActions } from "../project/duck/projectReducer";
import ProjectItemRow from "../item/components/ProjectItemRow";
import User from "../models/User";
import { userActions } from "../users/duck/userReducer";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import routes from "../utils/routes";
import MyTable from "../components/MyTable";
import MyTaskFilterDate from "../components/MyTaskFilterDate";
import moment from "moment-timezone";
const { PROJECT_TASK_STATUS, ROLE_IDS, FILTER_TYPE_DATE } = constants;
const defaultFilterType = FILTER_TYPE_DATE?.All?._id;
const InvoiceListing = () => {
  const dispatch = useDispatch();
  const preProps = useRef();
  const { pathname } = useLocation();

  const [state, setState] = useState({
    search: "",
    ids: [],
    filterType: defaultFilterType,
    modelOpen: false,
  });

  const [boardKey, setBoardKey] = useState("");
  const [usersBoardKey, setUsersBoardKey] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isReset, setReset] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

  const item = useSelector(({ item }) => item);
  const { list, meta, loading } = useMemo(() => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = item.boards;
    const mBoard = boards[mFilter] || {};

    return mBoard || {};
  }, [item, boardKey]);

  const filters = useMemo(
    () => item?.filters?.[pathname],
    [item?.filters, pathname]
  );

  const usersObj = useSelector(({ user }) => {
    const userMap = user.map;

    const boards = user.boards;
    const mBoard = boards[usersBoardKey];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let users = list.map((userId) => userMap[userId]);
    users = users.filter((user) => user);

    return { users, meta, loading };
  });

  const pageData = useMemo(() => {
    if (pathname === routes.INVOICE_WRITER) {
      return {
        invoice_type: "writer",
        // role_id: [ROLE_IDS.FREELANCER, ROLE_IDS.EDITOR, ROLE_IDS.MANAGER],
      };
    } else if (pathname === routes.INVOICE_REQUESTOR) {
      return {
        invoice_type: "requestor",
        // role_id: ROLE_IDS.CLIENT,
      };
    }
  }, [pathname]);

  const loadMoreUsers = useCallback(
    (offset = 0, limit = 10) => {
      if (usersObj?.loading) return;
      if (
        offset === 0 ||
        usersObj?.meta?.totalCount > usersObj?.users?.length
      ) {
        const params = {
          role_id: [ROLE_IDS.FREELANCER, ROLE_IDS.EDITOR, ROLE_IDS.MANAGER],
          offset,
          limit,
        };

        setUsersBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      usersObj?.loading,
      usersObj?.meta?.totalCount,
      usersObj?.users?.length,
    ]
  );

  useEffect(() => {
    if (loggedInUser?.role_id !== ROLE_IDS.FREELANCER) {
      loadMoreUsers(usersObj?.users?.length, usersObj?.meta?.totalCount);
    }
  }, [
    loggedInUser?.role_id,
    usersObj?.meta?.totalCount,
    usersObj?.users?.length,
  ]);

  const loadMore = useCallback(
    (offset = 0, limit = 15) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const {
          search,
          project_id,
          assigned_freelancer,
          invoice_status,
          filterType,
          start_date,
          end_date,
        } = state;

        const params = {
          pathname,
          offset: offset,
          limit: limit,
          invoice_type: pageData?.invoice_type,
          status_description: PROJECT_TASK_STATUS.APPROVED,
        };
        switch (filterType) {
          case "all_data":
            break;
          case "current_month":
            const currentMonthStartDate = moment().startOf("month");
            const todayDate = moment();
            params.start_date = moment(currentMonthStartDate)
              .startOf("day")
              .utc()
              .format();
            params.end_date = moment(todayDate).endOf("day").utc().format();
            break;
          case "last_month":
            const lastMonthStart = moment()
              .subtract(1, "month")
              .startOf("month");
            const lastMonthEnd = moment().subtract(1, "month").endOf("month");
            params.start_date = moment(lastMonthStart)
              .startOf("day")
              .utc()
              .format();
            params.end_date = moment(lastMonthEnd).endOf("day").utc().format();
            break;
          case "custom_date":
            if (start_date)
              params.start_date = moment(start_date)
                .startOf("day")
                .utc()
                .format();
            if (end_date)
              params.end_date = moment(end_date).endOf("day").utc().format();
            break;
          default:
            break;
        }

        if (search) params.search = search;
        if (project_id) params.project_id = project_id;
        if (assigned_freelancer)
          params.assigned_freelancer = assigned_freelancer;
        if (typeof invoice_status === "boolean")
          params.invoice_status = invoice_status;

        setBoardKey(paramsToString(params, ["pathname"]));
        dispatch(itemActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      list?.length,
      meta?.totalCount,
      pageData?.invoice_type,
      pathname,
      state,
    ]
  );

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setState((preState) => ({ ...preState, [name]: value }));
    },
    []
  );

  const handleCheckBoxes = useCallback(
    (isChecked, id) => {
      let value;
      if (isChecked) {
        value = [...new Set([...state?.ids, id])];
      } else if (!isChecked) {
        value = state?.ids?.filter((i) => i !== id);
      }

      setState((preState) => ({ ...preState, ids: value }));
    },
    [state?.ids]
  );

  const handleSelectAll = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      setIsCheckAll(isChecked);
      const updatedIds = isChecked ? list : [];
      setState((prevState) => ({ ...prevState, ids: updatedIds }));
    },
    [list]
  );

  const onResetFilter = useCallback(() => {
    setState({ search: "", ids: [], filterType: defaultFilterType });
    setBoardKey("");
    setReset(true);
    setIsCheckAll(true);
  }, []);

  useEffect(() => {
    if (filters) {
      setBoardKey(filters);
      setState({ ids: [], ...JSON.parse(filters) });
    }
  }, []);

  useEffect(() => {
    if (
      (!boardKey && !filters) ||
      isReset ||
      (boardKey && preProps?.current?.pathname !== pathname)
    ) {
      loadMore();
      setReset(false);
    }

    return () => {
      preProps.current = { pathname };
    };
  }, [boardKey, filters, isReset, loadMore, pathname]);

  const generateInvoice = useCallback(() => {
    setButtonLoading(true);
    axios({
      method: "GET",
      url: `${AppUrl.INVOICE}`,
      params: {
        ids: state?.ids,
        invoice_type: pageData?.invoice_type,
      },
    })
      .then((response) => {
        const { data, meta } = response?.data;

        if (meta?.success !== true || meta?.status !== 200) {
          showNotification("error", meta?.message || "Something Went wrong");
        } else {
          if (data?.attachment?.url) {
            downloadFile(data?.attachment?.url, data?.name);
            const invoice = [
              {
                invoice_type: pageData?.invoice_type,
                ...data,
              },
            ];

            state?.ids?.forEach((id) => {
              const mItem = item?.map?.[id];
              dispatch(itemActions.onUpdateOneSuccess({ ...mItem, invoice }));
            });
            showNotification("success", "Invoice Created Successfully.");
          } else {
            showNotification("error", "Error while downloading file.");
          }

          setState({ search: "", ids: [] });
        }
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);

        showNotification("error", "Something went wrong");
      });
  }, [dispatch, item?.map, pageData?.invoice_type, state?.ids]);

  const projectObj = useSelector(({ project }) => {
    const projectMap = project.map;

    const boards = project.boards;

    const mBoard = boards[JSON.stringify({ draft: false, archive: false })];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let projects = list.map((projectId) => projectMap[projectId]);
    projects = projects.filter((project) => project);

    return { projects, meta, loading };
  });

  const loadMoreProjects = useCallback(
    (offset = 0, limit = 10) => {
      if (projectObj?.loading) return;
      if (
        offset === 0 ||
        projectObj?.meta?.totalCount > projectObj?.projects?.length
      ) {
        const params = {
          draft: false,
          archive: false,
          offset,
          limit,
        };

        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [
      dispatch,
      projectObj?.loading,
      projectObj?.meta?.totalCount,
      projectObj?.projects?.length,
    ]
  );

  useEffect(() => {
    if (!projectObj?.meta) {
      loadMoreProjects();
    } else if (
      !projectObj?.loading &&
      projectObj?.projects?.length < projectObj?.meta?.totalCount
    ) {
      loadMoreProjects(projectObj?.projects?.length);
    }
  }, [
    loadMoreProjects,
    projectObj?.loading,
    projectObj?.meta,
    projectObj?.meta?.totalCount,
    projectObj?.projects?.length,
  ]);

  const canRaiseInvoice = useMemo(() => {
    let show = false;
    switch (loggedInUser?.role_id) {
      case ROLE_IDS.SUPER_ADMIN:
      case ROLE_IDS.ADMIN:
      case ROLE_IDS.MANAGER:
      case ROLE_IDS.FREELANCER:
        show = true;
        break;

      default:
        break;
    }
    return show;
  }, [loggedInUser?.role_id]);

  const onFilterOption = useCallback((input, option) => {
    const project = option.props.project;
    const projectName = project?.name.toLowerCase();
    const mInput = input.toLowerCase();

    return projectName.indexOf(mInput) >= 0;
  }, []);

  const onUserFilterOption = useCallback((input, option) => {
    const user = option.props.user;
    const fullName = user.fullName.toLowerCase();
    const email = user.email.toLowerCase();
    const mInput = input.toLowerCase();

    return fullName.indexOf(mInput) >= 0 || email.indexOf(mInput) >= 0;
  }, []);

  const columns = [
    { key: 1, title: "Task Name" },
    { key: 2, title: "Project" },
    { key: 3, title: "Created By" },
    { key: 5, title: "Assigned Writer" },
    { key: 6, title: "Status" },
  ];

  const handleClickList = useCallback(
    (id) => {
      setState((preState) => ({
        ...preState,
        filterType: id,
        modelOpen: !preState.modelOpen,
      }));
    },
    [state.filterType]
  );

  return (
    <div className="content-body app-container project-item-list-container">
      <Row className="fw" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
          <Input
            className="new_search fw"
            size="large"
            placeholder="Task Name or Task ID"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
            allowClear
          />
        </Col>

        {loggedInUser?.role_id !== ROLE_IDS.FREELANCER && (
          <Col xs={24} sm={8} md={8} lg={8} xl={3} xxl={2}>
            <Select
              className="new_select fw"
              size="large"
              placeholder="Writer"
              // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
              clearIcon={
                <CloseCircleOutlined
                  style={{ color: "#fff", background: "#000", fontSize: 13 }}
                />
              }
              dropdownMatchSelectWidth={false}
              value={state.assigned_freelancer}
              onChange={handleChange("assigned_freelancer")}
              optionFilterProp="children"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={onUserFilterOption}
              allowClear
              showSearch
            >
              {usersObj?.users.map((userMap, index) => {
                const user = new User().fromMap(userMap);
                return (
                  <Select.Option key={index} value={user._id} user={user}>
                    <span className="ml10">{user.fullName || user.email}</span>
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        )}
        <Col xs={24} sm={8} md={8} lg={8} xl={3} xxl={2}>
          <Select
            className="new_select fw"
            placeholder="Project"
            // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            size="large"
            dropdownMatchSelectWidth={false}
            value={state?.project_id}
            onChange={handleChange("project_id")}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={onFilterOption}
            allowClear
            showSearch
          >
            {projectObj?.projects?.map((project) => {
              return (
                <Select.Option
                  key={project?._id}
                  value={project?._id}
                  project={project}
                >
                  {project?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={3} xxl={2}>
          <Select
            className="new_select fw"
            // suffixIcon={<CaretDownOutlined style={{ color: "#fff" }} />}
            clearIcon={
              <CloseCircleOutlined
                style={{ color: "#fff", background: "#000", fontSize: 13 }}
              />
            }
            placeholder="Status"
            dropdownMatchSelectWidth={false}
            value={state?.invoice_status}
            onChange={handleChange("invoice_status")}
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
          >
            <Select.Option value={true}>Invoiced</Select.Option>
            <Select.Option value={false}>To Be Invoiced</Select.Option>
          </Select>
        </Col>

          <Col xs={24} sm={12} md={8} lg={8} xl={4} xxl={4}>
            <MyTaskFilterDate
              handleChange={handleChange}
              state={state}
              handleClickList={handleClickList}
            />
          </Col>

        <Col xs={24} sm={7} md={8} lg={6} xl={2} xxl={2}>
          <Button
            className="new_button fw"
            type="primary"
            size="large"
            onClick={() => loadMore()}
          >
            Submit
          </Button>
        </Col>

        <Col xs={24} sm={7} md={8} lg={6} xl={2} xxl={2}>
          <Button
            className="new_button fw"
            type="primary-outline"
            size="large"
            onClick={onResetFilter}
          >
            Reset
          </Button>
        </Col>

        <Row
          style={{
            marginLeft: "auto",
          }}
        >
          {canRaiseInvoice && (
            <Button
              className="new_button"
              type="primary"
              onClick={generateInvoice}
              loading={buttonLoading}
              icon={<img src={PlusIcon} alt="plus icon" className="mr10" />}
              disabled={!state?.ids?.length}
            >
              Create Invoice
            </Button>
          )}
        </Row>
      </Row>
      <Row className="fw mt30">
        <MyTable
          columns={columns}
          loading={loading}
          list={list}
          handleClick={handleSelectAll}
          isChecked={isCheckAll}
          showCheckbox={true}
          renderItem={(item, i) => (
            <ProjectItemRow
              key={i}
              id={item}
              invoice_type={pageData?.invoice_type}
              canRaiseInvoice={canRaiseInvoice}
              showInvoiceStatus
              writerOnly
              handleCheckBoxes={handleCheckBoxes}
              isChecked={state?.ids?.includes(item)}
            />
          )}
          totalCount={meta?.totalCount}
          loadMore={loadMore}
        />
      </Row>
    </div>
  );
};

export default InvoiceListing;
