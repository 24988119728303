import { call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import MSaga from "../../helper/MSaga";

import { openAiActions } from "./openAiReducer";
import { paramsToString, showNotification } from "../../utils/commonFunctions";
import appUrl from "../../config/appUrl";

const dashboardApi = (data = {}) => {
  return axios({
    method: "GET",
    url: `${appUrl.OPENAI}/dashboard`,
  });
};

function* handleDashboard(action) {
  try {
    const response = yield call(dashboardApi, action.payload);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(openAiActions.onGetDashboardFailure(data));
    } else {
      yield put(openAiActions.onGetDashboardSuccess(data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("get open ai dashboard ", error);
    yield put(openAiActions.onGetDashboardFailure(error));
  }
}

const recentlyGeneratedContentApi = (params = {}) => {
  return axios({
    method: "GET",
    url: `${appUrl.OPENAI}/recently-generated-content`,
    params,
  });
};

function* handleRecentlyGeneratedContent(action) {
  delete action.payload?.pathname;
  const filter = paramsToString(action.payload);

  try {
    const response = yield call(recentlyGeneratedContentApi, action.payload);
    const data = response.data;
    data.filter = filter;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(openAiActions.onGetAllRecentlyGeneratedContentFailure(data));
    } else {
      const offset = action.payload?.offset || 0;
      data.isReset = !offset;
      yield put(openAiActions.onGetAllRecentlyGeneratedContentSuccess(data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("get open ai dashboard ", error);
    error.filter = filter;
    yield put(openAiActions.onGetAllRecentlyGeneratedContentFailure(error));
  }
}

const openAiSage = new MSaga({
  name: "openAi",
  apiEndPoint: appUrl.OPENAI_TASK,
  actions: openAiActions,
  messages: {
    create: {},
    updateOne: {
      success: false,
    },
  },
});

function* watchOpenAiSaga() {
  yield fork(openAiSage.getWatchSaga());
  yield takeLatest(openAiActions.onGetDashboardRequest.type, handleDashboard);
  yield takeLatest(
    openAiActions.onGetAllRecentlyGeneratedContentRequest.type,
    handleRecentlyGeneratedContent
  );
}

export default watchOpenAiSaga;
