import React, { useCallback } from "react";
import { Col } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import routes from "../../utils/routes";

const RoleRowItem = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const mRole = useSelector(({ role }) => role.map[id]);

  const onEditRole = useCallback(() => {
    const navigateTo = routes.ROLE_DETAILS.replace(":id", id);
    navigate(navigateTo);
  }, [navigate, id]);

  return (
    <Col className="tr-container pointer" onClick={onEditRole}>
      {mRole.name}
    </Col>
  );
};

export default React.memo(RoleRowItem);
