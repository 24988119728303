import moment from "moment-timezone";

import User from "./User";

const KEYS = Object.freeze({
  ID: "_id",
  NAME: "name",
  THUMBNAIL: "thumbnail",
  TYPE: "type",
  STATUS: "status",
  CREATED_AT: "created_at",
  MODIFIED_AT: "modified_at",
  CREATED_BY: "created_by",
  MODIFIED_By: "modified_by",
});

class Template {
  _id;
  name;
  thumbnail;
  type;
  status;
  createdAt = moment();
  modifiedAt = moment();
  createdBy;
  modifiedBy;

  constructor(name, type, thumbnail) {
    this.name = name;
    this.type = type;
    this.thumbnail = thumbnail;
  }

  fromMap = (map = {}) => {
    if (map[KEYS.ID]) this._id = map[KEYS.ID];
    if (map[KEYS.NAME]) this.name = map[KEYS.NAME];
    if (map[KEYS.THUMBNAIL]) this.thumbnail = map[KEYS.THUMBNAIL];
    if (map[KEYS.TYPE]) this.type = map[KEYS.TYPE];
    if (map[KEYS.STATUS]) this.status = map[KEYS.STATUS];
    if (moment(map[KEYS.CREATED_AT]).isValid())
      this.createdAt = moment(map[KEYS.CREATED_AT]);
    if (moment(map[KEYS.MODIFIED_AT]).isValid())
      this.modifiedAt = moment(map[KEYS.MODIFIED_AT]);

    if (map[KEYS.CREATED_BY]) {
      this.createdBy = new User().fromMap(map[KEYS.CREATED_BY]);
    }

    if (map[KEYS.MODIFIED_By]) {
      this.modifiedBy = new User().fromMap(map[KEYS.MODIFIED_By]);
    }

    return this;
  };
}

export default Template;
