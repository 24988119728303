import React from "react";
import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

import constants from "../utils/constants";

import "./OpenAi.scss";

const { AUTO_GENERATE_CONTENT_TYPES } = constants;

const OpenAi = () => {
  const navigate = useNavigate();

  return (
    <Col className="content-body app-container project-details-container">
      <Row className="page-header fw" justify="space-between" align="middle">
        <Row className="page-heading">Writely Bot</Row>
      </Row>

      <Row className="mt10" align="middle">
        {Object.values(AUTO_GENERATE_CONTENT_TYPES).map((contentType, i) => (
          <Col
            key={`${contentType._id}_${i}`}
            className="mr15 mb15"
            xs={24}
            sm={11}
            md={7}
            lg={5}
            xl={5}
            xxl={4}
            onClick={() => navigate(contentType.url)}
          >
            <Card hoverable>
              <Row className="mb15" align="middle" justify="space-between">
                <Col>{contentType.name}</Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default OpenAi;
