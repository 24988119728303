import React, { memo, useCallback } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CalendarOutlined, MoreOutlined } from "@ant-design/icons";
import "./FreelancerCard.scss";
import User from "../../models/User";
import NameWithAvatar from "../../components/NameWithAvatar";
import { Col, Dropdown, Menu, Row, Tag, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import moment from "moment-timezone";
import { userActions } from "../../users/duck/userReducer";

const FreelancerCard = (props) => {
  const { user_id, handleModal, view, serialNo } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userMap = useSelector(({ user }) => user.map);
  const writer = useMemo(() => userMap?.[user_id], [userMap, user_id]);

  const mUser = useMemo(() => new User().fromMap(writer), [writer]);

  const onFreelancerDetails = useCallback(() => {
    const navigateTo = `${routes.FREELANCER}/${user_id}?tab=overview`;

    navigate(navigateTo);
  }, [navigate, user_id]);

  const status = useMemo(() => {
    let color;
    let text;
    switch (writer?.profile_approved) {
      case "approved":
        color = "green";
        text = "APPROVED";
        break;
      case "rejected":
        color = "red";
        text = "REJECTED";
        break;

      case "submission_pending":
        color = "gold";
        text = "SUBMISSION PENDING";
        break;

      case "assessment_submitted":
        color = "green";
        text = "ASSESSMENT SUBMITTED";
        break;

      default:
        color = "gold";
        text = "PENDING";
        break;
    }
    return (
      <Tag style={{ width: "13rem", textAlign: "center" }} color={color}>
        {text}
      </Tag>
    );
  }, [writer?.profile_approved]);

  const handleProfile = useCallback(
    (id, status) => {
      let updatedData = {
        _id: id,
        profile_approved: status,
      };
      status && dispatch(userActions.onUpdateOneRequest(updatedData));
    },
    [dispatch]
  );

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: "Assign Assessment",
        onClick: () => handleModal(true, user_id),
      },
      {
        key: 2,
        label: "Approve Profile",
        onClick: () => handleProfile(user_id, "approved"),
      },
      {
        key: 3,
        label: "Reject Profile",
        onClick: () => handleProfile(user_id, "rejected"),
      },
    ],
    [handleModal, handleProfile, user_id]
  );

  const onClickMenu = useCallback(
    (e) => {
      e?.domEvent?.stopPropagation();
      const mItem = menuItems.find((item) => item.key === e.key);
      mItem?.onClick?.();
    },
    [menuItems]
  );

  return (
    <>
      {view === "card" ? (
        <div
          className="freelancer-card pri-box-shadow"
          onClick={onFreelancerDetails}
        >
          <Row>
            <Col span={4} style={{ display: "flex", alignItems: "center" }}>
              <NameWithAvatar user={mUser} avatarSize={70} avatarOnly />
            </Col>
            <Col span={1} />
            <Col className=" user-details" span={18}>
              <Col className="name overflow-ellipse">{mUser?.fullName}</Col>
              <Col className="email overflow-ellipse"> {mUser?.email}</Col>
              <Col className="email">
                <CalendarOutlined className="mr5 f15" /> Reg on -&nbsp;
                {moment(writer?.created_at).isValid()
                  ? moment(writer?.created_at).format("DD MMM YYYY")
                  : ""}
              </Col>
              <Col className="profile-status mt20">{status}</Col>
            </Col>
          </Row>
          <Dropdown
            overlay={<Menu items={menuItems} onClick={onClickMenu} />}
            placement="right"
            trigger={["click"]}
          >
            <MoreOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{
                fontSize: 20,
                cursor: "pointer",
                position: "absolute",
                top: 22,
                right: 15,
              }}
            />
          </Dropdown>
        </div>
      ) : (
        <tr className="freelancer-card" onClick={onFreelancerDetails}>
          <td style={{ textAlign: "center" }}>{serialNo}</td>
          <td>
            <Col className="user-details" span={18}>
              <Col className="name overflow-ellipse">{mUser?.fullName}</Col>
              <Col className="email overflow-ellipse"> {mUser?.email}</Col>
            </Col>
          </td>
          <td>
            {mUser?.industry ? (
              <Tooltip
                placement="top"
                title={mUser?.industry?.replaceAll("|", ", ")}
              >
                <p
                  style={{
                    maxWidth: "200px",
                    display: "inline-block",
                  }}
                  className="low-opacity overflow-ellipse"
                >
                  {mUser?.industry?.replaceAll("|", ", ")}
                </p>
              </Tooltip>
            ) : (
              "-"
            )}
          </td>
          <td>{writer?.expertise || "-"}</td>
          <td>
            {" "}
            <CalendarOutlined className="mr5 f15" /> Reg on -&nbsp;
            {moment(writer?.created_at).isValid()
              ? moment(writer?.created_at).format("DD MMM YYYY")
              : ""}
          </td>
          <td>
            <Row align="middle" wrap={false}>
              {status}
              <Dropdown
                overlay={<Menu items={menuItems} onClick={onClickMenu} />}
                placement="right"
                trigger={["click"]}
              >
                <MoreOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                />
              </Dropdown>
            </Row>
          </td>
        </tr>
      )}
    </>
  );
};

export default memo(FreelancerCard);
