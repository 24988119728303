import { NavLink } from "react-router-dom";
import {
  AppstoreOutlined,
  AuditOutlined,
  ProjectOutlined,
  DatabaseOutlined,
  TeamOutlined,
  PaperClipOutlined,
  ContainerOutlined,
  QuestionCircleOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { useMemo } from "react";
import { useUserPermissions } from "../utils/customHooks";

import routes from "../utils/routes";

const getItem = ({ pathname, label, icon, children }) => ({
  key: pathname,
  icon,
  label:
    typeof label === "string" && pathname ? (
      <NavLink to={pathname}>{label}</NavLink>
    ) : (
      label
    ),
  children: children?.map?.(getItem),
});

const useSidebarMenuItems = () => {
  const mPermissions = useUserPermissions();

  return useMemo(() => {
    const menuItems = [];

    const { module } = mPermissions;

    const {
      dashboard,
      onboardingRequest,
      taskList,
      writerInvoice,
      requestorInvoice,
      taskDelayedList,
      users,
      database,
      projectList,
      projectDrafts,
      projectArchived,
      roles,
      assessments,
      template,
      openAi,
      bulkContent,
    } = module;

    if (dashboard.show) {
      menuItems.push({
        pathname: routes.DASHBOARD,
        icon: <AppstoreOutlined />,
        label: "Dashboard",
      });
    }
    if (projectList.show || projectDrafts.show || projectArchived.show) {
      const submenuItems = [];
      if (projectList.show) {
        submenuItems.push({
          pathname: routes.PROJECT,
          label: "Project List",
        });
      }
      if (projectArchived.show) {
        submenuItems.push({
          pathname: routes.PROJECT_ARCHIVED,
          label: "Archived Projects",
        });
      }
      if (projectDrafts.show) {
        submenuItems.push({
          pathname: routes.PROJECT_DRAFT,
          label: "Project Drafts",
        });
      }

      menuItems.push({
        icon: <ProjectOutlined />,
        label: "Project Dashboard",
        children: submenuItems,
      });
    }

    if (database.show) {
      menuItems.push({
        pathname: routes.FREELANCER,
        icon: <DatabaseOutlined />,
        label: "Writer",
      });
    }

    if (users.show) {
      menuItems.push({
        icon: <TeamOutlined />,
        pathname: routes.USERS,
        label: "User List",
      });
    }
    if (taskList?.show || taskDelayedList?.show) {
      const submenuItems = [];
      if (taskList?.show) {
        submenuItems.push({
          pathname: routes.PROJECT_TASK,
          label: "Task List",
        });
      }
      if (taskDelayedList?.show) {
        submenuItems.push({
          pathname: routes.PROJECT_TASK_DELAYED,
          label: "Task Delayed",
        });
      }

      menuItems.push({
        icon: <AuditOutlined />,
        label: "Task",
        children: submenuItems,
      });
    }

    if (requestorInvoice?.show || writerInvoice?.show) {
      const submenuItems = [];

      if (writerInvoice?.show) {
        submenuItems.push({
          pathname: routes.INVOICE_WRITER,
          label: "Writer Invoice",
        });
      }
      if (requestorInvoice?.show) {
        submenuItems.push({
          pathname: routes.INVOICE_REQUESTOR,
          label: "Requestor Invoice",
        });
      }

      menuItems.push({
        icon: <ContainerOutlined />,
        label: "Invoicing",
        children: submenuItems,
      });
    }
    if (assessments.show) {
      menuItems.push({
        pathname: routes.ASSESSMENTS,
        icon: <QuestionCircleOutlined />,
        label: "Assessments",
      });
    }

    if (onboardingRequest.show) {
      menuItems.push({
        icon: <AuditOutlined />,
        pathname: routes.TASK,
        label: "Onboarded Request",
      });
    }

    if (roles.show) {
      menuItems.push({
        pathname: routes.ROLES,
        icon: <TeamOutlined />,
        label: "Role Management",
      });
    }

    if (template?.show) {
      menuItems.push({
        pathname: routes.TEMPLATE,
        icon: <PaperClipOutlined />,
        label: "Template Management",
      });
    }

    if (openAi?.openAiContentGeneration?.show || openAi?.openAiChat?.show) {
      const items = [];

      if (openAi?.openAiContentGeneration?.show) {
        items.push({
          pathname: routes.OPEN_AI,
          label: "Dashboard",
        });

        items.push({
          pathname: routes.OPEN_AI_TEMPLATES,
          label: "Templates",
        });
      }

      if (openAi?.openAiChat?.show) {
        items.push({
          pathname: routes.OPEN_AI_CHAT,
          label: "Writely Chat Bot",
        });
      }

      menuItems.push({
        // pathname: routes.OPEN_AI,
        icon: <RobotOutlined />,
        label: "Writely Bot",
        children: items,
      });
    }

    if (bulkContent?.bulkProjectList.show || bulkContent?.projectReport.show) {
      const submenuItems = [];

      const addSubmenuItem = (condition, route, label) => {
        if (condition) {
          submenuItems.push({
            pathname: route,
            label: label,
          });
        }
      };

      addSubmenuItem(
        bulkContent.bulkProjectList.show,
        routes.BULK_CONTENT_CREATION,
        "Bulk Project List"
      );
      addSubmenuItem(
        bulkContent.projectReport.show,
        routes.GENERATE_REPORTS,
        "Project Report"
      );

      menuItems.push({
        icon: <DatabaseOutlined />,
        label: "Bulk Content",
        children: submenuItems,
      });
    }

    return menuItems.map((item) => getItem(item));
  }, [mPermissions]);
};

export default useSidebarMenuItems;
