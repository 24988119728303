import { createSlice } from "@reduxjs/toolkit";
import { paramsToString } from "../utils/commonFunctions";

class MSlice {
  #reducer;
  #actions;
  #Model;
  #mapKey = "_id";

  constructor({ name = "", initialState, reducers, Model, mapKey = "_id" }) {
    const mInitialState = initialState ?? {
      filters: {},
      map: {},
      boards: {},
      loading: false,
      error: null,
      newCreatedDataId: null,
    };

    if (Model) this.#Model = Model;
    if (mapKey) this.#mapKey = mapKey;

    const mSlice = createSlice({
      name: name,
      initialState: mInitialState,
      reducers: Object.assign(
        {
          // get all objects start
          onGetAllRequest: MSlice.onGetAll,
          onGetAllSuccess: this.#onGetAllSuccess,
          onGetAllFailure: this.#onGetAllFailure,
          // get all objects end

          // create object start
          onCreateOneRequest: this.#onCreate,
          onCreateOneSuccess: this.#onCreateSuccess,
          onCreateOneFailure: this.#onCreateFailure,
          // create object end

          // get one object start
          onGetOneRequest: MSlice.handleLoading,
          onGetOneSuccess: this.#handleSuccess,
          onGetOneFailure: this.#handleError,
          // get one object end

          // update one object start
          onUpdateOneRequest: MSlice.handleLoading,
          onUpdateOneWithoutNotifyRequest: MSlice.handleLoading,
          onUpdateOneSuccess: this.#handleSuccess,
          onUpdateOneFailure: this.#handleError,
          // update one object end

          // delete one object start
          onDeleteOneRequest: MSlice.handleLoading,
          onDeleteOneSuccess: this.#handleDelete,
          onDeleteOneFailure: this.#handleError,
          // delete one object end
        },
        reducers
      ),
    });

    this.#actions = mSlice.actions;
    this.#reducer = mSlice.reducer;
  }

  // handel get all request start
  static onGetAll = (state, action) => {
    const { payload } = action;

    const filtersParams = Object.assign({}, payload);

    const pathname = filtersParams?.pathname;
    delete filtersParams?.pathname;

    const mFilter = paramsToString(filtersParams);

    state.filters = Object.assign(state.filters, {
      [pathname]: mFilter,
    });

    state.boards[mFilter] = Object.assign({}, state.boards[mFilter], {
      loading: true,
      error: null,
    });
  };

  #onGetAllSuccess = (state, action) => {
    const { payload } = action;

    const mFilter = payload?.filter;
    const data = payload?.data ?? [];
    const meta = payload?.meta;

    const mBoard = state.boards?.[mFilter] ?? {};

    let order = payload?.isReset ? [] : mBoard?.list || [];
    order = [...(order || []), ...this.#getArrayObjectOrder(data)];

    state.map = Object.assign(state.map, this.#arrayToMap(data));
    state.boards[mFilter] = Object.assign({}, mBoard, {
      list: [...new Set(order)],
      meta: Object.assign({}, mBoard?.meta, meta),
      loading: false,
    });
  };

  #onGetAllFailure = (state, action) => {
    const mFilter = action.payload.filter;

    state.boards[mFilter] = Object.assign({}, state.boards[mFilter], {
      loading: false,
      error: action.payload,
    });
  };
  // handel get all request end

  // handel create one request start
  #onCreate = (state) => {
    state.newCreatedDataId = null;
    state.error = null;
    state.loading = true;
  };

  #onCreateSuccess = (state, action) => {
    const { payload } = action;
    const data = payload?.data;
    const meta = payload?.meta || {};
    const mFilter = payload?.filter;

    if (payload?.newCreatedDataId) {
      state.newCreatedDataId = payload.newCreatedDataId;
    }

    const mBoard = state.boards?.[mFilter] || {};
    const list = [...new Set([data?._id, ...(mBoard?.list || [])])];

    let totalCount = mBoard?.meta?.totalCount || 0;
    if (!mBoard?.list?.includes?.(data?._id)) totalCount += 1;

    state.map[data?._id] = data;
    state.boards[mFilter] = Object.assign({}, mBoard, {
      list,
      meta: Object.assign({}, mBoard?.meta, { totalCount }, meta),
    });
    state.loading = false;
  };

  #onCreateFailure = (state, action) => {
    state.error = action.payload;
    state.loading = false;
  };
  // handel create one request end

  // handle one object loading start
  static handleLoading = (state, action) => {
    const { payload } = action;

    const _id = payload._id;
    const data = state.map[_id];

    state.map[_id] = Object.assign({}, data, {
      loading: true,
      error: null,
    });
  };
  // handle one object loading end

  // handle one object success start
  #handleSuccess = (state, action) => {
    const payload = action.payload;
    const _id = payload._id;

    state.map[_id] = Object.assign({}, payload, {
      loading: false,
      error: null,
    });
  };
  // handle one object success end

  // handle one object error start
  #handleError = (state, action) => {
    const payload = action.payload;

    const _id = payload._id;
    const data = state.map[_id];

    state.map[_id] = Object.assign({}, data, {
      loading: false,
      error: action.payload,
    });
  };
  // handle one object error end

  // handle one object delete start
  #handleDelete = (state, action) => {
    const payload = action.payload;
    const _id = payload._id;

    state.map[_id] = null;
    Object.values(state?.boards).forEach((board) => {
      if (Array.isArray(board?.list) && board.list.includes(_id)) {
        board.list = board.list.filter((item) => item !== _id);

        let totalCount = (board?.meta?.totalCount ?? 0) - 1;
        totalCount = totalCount >= 0 ? totalCount : 0;
        board.meta = Object.assign({}, board?.meta, { totalCount });
      }
    });
  };
  // handle one object delete end

  #arrayToMap = (objectArray = []) => {
    const map = {};

    objectArray.forEach((item) => {
      let key;
      if (this.#Model) {
        key = new this.#Model().fromMap(item)._id;
      } else {
        key = item?.[this.#mapKey];
      }

      item._id = key;
      map[key] = item;
    });

    return map;
  };

  #getArrayObjectOrder = (array = []) => {
    const order = array?.map?.((item) => {
      if (this.#Model) {
        return new this.#Model().fromMap(item)?._id;
      } else {
        return item?.[this.#mapKey];
      }
    });
    return order ?? [];
  };

  getReducer = () => this.#reducer;

  getActions = () => this.#actions;
}

export default MSlice;
