import User from "./User";
import SvgIcon from "../utils/SvgIcon";
import moment from "moment-timezone";

const KEYS = Object.freeze({
  ID: "_id",
  NAME: "filename",
  LOCATION: "location",
  THUMB_LOCATION: "thumb_location",
  TIMESTAMP: "timestamp",
  SIZE: "size",
  MIMETYPE: "mimetype",
  CREATED_BY: "created_by",
  STATUS: "status",
  URL: "url",
  MODEL_ID: "model_id",
  ON_MODEL: "onModel",
});

class Attachment {
  _id;
  name;
  url;
  location;
  thumbLocation;
  size;
  mimetype;
  modelId;
  onModel;
  status;
  timestamp = moment();
  createdBy = new User();

  toMap = () => {
    const map = {};
    map[KEYS.ID] = this._id;
    map[KEYS.NAME] = this.name;
    map[KEYS.LOCATION] = this.location;
    map[KEYS.THUMB_LOCATION] = this.thumbLocation;
    map[KEYS.TIMESTAMP] = this.timestamp;
    map[KEYS.SIZE] = this.size;
    map[KEYS.MIMETYPE] = this.mimetype;
    map[KEYS.CREATED_BY] = this.createdBy?.toMap?.();
    map[KEYS.STATUS] = this.status;
    map[KEYS.URL] = this.url;
    map[KEYS.MODEL_ID] = this.modelId;
    map[KEYS.ON_MODEL] = this.onModel;

    return map;
  };

  fromMap = (map = {}) => {
    if (map[KEYS.ID]) this._id = map[KEYS.ID];
    if (map[KEYS.NAME]) this.name = map[KEYS.NAME];
    if (map[KEYS.LOCATION]) this.location = map[KEYS.LOCATION];
    if (map[KEYS.THUMB_LOCATION]) this.thumbLocation = map[KEYS.THUMB_LOCATION];
    if (moment(map[KEYS.TIMESTAMP]).isValid())
      this.timestamp = moment(map[KEYS.TIMESTAMP]);
    if (map[KEYS.SIZE]) this.size = map[KEYS.SIZE];
    if (map[KEYS.MIMETYPE]) this.mimetype = map[KEYS.MIMETYPE];
    if (map[KEYS.CREATED_BY]) this.createdBy.fromMap(map[KEYS.CREATED_BY]);
    if (map[KEYS.STATUS]) this.status = map[KEYS.STATUS];
    if (map[KEYS.URL]) this.url = map[KEYS.URL];
    if (map[KEYS.MODEL_ID]) this.modelId = map[KEYS.MODEL_ID];
    if (map[KEYS.ON_MODEL]) this.onModel = map[KEYS.ON_MODEL];

    return this;
  };

  getFileTypeIcon = () => {
    const mimetype = this.mimetype;

    switch (mimetype) {
      case "application/x-rar-compressed":
        return SvgIcon.Rar;
      case "application/xml":
        return SvgIcon.Xml;
      case "audio/mpeg":
        return SvgIcon.Mp3;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return SvgIcon.Docx;
      case "application/msword":
        return SvgIcon.Doc;
      case "application/pdf":
        return SvgIcon.Pdf;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return SvgIcon.Ppt;
      case "text/plain":
        return SvgIcon.Txt;
      case "video/x-m4v":
        return SvgIcon.M4v;
      case "application/zip":
        return SvgIcon.Zip;
      case "image/vnd.adobe.photoshop":
        return SvgIcon.Psd;
      case "audio/x-wav":
        return SvgIcon.Wav;
      case "text/html":
        return SvgIcon.Html;
      case "text/x-java-source":
        return SvgIcon.Java;
      case "image/gif":
        return SvgIcon.Gif;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return SvgIcon.XLSX;
      case "video/x-flv":
        return SvgIcon.Flv;
      case "video/mp4":
      case "application/mp4":
        return SvgIcon.Mp4;
      case "application/vnd.ms-excel":
        return SvgIcon.Xls;
      default:
        return SvgIcon.Blank;
    }
  };
}

export default Attachment;
