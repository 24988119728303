import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import AppTable from "../components/AppTable";
import TaskRowItem from "./components/TaskRowItem";

import { taskActions } from "./duck/taskReducer";
import { paramsToString } from "../utils/commonFunctions";

import "./TaskList.scss";
import Loading from "../components/Loading";
import EmptyContainer from "../components/EmptyContainer";

const TaskList = () => {
  const [state, setState] = useState({ search: "" });
  const [boardKey, setBoardKey] = useState("");

  const dispatch = useDispatch();

  const { list, meta, loading } = useSelector(({ task }) => {
    const boards = task.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const handleChange = (name) => (event) => {
    let value = event?.target?.value ?? event;

    setState((preState) => ({ ...preState, [name]: value }));
  };

  const loadMore = useCallback(
    (offset = 0, limit = 30) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const { onboarding_status, search } = state;
        const params = {
          offset: offset,
          limit: limit,
        };
        if (onboarding_status) params.onboarding_status = onboarding_status;
        if (search) params.search = search;

        setBoardKey(paramsToString(params));
        dispatch(taskActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount, state]
  );

  // const onResetFilter = useCallback(() => {
  //   setState({ search: "" });
  //   const params = {
  //     pathname,
  //     offset: 0,
  //     limit: 30,
  //   };
  //   dispatch(taskActions.onGetAllRequest(params));
  // }, [dispatch, pathname]);

  const onResetFilter = useCallback(() => {
    setState({ search: "" });
    setBoardKey("");
  }, []);

  useEffect(() => {
    if (!list) {
      loadMore();
    }
  }, [loadMore, list]);

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < meta?.totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, meta?.totalCount]
  );

  return (
    <Col className="content-body app-container " onScroll={handleScroll}>
      <Row className="page-header fw" justify="space-between" align="middle">
        <Row className="page-heading">Onboarding Task</Row>

        <Col />
      </Row>

      <Row className=" page-header fw" justify="start" align="middle">
        <Col>
          <Input
            style={{ width: "250px", fontWeight: "500", marginRight: "15px" }}
            placeholder="Search Name or Email"
            prefix={<SearchOutlined />}
            value={state?.search}
            onChange={handleChange("search")}
          />
        </Col>
        <Select
          style={{
            fontWeight: "500",
            marginRight: "15px",
          }}
          placeholder={"Onboarding Status"}
          dropdownMatchSelectWidth={false}
          value={state?.onboarding_status}
          onChange={handleChange("onboarding_status")}
          getPopupContainer={(trigger) => trigger.parentNode}
          allowClear
        >
          <Select.Option value={"approved"}>Approved</Select.Option>
          <Select.Option value={"rejected"}>Rejected</Select.Option>
          <Select.Option value={"pending"}>In Review</Select.Option>
        </Select>
        <Button type="primary" className="mr10" onClick={() => loadMore()}>
          Search
        </Button>
        <Button type="primary-outline" onClick={onResetFilter}>
          Reset
        </Button>
      </Row>
      {!list && loading && <Loading />}

      <Row className="page-body fw" justify="center">
        {!meta?.totalCount && !loading && <EmptyContainer />}
        <Col span={24}>
          {list?.map((item, i) => (
            <TaskRowItem key={i} id={item} />
          ))}
        </Col>
        {list && loading && <Spin />}
        {/* {!loading && (
          <AppTable
            boardKey={boardKey}
            name={"Tasks"}
            data={list}
            renderItem={(item, i) => <TaskRowItem key={item} id={item} />}
            totalItemCount={meta?.totalCount}
            loading={loading}
          />
        )} */}
      </Row>
    </Col>
  );
};

export default TaskList;
