import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Button, Col, Progress, Row, Skeleton, Tooltip } from "antd";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import "./ToDoListContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvatarColor,
  getNameInitials,
  paramsToString,
} from "../../utils/commonFunctions";
import EmptyContainer from "../../components/EmptyContainer";
import moment from "moment-timezone";
import { projectActions } from "../../project/duck/projectReducer";
import User from "../../models/User";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { useUserPermissions } from "../../utils/customHooks";

const ProjectListDashboardContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevProps = useRef();

  const mPermissions = useUserPermissions();
  const [boardKey, setBoardKey] = useState("{}");
  const project = useSelector(({ project }) => project);
  const projectMap = project.map;
  const isProjectLoading = project.loading;

  const onProjectDetails = useCallback(
    (id) => navigate(routes.PROJECT_DETAIL.replace(":id", id)),
    [navigate]
  );

  const { list, meta, loading } = useSelector(({ project }) => {
    const mFilter = boardKey || JSON.stringify({});
    const boards = project.boards;
    const mBoard = boards[mFilter];

    return mBoard || {};
  });
  const loadMore = useCallback(
    (offset = 0, limit = 4) => {
      if (offset === 0 || meta?.totalCount > list?.length) {
        const params = {
          path: "",
          draft: false,
          archive: false,
          offset,
          limit,
        };

        setBoardKey(paramsToString(params));
        dispatch(projectActions.onGetAllRequest(params));
      }
    },
    [dispatch, list?.length, meta?.totalCount]
  );

  useEffect(() => {
    if (prevProps.current?.loading && !project?.loading) {
      if (!project?.error && project?.newCreatedDataId) {
        navigate(
          `${routes.CREATE_PROJECT_FLOW}?draft_id=${project?.newCreatedDataId}`
        );
      }
    }

    return () => {
      prevProps.current = { loading: project?.loading };
    };
  }, [dispatch, navigate, project]);

  useEffect(() => {
    loadMore();
  }, []);

  const onCreateProject = useCallback(() => {
    dispatch(projectActions.onCreateOneRequest());
  }, [dispatch]);

  return (
    <div className="container pri-box-shadow">
      <div className="title-container pri-box-shadow">
        <Row justify="space-between" align="middle">
          <p className="header-text">Recent Added Projects</p>
          {mPermissions?.project?.create && (
            <Button
              size="large"
              shape="circle"
              loading={isProjectLoading}
              icon={<PlusOutlined style={{ fontSize: 16 }} />}
              onClick={onCreateProject}
            />
          )}
        </Row>
      </div>
      <div className="todo-list-container">
        {!meta?.totalCount && !loading && <EmptyContainer />}
        {loading ? (
          <Skeleton active={loading} paragraph={{ rows: 9 }} />
        ) : (
          <>
            {list?.map((id) => {
              const mProject = projectMap?.[id];
              const createdBy = new User().fromMap(mProject?.created_by);
              const { total_items, approved_item } = mProject?.count;
              const percentageCompleted =
                (approved_item / total_items) * 100 || 0;

              return (
                <div
                  className="task pointer"
                  key={id}
                  onClick={() => onProjectDetails(id)}
                >
                  <Tooltip
                    title={mProject?.name?.length > 40 ? mProject?.name : ""}
                  >
                    <p className="overflow-ellipses-tag pri-color">
                      {mProject?.name}
                    </p>
                  </Tooltip>

                  <Row justify="space-between">
                    <Col span={14}>
                      <span className="low-opacity">
                        <CalendarOutlined
                          style={{ color: "#000" }}
                          className="mr10"
                        />{" "}
                        Created on{" "}
                        {moment(mProject?.created_at).isValid()
                          ? moment(mProject?.created_at).format("MMM Do, YYYY")
                          : "-"}
                      </span>
                      <Row align="middle" className="mt15">
                        <Col className="mr10">
                          <Avatar
                            size={45}
                            shape="circle"
                            style={{
                              backgroundColor: getAvatarColor(createdBy?._id),
                            }}
                          >
                            {getNameInitials(createdBy?.fullName)}
                          </Avatar>
                        </Col>

                        <Row className="name-container" align="stretch">
                          <Col>
                            <span className="low-opacity nl"> Created by</span>
                            <p className="nl">{createdBy?.fullName}</p>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    <Col
                      className="pgc"
                      span={10}
                      style={{ marginLeft: "auto" }}
                    >
                      <p>Approved</p>
                      <span className="low-opacity">
                        {approved_item}/{total_items} items
                      </span>
                      <Progress
                        strokeColor={"#2A2A2A"}
                        type="circle"
                        percent={percentageCompleted?.toFixed(0)}
                        width={52}
                        format={(percent) => `${percent}%`}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectListDashboardContainer;
