import React, { useState } from 'react'
import FieldTypeList from './fieldTypeList'
import Section from './sections'
import { Input } from 'antd'
import './formstyle.css'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import {guidGenerator, showNotification} from '../utils/commonFunctions'
import PropertiesModal from './propertiesModal';
import Header from './header'

const fields = [
  {
    id: '5d9dc96ca181d032fac7b461',
    name: 'InputText',
    type: 'text',
    help_text: 'Enter name of your project',
    // options: 'ddd',
    dataType: 'text'
  },
  {
    id: '5d9dc96ca181d032fac7b462',
    name: 'TextArea',
    type: 'textarea',
    help_text: 'Enter area of your project',
    // options: 'ddd',
    dataType: 'textarea'
  },
  {
    id: '5d9dc96ca181d032fac7b463',
    name: 'Number',
    type: 'number',
    help_text: 'Enter number of your project',
    // options: null,
    dataType: 'number'
  },
  {
    id: '5d9dc96ca181d032fac7b464',
    name: 'DropDown',
    type: 'dropdown',
    help_text: 'Enter dropdown of your project',
    options: [],
    dataType: 'dropdown'
  },
  {
    id: '5d9dc96ca181d032fac7b465',
    name: 'Date Field',
    type: 'date',
    help_text: 'Enter date of your project',
    // options: '',
    dataType: 'date'
  },
    {
    id: '5d9dc96ca181d032fac7b480',
    name: 'Url (Link)',
    type: 'url',
    help_text: 'Enter url of your project',
    // options: '',
    dataType: 'url'
  },
  {
    id: '5d9dc96ca181d032fac7b466',
    name: 'File',
    type: 'file',
    help_text: 'Insert your file',
    // options: 'ddd',
    dataType: 'file'
  }
]

class FormBuilder extends React.Component {
    convertFieldsObject = () => {
        if(this.props.template && this.props.template.fields){
            let fields = this.props.template.fields.filter(o=>o.type !== "subheading");
            fields = fields.map(o=>{
                return {
                    ...o,
                    section_id      : o.section_id,
                    alias_name      : o.label,
                    alias_help_text : o.help_text,
                    computed_field  : false,
                    formula         : null,
                    mutable_entry   : false,
                    mandatory       : o.mandatory,
                    read_only       : false,
                    conditions      : false,
                    dependency      : {
                        draw_data   : {},
                        logic       : {}
                    },
                    affects         : [],
                    validation      : null,
                    id: o._id,
                    fid: o._id,
                    dataType: o.type,
                    options: o.options && o.options.split("|")
                }}
            )
            return fields.reduce((a, v) => ({ ...a, [v._id]: v}), {})
        }
    }
    convertSectionObject = () => {
        if(this.props.template && this.props.template.fields){
            // console.log(this.props.template)
            let fields = this.props.template.fields.filter(o => o.type === "subheading");
            let layout = this.props.template.fields.filter(o => o.type !== "subheading").map(o=>{
                return {
                    i:    o._id,
                    x:    0,
                    y:    0,
                    w:    24,
                    minW: 24,
                    maxW: 24,
                    h:    o.type === 'textarea' ? 6 : 4,
                    minH: o.type === 'textarea' ? 6 : 4,
                    maxH: o.type === 'textarea' ? 6 : 4,
                }
            })
            // console.log("------------------------------",layout)
            fields = fields.map(o => {
                    return {
                        section_id: o._id,
                        section_name: o.label,
                        section_description: o.description,
                        section_layout: layout,
                        section_type: 'section'
                    }
                }
            )
            // console.log('---',fields)
            return fields
        }
    }

  state = {
        unsavedProcess     : false,
        redirectToProjects : false,
        redirectToProcess  : false,
        isProject   : false,
        current     : 0,
        loading     : false,
        draftLoading: false,
        activeProcess : null,
        field: {},
        layout: {},
        searchText: "",
        sections   : this.convertSectionObject(),
      allFields   : this.convertFieldsObject(),
      draw_data   : {},
      showPropertiesModal : false,
      propertiesModalPos : {top : 0, left : 0},
      activeField : null,
      template: this.props.template
  }

  setDynamicFields = e => {
    this.setState({field: e})
  }

    updateSections = (data) => {
        this.setState({sections: data, unsavedProcess: true});
    }

    updateFields = (fields) => {
      console.log(fields)
        this.setState({allFields: fields, unsavedProcess: true})
    }

    togglePropertiesModal = (pos, val, field) => {
        if(pos){
            this.setState({showPropertiesModal: val, propertiesModalPos: pos, activeField: field})
        }else{
            this.setState({showPropertiesModal: val, activeField: null})
        }
    }

    addNewSection = (id) => {
        let data = [...this.state.sections]
        let _idx = data.findIndex(s=>s.section_id === id)
        let obj  = {
            section_id          : guidGenerator(),
            section_name        : 'Untitled Section',
            section_description : 'Section Information',
            section_layout      : []
        }
        data.splice(_idx + 1, 0, obj)
        this.updateSections(data)
    }

    copySection = (id) => {
        let data = [...this.state.sections]
        let _sec = data.find(s=>s.section_id === id)
        let _idx = data.findIndex(s=>s.section_id === id)
        let obj  = {
            section_id          : guidGenerator(),
            section_name        : 'Copy of '+_sec.section_name,
            section_description : _sec.section_description,
            section_layout      : _sec.section_layout
        }
        data.splice(_idx + 1, 0, obj)
        this.updateSections(data)
    }

    sectionName = (val, id) => {
        let data = [...this.state.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_name = val;
        this.updateSections(data)
    }

    sectionDescription = (val, id) => {
        let data = [...this.state.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_description = val;
        this.updateSections(data)
    }

    onLayoutChange = (id, layout) => {
        let data = JSON.parse(JSON.stringify(this.state.sections))
        // let data = [...this.props.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_layout = layout;
        this.updateSections(data)
    }

    publishProject = () => {
        this.setState({loading:true})
        let _this               = this;
        let activeProject       = Object.assign({}, this.props.template);
        activeProject.fields    = this.state.allFields
        activeProject.sections  = this.state.sections
        console.log('Project template maker data ----->',activeProject)
        setTimeout(function(){
            _this.setState({loading:false, redirectToProjects: true})
            showNotification('success','Form saved successfully');
        },1000)
    }

  render() {
    let {allFields, sections, showPropertiesModal, template} = this.state;

    return (<DndProvider backend={HTML5Backend}>
            <Header
                name            = {template.name}
                description     = {template.description}
                onPublish       = {this.publishProject}
                // saveAsDraft     = {this.saveAsDraft}
                // onChangeOfStep  = {this.onChangeOfStep}
                // current         = {current}
                loader          = {this.state.loading}
                // draftLoading    = {this.state.draftLoading}
                // isProject       = {isProject}
                projectId       = {template._id}
            />
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(248, 250, 251)'}}>

              <div style={{ width: '300px', marginTop: 10}}>
                <FieldTypeList fields={fields}/>
            </div>


            <div style={{ width: '100%', height: 'calc(100vh - 106px)', overflow: 'auto', marginTop: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
                <div style={{ background: '#fff', width: '80%', borderRadius: '5px', padding: '20px', boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1), 0px 0 32px rgba(169, 194, 209, 0.15)' }}>
                  {
                      this.state.sections && this.state.sections.map(section => {
                      return (
                          <Section
                              key                 = {section.section_id}
                              section             = {section}
                              addNewSection       = {this.addNewSection}
                              deleteSection       = {this.deleteSection}
                              sectionName         = {this.sectionName}
                              sectionDescription  = {this.sectionDescription}
                              copySection         = {this.copySection}
                              togglePropertiesModal= {this.togglePropertiesModal}
                              onLayoutChange      = {this.onLayoutChange}
                              allFields           = {allFields}
                              updateFields        = {this.updateFields}
                              isProject           = {true}
                              sections            = {sections}
                              // steps               = {this.props.steps}
                              // updateSteps         = {this.props.updateSteps}
                              updateSections      = {this.updateSections}
                          />
                      )
                    })
                  }
                    </div>
                    </div>
                    </div>
                    </div>
            {
                showPropertiesModal &&
                <PropertiesModal
                    allFields               = {allFields}
                    updateFields            = {this.updateFields}
                    position                = {this.state.propertiesModalPos}
                    togglePropertiesModal   = {this.togglePropertiesModal}
                    activeField             = {this.state.activeField}
                />
            }
                    </DndProvider>
                    )
                    }
}


export default FormBuilder
