import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Input, Row, Select } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import { ReloadOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import GenerateContentContainer from "./components/GenerateContentContainer";
import MRadioGroup from "../components/MRadioGroup";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import Loading from "../components/Loading";

import { useQuery } from "../utils/customHooks";
import { openAiActions } from "./duck/openAiReducer";

import {
  arrayToMap,
  hasApiCallError,
  showNotification,
} from "../utils/commonFunctions";
import appUrl from "../config/appUrl";
import routes from "../utils/routes";
import constants from "../utils/constants";

import "./OpenAi.scss";

const {
  CONTENT_VOICE_TONES,
  CONTENT_PERSPECTIVES,
  AUTO_GENERATE_CONTENT_TYPES,
  OPEN_AI_TASK_FIELDS,
} = constants;

const DEFAULT_STEP = 1;
const MAX_STEPS = 1;

const GenerateQuoraQnA = () => {
  const prevProps = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const openAi = useSelector(({ openAi }) => openAi);
  const mTask = useMemo(() => {
    const taskId = query.get("task_id");
    return openAi.map[taskId] || {};
  }, [query, openAi.map]);

  const [state, setState] = useState({
    currentStep: DEFAULT_STEP,
    tone: CONTENT_VOICE_TONES[3],
    contentPerspective: CONTENT_PERSPECTIVES[0],
  });
  const [error, setError] = useState({});
  const [responses, setResponses] = useState({});
  const [responseAnsers, setResponseAnsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const hasError = useCallback(() => {
    let { currentStep, question, tone, contentPerspective } = state;
    question = question?.trim?.();
    tone = tone?.trim?.();
    contentPerspective = contentPerspective?.trim?.();

    const error = {};

    if (currentStep === 1) {
      if (!question) {
        error.question = "Please enter question";
      }

      if (!tone) {
        error.tone = "Please select Tone of the content";
      }

      if (!contentPerspective) {
        error.contentPerspective = "Please select perspective of content";
      }
    }

    setError(error);
    return Object.keys(error).length > 0;
  }, [state]);

  const generateQuoraAnswerApi = useCallback(
    (temperature) => {
      if (temperature || !hasError()) {
        setLoading(true);

        let { currentStep, question, tone, contentPerspective, keywords } =
          state;
        question = question?.trim?.();
        tone = tone?.trim?.();
        contentPerspective = contentPerspective?.trim?.();
        keywords = keywords?.trim?.();

        let text = `Give 5 answer to ${question} with a ${tone} tone and preferred perspective of the answer in the ${contentPerspective}.`;
        if (keywords)
          text = `${text} Use word like ${keywords} while giving answers.`;
        // text = `${text} The answer length should be 100 to 150 words.`;
        const payload = { text };
        if (temperature) payload.temperature = 0.5;
        if (mTask?._id) payload.task_id = mTask?._id;

        axios({
          method: "POST",
          url: `${appUrl.OPENAI}/default`,
          data: payload,
        })
          .then((response) => {
            const resData = response.data;

            if (!hasApiCallError(resData?.meta)) {
              const choiceText = resData?.data?.content;
              let answers = choiceText?.split?.("\n") || [];
              if (Array.isArray(answers) && answers.length > 0) {
                answers = answers.filter((answer) => answer?.trim?.());
                answers = answers.map((answer, i) => {
                  answer =
                    answer
                      ?.match?.(/(?:"[^"]*"|^[^"]*$)/)?.[0]
                      ?.replace?.(/"/g, "") || answer;

                  if (answer.startsWith(`${i + 1}.`)) {
                    answer = answer?.replace(`${i + 1}.`, "");
                  }

                  return answer?.trim?.();
                });

                answers = answers.filter((answer) => answer?.trim?.());
                setResponseAnsers(answers);
              }

              setResponses((prevResponses) =>
                Object.assign({}, prevResponses, { [currentStep]: resData })
              );
              setState((prevState) => ({
                ...prevState,
                // finalDraft: choiceText,
                currentStep: temperature
                  ? prevState.currentStep
                  : prevState.currentStep + 1,
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log("Error", err);
            showNotification("error", "Something went wrong");
            setLoading(false);
          });
      }
    },
    [state, mTask, hasError]
  );

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const onSubmit = useCallback(() => {
    setState((prevState) => ({ ...prevState, finalDraft: "" }));
    generateQuoraAnswerApi();
  }, [generateQuoraAnswerApi]);

  const onSelect = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;
      let finalDraft = state.finalDraft || "";

      if (!finalDraft.replace(/<(.|\n)*?>/g, "").trim()) finalDraft = "";
      if (finalDraft) finalDraft = `${finalDraft}<br>`;
      finalDraft = `${finalDraft}<p>${value}</p>`;

      setState((prevState) => ({ ...prevState, finalDraft }));
      setError({});
    },
    [state.finalDraft]
  );

  const onGoBack = useCallback(() => {
    if (state.currentStep <= 1) {
      navigate(-1);
      return;
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }, [state.currentStep, navigate]);

  const onRegenerate = useCallback(() => {
    const temperature = Number(Math.random().toFixed(2));

    if (state.currentStep === 2) {
      generateQuoraAnswerApi(temperature);
    }
  }, [state.currentStep, generateQuoraAnswerApi]);

  useEffect(() => {
    if (
      prevProps.current?.loading &&
      !openAi?.loading &&
      state.isTaskCreating
    ) {
      const searchParams = {
        task_id: openAi?.newCreatedDataId,
      };

      const navigateTo = {
        search: `?${createSearchParams(searchParams)}`,
      };
      navigate(navigateTo, { replace: true });
      setState((prevState) => ({ ...prevState, isTaskCreating: false }));
    } else if (
      prevProps.current?.loading &&
      !mTask?.loading &&
      state.isTaskSaving
    ) {
      if (!mTask?.error) {
        const navigateTo = routes.OPEN_AI_DASHBOARD;
        navigate(navigateTo, { replace: true });
      }
    }

    return () => {
      prevProps.current = {
        loading: openAi?.loading || mTask?.loading,
        isTaskCreating: state.isTaskCreating,
        isTaskSaving: state.isTaskSaving,
      };
    };
  }, [
    openAi?.newCreatedDataId,
    openAi?.loading,
    state?.isTaskCreating,
    state?.isTaskSaving,
    mTask?.loading,
    mTask?.error,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    const taskId = query.get("task_id");
    if (!taskId) {
      setState((prevState) => ({ ...prevState, isTaskCreating: true }));
      const payload = {
        fields: [...OPEN_AI_TASK_FIELDS],
      };
      payload.fields.forEach((field) => {
        if (field._id === "content_type") {
          field.value = AUTO_GENERATE_CONTENT_TYPES.QUORA_QNA.name;
        }
      });
      dispatch(openAiActions.onCreateOneRequest(payload));
    } else {
      dispatch(openAiActions.onGetOneRequest({ _id: taskId }));
    }
  }, [query, dispatch]);

  const handleShowOpenAiTaskModal = useCallback((show) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
    }));
  }, []);

  const onSave = useCallback(
    (fields = []) => {
      const { _id } = mTask;

      const payload = {
        _id: _id,
        name: "",
        fields: [],
        draft: false,
      };

      const fieldMap = Object.assign(
        {},
        arrayToMap(mTask?.fields),
        arrayToMap(fields)
      );
      payload.fields = mTask?.fields?.map((field) => {
        const newField = Object.assign({}, field, fieldMap[field?._id]);
        if (newField?._id === "content_type") {
          newField.value = AUTO_GENERATE_CONTENT_TYPES.QUORA_QNA.name;
        } else if (newField?._id === "task_name") {
          payload.name = newField.value;
        } else if (newField?._id === "content") {
          newField.value = state.finalDraft;
        }

        return newField;
      });

      setState((prevState) => ({
        ...prevState,
        showOpenAiTaskModal: false,
        isTaskSaving: true,
      }));
      dispatch(openAiActions.onUpdateOneRequest(payload));
    },
    [mTask, state, dispatch]
  );

  if (
    (!openAi.newCreatedDataId && openAi.loading) ||
    state.isTaskCreating ||
    (!mTask?._id && mTask?.loading)
  ) {
    return <Loading />;
  }

  return (
    <GenerateContentContainer containerClassName="set-container">
      <Row className="content-container">
        {state.currentStep === 1 && (
          <Row className="fw" justify="space-between">
            <Col className="--left-container" span={24}>
              <Col className="title-container mb15">
                <Row className="d-flex-open-ai" align="middle">
                  <div className="back-icon mr-3">
                    <LeftOutlined className="text-2xl" onClick={onGoBack} />
                  </div>
                  <span>Generate your quora answer</span>
                  {/* <Button
                    type="primary-outline"
                    size="large"
                    onClick={onGoBack}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </Button> */}
                </Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Let us know your question</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Input.TextArea
                  className="input input-area"
                  placeholder="Let us know your question"
                  value={state.question}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={handleChange("question")}
                  size="large"
                  disabled={loading}
                />

                <Row className="error mt5">{error.question}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">Select tone of the content</label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Select tone of the content"
                  value={state.tone}
                  onChange={handleChange("tone")}
                  size="large"
                  disabled={loading}
                >
                  {CONTENT_VOICE_TONES.map((tone, i) => (
                    <Select.Option key={i} title={tone} value={tone} />
                  ))}
                </Select>

                <Row className="error mt5">{error.tone}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Select your preferred perspective of content
                  </label>
                  <sup style={{ color: "red" }}>*</sup>
                </Col>

                <Select
                  className="custom-select fw"
                  placeholder="Select your preferred perspective of content"
                  value={state.contentPerspective}
                  onChange={handleChange("contentPerspective")}
                  disabled={loading}
                >
                  {CONTENT_PERSPECTIVES.map((contentPerspective, i) => (
                    <Select.Option
                      key={i}
                      title={contentPerspective}
                      value={contentPerspective}
                    />
                  ))}
                </Select>

                <Row className="error mt5">{error.contentPerspective}</Row>
              </Col>

              <Col className="mb15">
                <Col className="mb5">
                  <label className="label">
                    Add words to include in the answer
                  </label>
                </Col>

                <Input
                  className="input input-area"
                  placeholder="Add words to include in the answer here"
                  value={state.keywords}
                  onChange={handleChange("keywords")}
                  size="large"
                  disabled={loading}
                />
              </Col>

              <Row className="mt-4" align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                  <Button
                    className="fw mt15"
                    type="primary"
                    loading={loading}
                    onClick={onSubmit}
                    size="large"
                  >
                    Create Answer
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* <Col
              className="--right-container mb-24"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              <ReactQuill
                className="input-text-editor h-96 sticky top-0"
                value={state.finalDraft}
                onChange={handleChange("finalDraft")}
                theme="snow"
              />
            </Col> */}
          </Row>
        )}

        {state.currentStep === 2 && (
          <Row className="fw" justify="space-between">
            <Col
              className="--left-container"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              {/* quora answer start */}
              <Col className="mb-24">
                <Col className="title-container mb15">
                  <Row className="d-flex-open-ai" align="middle">
                    <div className="back-icon mr-3">
                      <LeftOutlined className="text-2xl" onClick={onGoBack} />
                    </div>
                    <span>Choose your quora answer</span>

                    {/* <Button
                      type="primary-outline"
                      size="large"
                      onClick={onGoBack}
                    >
                      <ArrowLeftOutlined /> Go Back
                    </Button> */}
                  </Row>
                </Col>

                <Col className="mb15">
                  <MRadioGroup values={responseAnsers} onChange={onSelect()} />
                </Col>

                <Col className="mt15">
                  <Button
                    className="fw"
                    type="primary"
                    disabled={loading}
                    onClick={() => generateQuoraAnswerApi(0.5)}
                    size="large"
                  >
                    <Row align="middle" justify="center">
                      <ReloadOutlined className="mr-2.5" spin={loading} />
                      Regenerate
                    </Row>
                  </Button>
                </Col>
              </Col>
              {/* quora answer end */}
            </Col>

            <Col
              className="--right-container"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={11}
              xxl={11}
            >
              <Col className="sticky top-0">
                <Col className="h-96 mb-16">
                  <ReactQuill
                    className="input-text-editor h-full"
                    value={state.finalDraft}
                    onChange={handleChange("finalDraft")}
                    theme="snow"
                  />
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Row
                    className="w-full"
                    align="middle"
                    justify="space-between"
                  >
                    {mTask?._id && (
                      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                        <Button
                          className="w-full"
                          type="success"
                          size="large"
                          loading={mTask?.loading}
                          onClick={() => handleShowOpenAiTaskModal(true)}
                        >
                          {mTask?.loading ? "Saving" : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Col>
            </Col>
          </Row>
        )}
      </Row>

      <OpenAiTaskModal
        visible={state.showOpenAiTaskModal}
        taskId={mTask?._id}
        fields={mTask?.fields?.filter((field) => field?._id === "task_name")}
        onSubmit={onSave}
        handleModal={handleShowOpenAiTaskModal}
      />
    </GenerateContentContainer>
  );
};

export default GenerateQuoraQnA;
