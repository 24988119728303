import axios from "axios";

class ApiHelper {
  #apiEndPoint;

  constructor(apiEndPoint) {
    this.#apiEndPoint = apiEndPoint;
  }

  createOneApi = (data) => {
    return axios({
      method: "POST",
      url: this.#apiEndPoint,
      data: data,
    });
  };

  getAllApi = (params = {}) => {
    return axios({
      method: "GET",
      url: this.#apiEndPoint,
      params: params,
    });
  };

  getOneApi = (params = {}) => {
    return axios({
      method: "GET",
      url: `${this.#apiEndPoint}/${params?._id}`,
    });
  };

  updateOneApi = (data = {}) => {
    return axios({
      method: "PUT",
      url: `${this.#apiEndPoint}/${data?._id}`,
      data: data,
    });
  };

  deleteOneApi = (data = {}) => {
    return axios({
      method: "DELETE",
      url: `${this.#apiEndPoint}/${data?._id}`,
    });
  };
}

export default ApiHelper;
