import { Skeleton } from "antd";
import React, { useCallback } from "react";
import { Checkbox } from "antd";
import EmptyContainer from "./EmptyContainer";
import "./MyTable.scss";

const MyTable = (props) => {
  const {
    columns = [],
    loading,
    list = [],
    totalCount,
    loadMore = () => {},
    renderItem = (item) => item,
    handleClick,
    isChecked,
    showCheckbox = false,
  } = props;

  const handleScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        if (!loading && list?.length < totalCount) {
          loadMore(list?.length);
        }
      }
    },
    [list?.length, loadMore, loading, totalCount]
  );

  return (
    <div className="table-container" onScroll={handleScroll}>
      <table className="mb20">
        <thead className="pri-box-shadow">
          <tr>
            {/* <th className="uppercase">
              <Checkbox
                style={{
                  position: "absolute",
                  left: 5,
                  top: 29,
                }}
              />
              Select All
            </th> */}
            {columns?.map((col, i) => (
              <th className="uppercase" key={i} style={col?.style}>
                {}
                {showCheckbox && i === 0 ? (
                  <Checkbox
                    style={{
                      position: "absolute",
                      left: 10,
                      top: 35,
                    }}
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleClick}
                    checked={isChecked}
                  />
                ) : (
                  ""
                )}
                {col?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {list?.map(renderItem)}

          {loading &&
            [...new Array(5)].map((_, i) => (
              <tr key={i}>
                {columns?.map((col, i) => (
                  <td key={i} style={col?.style}>
                    <Skeleton
                      active={loading}
                      title={false}
                      paragraph={{ rows: 1 }}
                      size="large"
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {totalCount === 0 && !loading && <EmptyContainer />}
    </div>
  );
};

export default MyTable;
