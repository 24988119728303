import React, { useCallback, useMemo } from "react";
import { Badge, Checkbox, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import routes from "../../utils/routes";
import CalendarIcon from "../../assets/images/Calendar.svg";

import User from "../../models/User";
import moment from "moment-timezone";
import { statusTransformer } from "../../components/StatusTransformer";
import NameWithAvatar from "../../components/NameWithAvatar";

const ProjectItemRow = (props) => {
  const {
    id,
    isChecked,
    showInvoiceStatus = false,
    canRaiseInvoice = false,
    writerOnly = false,
    showWriter = false,
    showDueDate = false,
    invoice_type = "",
    handleCheckBoxes = () => {},
  } = props;

  const navigate = useNavigate();

  const itemObj = useSelector(({ item }) => item.map[id]);

  const fields = useMemo(() => itemObj.fields, [itemObj.fields]);

  const project = useMemo(() => itemObj?.project_id, [itemObj?.project_id]);

  const onTaskDetails = useCallback(() => {
    const navigateTo = routes.PROJECT_TASK_DETAIL.replace(":id", id).replace(
      ":projectId",
      project?._id
    );
    console.log(navigateTo, "navigateTo");
    window.open(navigateTo, "_blank");
    //navigate(navigateTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, id, project]);

  const assignedFreelancer = useMemo(() => {
    return new User().fromMap(itemObj?.assigned_freelancer);
  }, [itemObj?.assigned_freelancer]);

  const currentAssignee = useMemo(() => {
    if (itemObj?.current_assignee) {
      return new User().fromMap(itemObj?.current_assignee);
    } else {
      return new User().fromMap(itemObj?.project_id?.assigned_manager);
    }
  }, [itemObj?.current_assignee, itemObj?.project_id?.assigned_manager]);

  const createdBy = useMemo(
    () => new User().fromMap(itemObj?.created_by),
    [itemObj?.created_by]
  );
  const { invoice_number, invoice_url } = useMemo(() => {
    const invoiceObj = itemObj?.invoice?.filter(
      (obj) => obj?.invoice_type === invoice_type
    )?.[0];

    const { invoice_number, attachment } = invoiceObj || {};
    return { invoice_number, invoice_url: attachment?.url };
  }, [invoice_type, itemObj?.invoice]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleCheckBoxes(e?.target?.checked, id);
    },
    [handleCheckBoxes, id]
  );

  if (!itemObj?._id) {
    return <></>;
  }

  return (
    <tr>
      <td style={{ position: "relative" }}>
        {showInvoiceStatus && (
          <>
            {invoice_number && (
              <Badge.Ribbon
                text={invoice_number}
                placement="start"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: -30,
                  left: -30,
                }}
                color="#2A2A2A"
              />
            )}
            {canRaiseInvoice && !invoice_number && (
              <Checkbox
                onClick={handleClick}
                checked={isChecked}
                disabled={invoice_number}
                style={{
                  position: "absolute",
                  left: 10,
                  top: "auto",
                }}
              />
            )}
          </>
        )}
        <Tooltip
          title={fields?.[0].value?.length > 30 ? fields?.[0].value : ""}
        >
          <p
            style={{
              maxWidth: "200px",
              display: "inline-block",
            }}
            className="low-opacity overflow-ellipse"
          >
            {fields?.[0].value}
          </p>
          <span
            className="low-opacity"
            style={{ fontSize: 14, color: "#000", opacity: 0.6 }}
          >
            #{itemObj?.task_id}
          </span>

          <span className="low-opacity">
            <img
              width={11}
              className="mr5 mb5"
              src={CalendarIcon}
              alt="calendar icon"
            />
            Created on{" "}
            {moment(itemObj?.created_at).isValid()
              ? moment(itemObj?.created_at).format("MMM Do, YYYY")
              : "-"}
          </span>
          <span className="low-opacity">
            <img
              width={11}
              className="mr5 mb5"
              src={CalendarIcon}
              alt="calendar icon"
            />
            Assigned on{" "}
            {moment(itemObj?.assigned_date).isValid()
              ? moment(itemObj?.assigned_date).format("MMM Do, YYYY")
              : "-"}
          </span>
        </Tooltip>

        <div className="link-cb">
          <i className="pointer" onClick={onTaskDetails}>
            {" "}
            View Details{" "}
          </i>
        </div>
      </td>

      <td>
        <Tooltip title={project?.name?.length > 25 ? project?.name : ""}>
          <p
            style={{
              maxWidth: "180px",
            }}
            className="low-opacity inline-block overflow-ellipse"
          >
            {project?.name}
          </p>
        </Tooltip>
      </td>
      <td>
        <p
          style={{
            maxWidth: "200px",
            display: "inline-block",
          }}
          className=" overflow-ellipse"
        >
          <NameWithAvatar user={createdBy} />
        </p>
      </td>
      {!writerOnly && (
        <td>
          <p
            style={{
              maxWidth: "200px",
              display: "inline-block",
            }}
            className=" overflow-ellipse"
          >
            <NameWithAvatar user={currentAssignee} />
          </p>
        </td>
      )}
      {(writerOnly || showWriter) && (
        <td>
          <p
            style={{
              maxWidth: "200px",
              display: "inline-block",
            }}
            className=" overflow-ellipse"
          >
            <NameWithAvatar user={assignedFreelancer} />
          </p>
        </td>
      )}
      <td>
        {showInvoiceStatus ? (
          <>
            <Tag
              style={{ textAlign: "center", width: "9rem" }}
              color={invoice_url ? "#F3AF4B" : "#00AF82"}
            >
              {invoice_url ? "Invoiced" : "To Be Invoiced"}
            </Tag>
            {invoice_url && (
              <Tooltip title="View Invoice">
                <EyeOutlined
                  style={{ fontSize: 14 }}
                  className="pointer ml10"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(invoice_url, "_blank");
                  }}
                />
              </Tooltip>
            )}
          </>
        ) : showDueDate ? (
          moment(itemObj?.due_date).format("DD-MM-YY")
        ) : (
          statusTransformer(itemObj?.status_description)
        )}
      </td>
    </tr>
  );
};

export default React.memo(ProjectItemRow);
