import { Avatar, Col, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getNameInitials, getAvatarColor } from "../../utils/commonFunctions";
import "./ChatItem.scss";
import constants from "../../utils/constants";

const { URL_REGEX } = constants;
const ChatItem = (props) => {
    const { message = {} } = props;

    const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser.data);

    const { createdBy, body, canCopy, loading } = useMemo(() => {
        const createdBy = {};
        let canCopy = false;

        if (message?.role === "user") {
            createdBy._id = loggedInUser?._id;
            createdBy.name = `${loggedInUser?.first_name} ${
                loggedInUser?.last_name || ""
            }`.trim();
        } else if (message?.role === "assistant" || message?.role === "system") {
            canCopy = true;
            createdBy._id = `writely-bot`;
            createdBy.name = `Writely Bot`;
        }

        return {
            createdBy,
            body: message?.content,
            canCopy,
            loading: message?.loading,
        };
    }, [message, loggedInUser]);

    const avatarColor = useMemo(
        () => getAvatarColor(createdBy?._id),
        [createdBy?._id]
    );

    const nameInitials = useMemo(
        () => getNameInitials(createdBy?.name),
        [createdBy]
    );

    const renderText = useCallback(
        (txt) =>
            txt?.split(" ").map((part, i) => {
                let text = part;
                text = URL_REGEX.test(part) ? (
                    <a href={part} target="_blank" key={part} rel="noreferrer">
                        {`${part} `}
                    </a>
                ) : (
                    `${part} `
                );

                return text;
            }),
        []
    );

  return (
    <Col className="chat-item">
      <Row className="chat-item-container">
        <Col>
          <Avatar
            size={40}
            shape={"circle"}
            style={{ backgroundColor: avatarColor }}
          >
            {nameInitials}
          </Avatar>
        </Col>

        <Col className="chat-details-container">
          <Row className="user-details" align="middle">
            <span className="name">
              {createdBy.name}
            </span>
          </Row>
          <Row>
          </Row>

          <Col className="chat-details">
            <Col className="message mt5">{renderText(body)}</Col>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ChatItem);
