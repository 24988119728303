import MSlice from "../../helper/MSlice";

const progressSlice = new MSlice({
    name: "progress",
    reducers: {
        onRatingsRequest: MSlice.handleLoading,
    }
});

export const progressActions = progressSlice.getActions();

export default progressSlice.getReducer();
