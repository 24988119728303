import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import classNames from "classnames";
import "./FileProcessing.scss";

const FileProcessing = () => {
  const [isVisible, setIsVisible] = useState(true);

  // Define the indicators for different steps
  const steps = [
    { id: 1, description: "File successfully uploaded", status: "success" }, // blue background
    { id: 2, description: "File processing initiated", status: "initiating" }, // gray background
    { id: 3, description: "Data validation failed", status: "failure" }, // red background
    { id: 4, description: "File processing failed", status: "failure" }, // red background
  ];

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <Modal
        className="custom-modal modal-container"
        title="File Processing in Progress..."
        open={false}
        onCancel={onClose}
        footer={[
          <Button key="close" onClick={onClose}>
            Close
          </Button>,
        ]}
      >
        <div className="timer-icon">
          <span className="time-text">2:04 min</span>
        </div>
        <div>
          <ul className="custom-list">
            {steps.map((step) => (
              <li
                key={step.id}
                className={classNames("indicator-container", {
                  "blue-bg": step.status === "success",
                  "red-bg": step.status === "failure",
                  "gray-bg": step.status === "initiating",
                })}
              >
                {step.status === "success" && (
                  <CheckOutlined className="right-checked" />
                )}
                {step.status === "failure" && (
                  <CloseOutlined className="cross-checked" />
                )}
                {step.status === "initiating" && (
                  <span className="hidden-icon" />
                )}
                <span className="description-text">{step.description}</span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default FileProcessing;
