import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Home from "../home/Home";
import NotFound from "../components/NotFound";
import AppRoutes from "./AppRoutes";
// import CreateProject from "../project/CreateProject";

import routes from "../utils/routes";
import LoginContainer from "../login/LoginContainer";
import ForgotPassword from "../login/ForgotPassword";
import VerifyUser from "../login/VerifyUser";
import ResetPassword from "../login/ResetPassword";
import FreelancerSignup from "../login/FreelancerSignup";
import ClientSignup from "../login/ClientSignup";
import AssessmentTest from "../assessmentTest/AssessmentTest";
import Test from "../assessmentTest/Test";

const MRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.ROOT} element={<Home />} />
        <Route path={routes.LOGIN} element={<LoginContainer />} />
        <Route path={routes.CLIENT_LOGIN} element={<LoginContainer />} />
        <Route path={routes.FREELANCER_LOGIN} element={<LoginContainer />} />
        <Route path={routes.CLIENT_SIGNUP} element={<ClientSignup />} />
        <Route path={routes.FREELANCER_SIGNUP} element={<FreelancerSignup />} />
        <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={routes.EMAIL_VERIFY} element={<VerifyUser />} />
        <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />

        <Route
          path={routes.ASSESSMENT_TEST}
          element={<PrivateRoute component={AssessmentTest} />}
        />
        <Route path={routes.TEST} element={<PrivateRoute component={Test} />} />

        {/* <Route
          path={routes.CREATE_PROJECT_FLOW}
          element={<PrivateRoute component={CreateProject} />}
        /> */}
        <Route
          path={`${routes.APP}/*`}
          element={<PrivateRoute component={AppRoutes} />}
        />

        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MRoutes;
