import React from "react";
import { Provider } from "react-redux";
import MRoutes from "./routes/MRoutes";
import store from "./config/store";
import "./assets/scss/App.scss";

const App = () => {
  return (
    <Provider store={store}>
      <MRoutes />
    </Provider>
  );
};

export default App;
