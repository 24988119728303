import {
  put,
  takeLatest,
  takeLeading,
  takeEvery,
  call,
} from "redux-saga/effects";

import { paramsToString, showNotification } from "../utils/commonFunctions";
import ApiHelper from "./ApiHelper";

class MSaga {
  #name;
  #mApiHelper;
  #mActions;
  #mWorkers = {};
  #mMessages = {
    create: {
      success: "",
      error: "",
    },
    getAll: {
      success: "",
      error: "",
    },
    getOne: {
      success: "",
      error: "",
    },
    updateOne: {
      success: "",
      error: "",
    },
    deleteOne: {
      success: "",
      error: "",
    },
  };

  constructor({
    name = "MSaga",
    apiEndPoint = {},
    actions,
    messages = {},
    workers = {},
  }) {
    this.#name = name;
    this.#mApiHelper = new ApiHelper(apiEndPoint);
    this.#mActions = actions;
    this.mMessages = Object.assign(this.#mMessages, messages);
    this.#mWorkers = Object.assign(
      {
        handleCreateOne: this.#handleCreateOne(),
        handleGetOne: this.#handleGetOne(),
        handleGetAll: this.#handleGetAll(),
        handleUpdateOne: this.#handleUpdateOne(),
        handleUpdateOneWithoutNotify: this.#handleUpdateOne(false),
        handleDeleteOne: this.#handleDeleteOne(),
      },
      workers
    );
  }

  // handle create one start
  #handleCreateOne() {
    const { onCreateOneSuccess, onCreateOneFailure } = this.#mActions;
    const { createOneApi } = this.#mApiHelper;
    const { create } = this.#mMessages;

    return function* (action) {
      try {
        const mFilter = action?.payload?.filter;
        delete action?.payload?.filter;

        const response = yield call(createOneApi, action.payload);
        const { data } = response;

        const meta = data?.meta;
        if (meta?.success !== true || meta?.status !== 200) {
          showNotification("error", meta?.message);
          yield put(onCreateOneFailure(data));
        } else {
          create.success && showNotification("success", create.success);
          data.filter = mFilter || JSON.stringify({});
          data.newCreatedDataId = data.data._id;
          yield put(onCreateOneSuccess(data));
        }
      } catch (error) {
        showNotification("error", "Something went wrong");
        console.log("MSaga Create One", error);
        yield put(onCreateOneFailure(error));
      }
    };
  }
  // handle create one end

  // handle get all start
  #handleGetAll() {
    const { onGetAllSuccess, onGetAllFailure } = this.#mActions;
    const { getAllApi } = this.#mApiHelper;
    const { getAll } = this.#mMessages;

    return function* (action) {
      delete action.payload?.pathname;
      const filter = paramsToString(action.payload);

      try {
        const response = yield call(getAllApi, action.payload);
        const { data } = response;
        data.filter = filter;

        const meta = data?.meta;
        if (meta?.success !== true || meta?.status !== 200) {
          getAll.error && showNotification("error", getAll.error);
          yield put(onGetAllFailure(data));
        } else {
          getAll.success && showNotification("success", getAll.success);

          const offset = action.payload?.offset || 0;
          data.isReset = offset < 1;
          yield put(onGetAllSuccess(data));
        }
      } catch (error) {
        showNotification("error", "Something went wrong");
        console.log("MSaga Get All", error);
        error.filter = filter;
        yield put(onGetAllFailure(error));
      }
    };
  }
  // handle get all end

  // handle get one start
  #handleGetOne() {
    const { onGetOneSuccess, onGetOneFailure } = this.#mActions;
    const { getOneApi } = this.#mApiHelper;
    const { getOne } = this.#mMessages;

    return function* (action) {
      try {
        const response = yield call(getOneApi, action.payload);
        const { data } = response;
        data._id = action.payload?._id;

        const meta = data?.meta;
        if (meta?.success !== true || meta?.status !== 200) {
          getOne.error && showNotification("error", getOne.error);
          yield put(onGetOneFailure(data));
        } else {
          getOne.success && showNotification("success", getOne.success);
          data.data._id = action.payload?._id;
          yield put(onGetOneSuccess(data?.data));
        }
      } catch (error) {
        showNotification("error", "Something went wrong");
        console.log("MSaga Get One", error);

        error._id = action.payload?._id;
        yield put(onGetOneFailure(error));
      }
    };
  }
  // handle get one end

  // handle update one start
  #handleUpdateOne(notify = true) {
    const { onUpdateOneSuccess, onUpdateOneFailure } = this.#mActions;
    const { updateOneApi } = this.#mApiHelper;
    const updateOneMessage = this.#mMessages.updateOne;

    return function* (action) {
      try {
        const response = yield call(updateOneApi, action.payload);
        const { data } = response;
        data._id = action.payload?._id;

        const meta = data?.meta;
        let message = meta?.message;
        if (meta?.success !== true || meta?.status !== 200) {
          message = updateOneMessage.error || message;
          showNotification("error", message);
          yield put(onUpdateOneFailure(data));
        } else {
          message = updateOneMessage.success ?? message;

          // Logic for whether to show notification or not
          if (!Object.keys(action.payload)?.includes("consent")) {
            notify && message && showNotification("success", message);
          }

          data.data._id = action.payload?._id;
          yield put(onUpdateOneSuccess(data?.data));
        }
      } catch (error) {
        showNotification("error", "Something went wrong");
        console.log("MSaga Update One", error);

        error._id = action.payload?._id;
        yield put(onUpdateOneFailure(error));
      }
    };
  }
  // handle update one end

  // handle get one start
  #handleDeleteOne() {
    const { onDeleteOneSuccess, onDeleteOneFailure } = this.#mActions;
    const { deleteOneApi } = this.#mApiHelper;
    const deleteOneMessage = this.#mMessages.deleteOne;

    return function* (action) {
      try {
        const response = yield call(deleteOneApi, action.payload);
        const { data } = response;
        data._id = action.payload?._id;

        const meta = data?.meta;
        let message = meta?.message;
        if (meta?.success !== true || meta?.status !== 200) {
          message = deleteOneMessage.error ?? meta?.message;
          message && showNotification("error", message);
          yield put(onDeleteOneFailure(data));
        } else {
          message = deleteOneMessage.success ?? meta?.message;
          message && showNotification("success", message);
          data.data._id = action.payload?._id;
          yield put(onDeleteOneSuccess(data));
        }
      } catch (error) {
        showNotification("error", "Something went wrong");
        console.log("MSaga delete One", error);

        error._id = action.payload?._id;
        yield put(onDeleteOneFailure(error));
      }
    };
  }
  // handle get one end

  getWatchSaga() {
    const {
      onCreateOneRequest,
      onGetAllRequest,
      onGetOneRequest,
      onUpdateOneRequest,
      onUpdateOneWithoutNotifyRequest,
      onDeleteOneRequest,
    } = this.#mActions;

    const {
      handleCreateOne,
      handleGetOne,
      handleGetAll,
      handleUpdateOne,
      handleUpdateOneWithoutNotify,
      handleDeleteOne,
    } = this.#mWorkers;

    return function* () {
      yield takeLeading(onCreateOneRequest.type, handleCreateOne);
      yield takeEvery(onGetAllRequest.type, handleGetAll);
      yield takeEvery(onGetOneRequest.type, handleGetOne);
      yield takeLatest(onUpdateOneRequest.type, handleUpdateOne);
      yield takeLatest(
        onUpdateOneWithoutNotifyRequest.type,
        handleUpdateOneWithoutNotify
      );
      yield takeLatest(onDeleteOneRequest.type, handleDeleteOne);
    };
  }
}

export default MSaga;
