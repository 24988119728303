import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import { Col, Row, Button, Upload, Mentions, Avatar } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import paperClipSVG from "../assets/images/PaperClip.svg";
import "./CommentBox.scss";
import { useDispatch, useSelector } from "react-redux";
import { commentActions } from "../comment/duck/commentReducer";
import UploadListPreview from "./UploadListPreview";
import User from "../models/User";
import { getAvatarColor, getNameInitials } from "../utils/commonFunctions";

const CommentBox = (props) => {
  const dispatch = useDispatch();
  const comment = useSelector(({ comment }) => comment);
  const prevProps = useRef();
  const itemId = useParams()?.id;

  const loggedInUserId = useSelector(
    ({ loggedInUser }) => loggedInUser.data?._id
  );
  const itemObj = useSelector(({ item }) => item.map[itemId]);
  const avatarColor = useCallback((id) => getAvatarColor(id), []);

  const createdBy = useMemo(
    () => new User().fromMap(itemObj?.created_by),
    [itemObj?.created_by]
  );

  const assignedFreelancer = useMemo(
    () => new User().fromMap(itemObj?.assigned_freelancer),
    [itemObj?.assigned_freelancer]
  );
  const assignedManager = useMemo(
    () => new User().fromMap(itemObj?.project_id?.assigned_manager),
    [itemObj?.project_id?.assigned_manager]
  );

  const assignedEditor = useMemo(
    () => new User().fromMap(itemObj?.project_id?.assigned_editor),
    [itemObj?.project_id?.assigned_editor]
  );

  const mentionUserIds = useMemo(() => {
    const userIds = [
      ...new Set([
        createdBy?._id,
        assignedFreelancer?._id,
        assignedManager?._id,
        assignedEditor?._id,
      ]),
    ]?.filter((userId) => userId && userId !== loggedInUserId);

    return userIds;
  }, [
    assignedEditor,
    assignedFreelancer,
    assignedManager,
    createdBy,
    loggedInUserId,
  ]);

  const usersObj = useMemo(
    () => ({
      [createdBy?._id]: createdBy,
      [assignedFreelancer?._id]: assignedFreelancer,
      [assignedManager?._id]: assignedManager,
      [assignedEditor?._id]: assignedEditor,
    }),
    [assignedEditor, assignedFreelancer, assignedManager, createdBy]
  );

  const [message, setMessage] = useState("");
  const [sendMessage, setSendMessage] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [mentions, setMentions] = useState([]);

  useEffect(() => {
    if (prevProps.current?.loading && !comment?.loading && sendMessage) {
      setMessage("");
      setFileList([]);
      setMentions([]);
      setSendMessage(false);
    }

    return () => {
      prevProps.current = { loading: comment?.loading, sendMessage };
    };
  }, [comment?.loading, sendMessage]);

  const handleChange = useCallback((value) => {
    setMentions((preState) => {
      if (preState?.length) {
        return preState?.filter((user) => value?.includes(user?.value));
      } else {
        return preState;
      }
    });

    setMessage(value);
  }, []);

  const onSelect = useCallback(
    (option) => {
      const isMentioned = mentions?.find((user) => user?.key === option?.key);
      if (!isMentioned) {
        setMentions((preState) => [...preState, option]);
      }
    },
    [mentions]
  );

  // const handleChange = useCallback(
  //   (event) => setMessage(event.target.value),
  //   []
  // );

  // const beforeUpload = useCallback((file) => {
  //   const supportedFileType = [
  //     "image/png",
  //     "image/jpg",
  //     "image/jpeg",
  //     "application/msword",
  //     "application/pdf",
  //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //   ];
  //   let isAcceptable = supportedFileType.includes(file?.type);
  //   if (!isAcceptable)
  //     showNotification(
  //       "error",
  //       "Please upload JEPG, JGP, PNG, PDF or Word file only"
  //     );

  //   return isAcceptable || Upload.LIST_IGNORE;
  // }, []);

  const onSend = useCallback(() => {
    setSendMessage(true);
    const data = new FormData();
    if (message.trim()) {
      let messageBody = message.trim();
      if (mentions?.length) {
        mentions.forEach((user) => {
          messageBody = messageBody?.replaceAll(user?.value, user?.key);
        });
      }
      data.append("body", messageBody);
    }
    if (fileList?.length) data.append("file", fileList[0]);
    if (mentions?.length) {
      const mentionedUserIds = mentions?.map((user) => user?.key);
      data.append("mentions", mentionedUserIds);
    }
    data.append("model_id", props?.modelId);
    data.append("onModel", props?.onModel);
    data.filter = props?.filter;

    dispatch(commentActions.onCreateOneRequest(data));
  }, [
    message,
    fileList,
    mentions,
    props?.modelId,
    props?.onModel,
    props?.filter,
    dispatch,
  ]);

  return (
    <Col className="comment-box">
      {fileList?.length > 0 && (
        <UploadListPreview fileList={fileList} setFileList={setFileList} />
      )}
      <Row className="comment-container" align="middle">
        <Col className="input-container" align="stretch">
          <Mentions
            className="custom-mention"
            placeholder={"Type @ to mention"}
            autoSize={{
              minRows: 1,
              maxRows: 3,
            }}
            style={{
              position: "relative",
              top: "50%",
            }}
            onPressEnter={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                onSend();
              }
            }}
            onChange={handleChange}
            onSelect={onSelect}
            value={message}
            placement="top"
            disabled={comment?.loading || props?.disabled}
          >
            {mentionUserIds?.map((id) => {
              const user = usersObj?.[id];
              const name = usersObj?.[id]?.firstName?.split(" ")?.[0];
              return (
                <Mentions.Option key={id} value={name}>
                  {/* {user?.fullName} */}
                  <Row align="middle">
                    <Col className="mr10">
                      <Avatar
                        size={35}
                        shape="circle"
                        style={{ backgroundColor: avatarColor(id) }}
                      >
                        {getNameInitials(user?.fullName)}
                      </Avatar>
                    </Col>

                    <Row className="name-container" align="stretch">
                      <Col>
                        <Col className="overflow-ellipse capitalize pri-bold">
                          {user?.fullName}
                        </Col>
                        <Col>{user?.email}</Col>
                      </Col>
                    </Row>
                  </Row>
                </Mentions.Option>
              );
            })}
          </Mentions>
          {/* <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 3 }}
            bordered={false}
            placeholder={"Enter message"}
            value={message}
            onChange={handleChange}
            disabled={comment?.loading || props?.disabled}
            size="large"
          /> */}
        </Col>
        <Row className="action-btn-container mr10" align="middle">
          <Col className="attachment-btn-container ">
            <Upload
              className="mr5"
              // accept={".jpeg, .jpg, .png, .pdf, .docx, .doc"}
              accept={"*"}
              multiple={false}
              listType={"text"}
              showUploadList={false}
              fileList={fileList}
              // beforeUpload={beforeUpload}
              customRequest={({ file }) => setFileList([file])}
              disabled={comment?.loading || props?.disabled}
            >
              <img
                src={paperClipSVG}
                alt="upload icon"
                style={{ cursor: "pointer" }}
              />
            </Upload>
          </Col>

          <Button
            type="text"
            size="large"
            icon={<SendOutlined style={{ fontSize: 23 }} />}
            onClick={onSend}
            disabled={!fileList.length && !message.trim()}
            loading={comment?.loading}
          />
        </Row>
      </Row>
    </Col>
  );
};

export default React.memo(CommentBox);
