import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import OpenAiTaskCard from "./components/OpenAiTaskCard";
import OpenAiTaskModal from "./components/OpenAiTaskModal";
import EmptyContainer from "../components/EmptyContainer";

import { useDebounce } from "../utils/customHooks";
import { openAiActions } from "./duck/openAiReducer";

import { paramsToString } from "../utils/commonFunctions";
import routes from "../utils/routes";

const OpenAiTaskList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debounce = useDebounce();

  const [state, setState] = useState({});

  const [boardKey, setBoardKey] = useState("{}");
  const { list, meta, loading } = useSelector(({ openAi }) => {
    const boards = openAi.boards;
    const mBoard = boards[boardKey];

    return mBoard || {};
  });

  const loadMore = useCallback(
    (offset = 0, limit = 20) => {
      if (!loading && (offset === 0 || meta?.totalCount > list?.length)) {
        const params = {
          offset: offset,
          limit: limit,
          draft: false,
        };
        if (state.search) params.search = state.search;

        setBoardKey(paramsToString(params));
        dispatch(openAiActions.onGetAllRequest(params));
      }
    },
    [dispatch, list, meta, loading, state.search]
  );

  useEffect(() => {
    if (state.search) {
      debounce(loadMore);
    } else {
      loadMore();
    }
  }, [state.search]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value;

      setState((preState) => ({ ...preState, [name]: value }));
    },
    []
  );

  const handleShowOpenAiTaskModal = useCallback((show, taskId) => {
    show = typeof show === "boolean" && show;
    setState((prevState) => ({
      ...prevState,
      showOpenAiTaskModal: show,
      taskId,
    }));
  }, []);

  const handleOnScroll = useCallback(
    ({ target }) => {
      if (
        Math.ceil(target.scrollTop) + window.innerHeight >=
        target.scrollHeight
      ) {
        loadMore(list?.length);
      }
    },
    [list?.length, loadMore]
  );

  const onCreateProject = useCallback(
    () => navigate(routes.OPEN_AI_TEMPLATES),
    [navigate]
  );

  return (
    <Col
      className="content-body open-ai open-ai-dashboard"
      onScroll={handleOnScroll}
    >
      <Row className="mb-5" align="middle" justify="space-between">
        <Col className="mb-2" xs={24} sm={24} md={14} lg={16} xl={12} xxl={8}>
          <Input
            className="fw"
            size="large"
            prefix={<SearchOutlined />}
            placeholder="Search Here..."
            value={state.search}
            onChange={handleChange("search")}
          />
        </Col>

        <Col className="mb-2" xs={24} sm={24} md={8} lg={6} xl={4} xxl={3}>
          <Button
            className="fw"
            type="primary"
            size="large"
            onClick={onCreateProject}
          >
            + Create Project
          </Button>
        </Col>
      </Row>

      {Array.isArray(list) && list.length > 0 && (
        <>
          <Row className="tasks-container" gutter={[20, 20]}>
            {list?.map?.((taskId, i) => (
              <OpenAiTaskCard
                key={i}
                _id={taskId}
                onShowDetails={handleShowOpenAiTaskModal}
              />
            ))}

            {loading &&
              [...new Array(5)]?.map?.((_, i) => (
                <OpenAiTaskCard key={i} loading />
              ))}
          </Row>

          {/* open ai tasks model start */}
          <OpenAiTaskModal
            visible={state.showOpenAiTaskModal}
            taskId={state.taskId}
            handleModal={handleShowOpenAiTaskModal}
          />
          {/* open ai tasks model end */}
        </>
      )}

      {!list?.length > 0 && <EmptyContainer description="No Project Found" />}
    </Col>
  );
};

export default React.memo(OpenAiTaskList);
