import React from "react";
import { Col, Row, Switch } from "antd";

const SubsectionItem = ({ section = {}, handleChange, disabled }) => {
  const onChange = (name) => (value) => {
    if (name === "show" && !value) {
      Object.keys(section).forEach((s) => {
        if (typeof section[s] === "boolean") {
          section[s] = false;
        }
      });
    }

    handleChange && handleChange({ ...section, [name]: value });
  };

  return (
    <tr>
      <td>
        <Row className="text-left pl15">{section?.name}</Row>
      </td>

      <td>
        <Row align="middle">
          <Col className="text-right" span={9}>
            Hide
          </Col>

          <Col className="text-center" span={6}>
            <Switch
              disabled={disabled}
              onChange={onChange("show")}
              checked={section.show}
            />
          </Col>

          <Col className="text-left" span={9}>
            Show
          </Col>
        </Row>
      </td>

      <td>
        <Row align="middle">
          <Col className="text-right" span={9}></Col>

          <Col className="text-center" span={6}>
            <Switch
              checked={section?.editable}
              onChange={onChange("editable")}
              disabled={!section.show || disabled}
            />
          </Col>

          <Col className="text-left" span={9}>
            Edit
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default SubsectionItem;
