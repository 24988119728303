import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userActions } from "../../users/duck/userReducer";

import NotFound from "../../components/NotFound";
import Loading from "../../components/Loading";

import MemberDashboard from "../../dashboard/MemberDashboard";

const FreelancerDetails = () => {
  const assigned_freelancer = useParams()?.id;
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user.map?.[assigned_freelancer]);

  useEffect(() => {
    if (assigned_freelancer && !user)
      dispatch(userActions.onGetOneRequest({ _id: assigned_freelancer }));
  }, [assigned_freelancer, dispatch, user]);

  if (!user?._id && user?.loading) {
    return <Loading />;
  }
  // check if freelancer exist
  else if (!user?._id) {
    return <NotFound subTitle={"Sorry, User does not exist."} />;
  }

  return (
    <MemberDashboard assigned_freelancer={assigned_freelancer} showBackButton />
  );
};

export default FreelancerDetails;
