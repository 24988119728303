import { Avatar, Col, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Comment from "../../models/Comment";
import { getNameInitials, getAvatarColor } from "../../utils/commonFunctions";
import "./ChatItem.scss";
import FileItem from "./FileItem";

const ChatItem = (props) => {
    const { id } = props;

    const commentMap = useSelector(({ comment }) => comment.map);
    // const user = useSelector(({ loggedInUser }) => loggedInUser.data);

    const { _id, createdBy, createdAt, body, attachments, modelId, meta } =
        useMemo(() => {
            const mComment = new Comment();
            mComment.fromMap(commentMap[id]);

            return mComment;
        }, [commentMap, id]);

    const replaceWith = useCallback(
        (text) => {
            const userObj = meta?.mentions?.find((user) =>
                text?.includes?.(user?._id)
            );
            if (userObj) {
                const newText = text?.replace(userObj?._id, userObj?.first_name);
                return (
                    <span className="font-black text-cyan-600" key={userObj?._id}>
            {newText}{" "}
          </span>
                );
            } else {
                return text;
            }
        },
        [meta?.mentions]
    );

    const avatarColor = useMemo(
        () => getAvatarColor(createdBy?._id),
        [createdBy?._id]
    );

    const nameInitials = useMemo(() => {
        const fullName = `${createdBy?.firstName} ${createdBy.lastName}`;
        return getNameInitials(fullName);
    }, [createdBy]);

    const URL_REGEX = useMemo(
        () =>
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        []
    );

    const renderText = useCallback(
        (txt) =>
            txt.split(" ").map((part, i) => {
                let text = part;
                text = URL_REGEX.test(part) ? (
                    <a href={part} target="_blank" key={part} rel="noreferrer">
                        {`${part} `}
                    </a>
                ) : (
                    `${part} `
                );

                text = text?.includes?.("@") ? replaceWith(text) : text;

                return text;
            }),
        [URL_REGEX, replaceWith]
    );
    if (!_id) {
        return <></>;
    }

    return (
        <Col className="chat-item">
            <Row className="chat-item-container">
                <Col>
                    <Avatar
                        size={40}
                        shape={"circle"}
                        style={{ backgroundColor: avatarColor }}
                    >
                        {nameInitials}
                    </Avatar>
                </Col>

                <Col className="chat-details-container">
                    <Row className="user-details" align="middle">
            <span className="name">
              {createdBy.firstName} {createdBy.lastName}
            </span>
                    </Row>
                    <Row>
                        <span className="timestamp">{createdAt.fromNow()}</span>
                    </Row>

                    <Col className="chat-details">
                        <Col className="message mt5">{renderText(body)}</Col>

                        <Col>
                            <Row className="file-list mb10">
                                {attachments.map((attachment, i) => (
                                    <FileItem key={i} file={attachment?.toMap()} />
                                ))}
                            </Row>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </Col>
    );
};

export default React.memo(ChatItem);
