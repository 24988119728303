import { Button, Tag } from "antd";
import moment from "moment-timezone";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";

const AssessmenTestRow = (props) => {
  const { id, serialNo, canGiveTest } = props;
  const navigate = useNavigate();
  const testObj = useSelector(({ assessmentTest }) => assessmentTest.map?.[id]);

  const {
    status_type,
    test_name,
    timestamp,
    created_at,
    modified_at,
    marks_obtained,
    total_marks,
  } = testObj ?? {};

  const isTestAttempted = useMemo(
    () => typeof marks_obtained === "number",
    [marks_obtained]
  );

  const handleClick = useCallback(() => {
    navigate(routes.TEST.replace(":id", id));
  }, [id, navigate]);

  const testStatus = useMemo(() => {
    const statusObj = {
      color: "#EA605A",
      name: "Incomplete",
    };
    if (isTestAttempted) {
      statusObj.color = status_type === "started" ? "#EA605A" : "#234f1e";
      statusObj.name = status_type === "started" ? "Incomplete" : "Submitted";
    } else if (status_type === "pending") {
      statusObj.color = "#F3AF4B";
      statusObj.name = status_type;
    }
    return statusObj;
  }, [isTestAttempted, status_type]);

  return (
    <tr>
      <td> {serialNo}</td>
      <td>{test_name}</td>
      <td>{moment(timestamp || created_at).format("DD MMM YYYY h:mm A")}</td>
      <td>
        {isTestAttempted
          ? moment(timestamp || modified_at).format("DD MMM YYYY h:mm A")
          : "-"}
      </td>
      <td style={{ textAlign: "center" }}>
        {isTestAttempted ? `${marks_obtained}/${total_marks}` : "-"}
      </td>
      <td>
        <Tag
          color={testStatus?.color}
          style={{ width: "9rem", textAlign: "center" }}
          className="uppercase"
        >
          {testStatus?.name}
        </Tag>
      </td>
      {canGiveTest && (
        <td>
          {status_type === "pending" && !isTestAttempted ? (
            <Button
              className="new_button"
              size="large"
              type="primary"
              onClick={handleClick}
            >
              Start Test
            </Button>
          ) : (
            "-"
          )}
        </td>
      )}
    </tr>
  );
};

export default AssessmenTestRow;
