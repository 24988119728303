import React, { useCallback } from "react";
import { Button, Result, Row } from "antd";
import { useNavigate } from "react-router-dom";

import routes from "../utils/routes";

const NotFound = (props) => {
  const navigate = useNavigate();

  const {
    status = 404,
    subTitle = "Sorry, the page you visited does not exist.",
    extra,
    showHomeBtn = false,
  } = props;

  const onGoHome = useCallback(() => {
    navigate(routes.ROOT);
  }, [navigate]);

  return (
    <Row className="fh" justify="center" align="middle">
      <Result
        status={status}
        title={status}
        subTitle={subTitle}
        extra={
          extra ??
          (showHomeBtn && (
            <Button type="primary" size="large" onClick={onGoHome}>
              Go Home
            </Button>
          ))
        }
      />
    </Row>
  );
};

export default React.memo(NotFound);
