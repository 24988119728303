import { Tooltip } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import routes from "../../utils/routes";
import moment from "moment-timezone";

const ProjectDraftRow = (props) => {
  const { id, serialNo } = props;
  const navigate = useNavigate();
  const mProject = useSelector(({ project }) => project.map[id]);
  const mTemplate = useMemo(() => mProject?.template, [mProject?.template]);

  const onClick = useCallback(() => {
    navigate(`${routes.CREATE_PROJECT_FLOW}?draft_id=${id}`);
  }, [navigate, id]);

  return (
    <tr>
      <td style={{ textAlign: "center" }}>{serialNo}</td>
      <td>{mProject?.name || mTemplate?.name}</td>
      <td>{mTemplate?.type || "-"}</td>
      <td>
        {moment(mProject?.created_at).isValid()
          ? moment(mProject?.created_at).format("Do MMM  YY")
          : "-"}
      </td>
      <td style={{ textAlign: "center" }}>
        <Tooltip title={"Complete"}>
          <EditOutlined style={{ fontSize: 12 }} onClick={onClick} />
        </Tooltip>
      </td>
    </tr>
  );
};
export default React.memo(ProjectDraftRow);
