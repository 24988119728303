import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Result, Row } from "antd";

import "./MRadioGroup.scss";

const MRadioBlogOutline = ({
  values = [],
  value = "",
  onChange = (value) => value,
  onChangeValues = (values = []) => values,
  showSelectBtn = true,
  canTextEdit = false,
  ...props
}) => {
  const [activeEditItem, setActiveEditItem] = useState(null);

  const onChangeValueItem = useCallback(
    (i = 0) =>
      (e) => {
        let newValue = e?.target?.value ?? e;

        if (props.value && props.value === values[i]) {
          onChange(newValue);
        }

        let newValues = [...values];
        newValues[i] = newValue;
        onChangeValues(newValues);
      },
    [props.value, values, onChangeValues, onChange]
  );

  const onEdit = useCallback(
    (i) => {
      setActiveEditItem((prevItem) => prevItem !== i && i);
      onChange("");
    },
    [onChange]
  );

  const onDelete = useCallback(
    (i) => {
      const newValues = values.filter((_, itemIndex) => itemIndex !== i);

      setActiveEditItem(false);
      onChangeValues(newValues);
    },
    [values, onChangeValues]
  );

  const handleSelect = useCallback(
    (selectedValue) => {
      onChange(selectedValue);
    },
    [onChange]
  );

  {
    /*useEffect(() => {
    if (values.length) {
      onChange(values);
    }
  }, []);*/
  }
  console.log("values", values);
  return (
    <Col className="m-radio-group-container">
      {values?.length ? (
        values.map((v, i) => {
          const canEdit = activeEditItem === i;

          return (
            <Col key={i} className="m-radio-group-item pb-3 border-b">
              <Col className="item-value  px-4">
                {canEdit ? (
                  <Col>
                    <Input
                      className="input input-area"
                      value={v}
                      onChange={onChangeValueItem(i)}
                      size="large"
                    />
                  </Col>
                ) : (
                  v
                )}
              </Col>

              {(typeof activeEditItem !== "number" || i === activeEditItem) && (
                <Col>
                  <Row align="middle">
                    {canTextEdit && (
                      <>
                        {v && (
                          <Button
                            className="mr-2"
                            type={canEdit ? "primary" : "default"}
                            onClick={() => onEdit(i)}
                          >
                            {canEdit ? "Done" : "Edit"}
                          </Button>
                        )}

                        <Button className="mr-2" onClick={() => onDelete(i)}>
                          Remove
                        </Button>
                      </>
                    )}
                  </Row>
                </Col>
              )}
            </Col>
          );
        })
      ) : (
        <Result status={404} subTitle={"Sorry, no result found."} />
      )}

      {showSelectBtn && (
        <Button
          className="mr-2"
          type={value !== "" ? "primary" : "default"}
          onClick={() => handleSelect(values)}
        >
          Select
        </Button>
      )}
    </Col>
  );
};

export default React.memo(MRadioBlogOutline);
