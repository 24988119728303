import MSlice from "../../helper/MSlice";
import {
  arrayToMap,
  getArrayObjectOrder,
  paramsToString,
} from "../../utils/commonFunctions";

const openAiSlice = new MSlice({
  name: "openAi",
  reducers: {
    // open ai dashboard start
    onGetDashboardRequest: (state, action) => {
      state.dashboard = Object.assign({}, state.dashboard, {
        loading: true,
        error: null,
      });
    },
    onGetDashboardSuccess: (state, action) => {
      const { payload } = action;
      const data = payload?.data;

      state.dashboard = Object.assign({}, state.dashboard, {
        data: data,
        loading: false,
      });
    },
    onGetDashboardFailure: (state, action) => {
      const { payload } = action;

      state.dashboard = Object.assign({}, state.dashboard, {
        error: payload,
        loading: false,
      });
    },
    // open ai dashboard end

    // open ai get all recently generated content start
    onGetAllRecentlyGeneratedContentRequest: (state, action) => {
      const { payload } = action;

      const filtersParams = Object.assign({}, payload);

      const pathname = filtersParams?.pathname;
      delete filtersParams?.pathname;

      const mFilter = paramsToString(filtersParams);

      state.filters = Object.assign(state.filters, {
        [pathname]: mFilter,
      });

      state.boards[mFilter] = Object.assign({}, state.boards[mFilter], {
        loading: true,
        error: null,
      });
    },
    onGetAllRecentlyGeneratedContentSuccess: (state, action) => {
      const { payload } = action;

      const mFilter = payload?.filter;
      const data = payload?.data ?? [];
      const meta = payload?.meta;

      const mBoard = state.boards?.[mFilter] ?? {};

      let order = payload?.isReset ? [] : mBoard?.list || [];
      order = [...(order || []), ...getArrayObjectOrder(data)];

      state.recentContentMap = Object.assign(
        {},
        state.recentContentMap,
        arrayToMap(data)
      );
      state.boards[mFilter] = Object.assign({}, mBoard, {
        list: [...new Set(order)],
        meta: Object.assign({}, mBoard?.meta, meta),
        loading: false,
      });
    },
    onGetAllRecentlyGeneratedContentFailure: (state, action) => {
      const mFilter = action.payload.filter;

      state.boards[mFilter] = Object.assign({}, state.boards[mFilter], {
        loading: false,
        error: action.payload,
      });
    },
    // open ai get all recently generated content end
  },
});

export const openAiActions = openAiSlice.getActions();

export default openAiSlice.getReducer();
