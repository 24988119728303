import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, Button, Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";

import CustomModal from "../../components/CustomModal";

import { userActions } from "../../users/duck/userReducer";
import { projectActions } from "../duck/projectReducer";

import User from "../../models/User";

import constants from "../../utils/constants";
import {
  getAvatarColor,
  getNameInitials,
  paramsToString,
} from "../../utils/commonFunctions";

import "./SelectManagerModal.scss";
const { ROLE_IDS } = constants;

const SelectClientModal = (props) => {
  const { visible, projectId, isProjectEditable } = props;

  const prevProps = useRef();
  const dispatch = useDispatch();

  const [boardKey, setBoardKey] = useState("");
  const [state, setState] = useState({
    assignedClient: null,
  });
  const [error, setError] = useState({});

  const projectObj = useSelector(({ project }) => project.map[projectId]);

  const { users, meta, loading } = useSelector(({ user }) => {
    const userMap = user.map;

    const boards = user.boards;
    const mBoard = boards[boardKey];

    const list = mBoard?.list || [];
    const meta = mBoard?.meta;
    const loading = mBoard?.loading;

    let users = list.map((userId) => userMap[userId]);
    users = users.filter((user) => user);

    return { users, meta, loading };
  });

  const loadMore = useCallback(
    (offset = 0, limit = 10) => {
      if (loading) return;
      if (offset === 0 || meta?.totalCount > users?.length) {
        const params = {
          role_id: [
            ROLE_IDS.CLIENT,
            ROLE_IDS.ADMIN,
            ROLE_IDS.SUPER_ADMIN,
            ROLE_IDS.EDITOR,
            ROLE_IDS.MANAGER,
          ],
          offset,
          limit,
        };

        setBoardKey(paramsToString(params));
        dispatch(userActions.onGetAllRequest(params));
      }
    },
    [dispatch, users?.length, meta?.totalCount, loading]
  );

  useEffect(() => {
    loadMore(users?.length, meta?.totalCount);
  }, [users?.length, meta?.totalCount]);

  useEffect(() => {
    if (visible) {
      if (!prevProps.current?.visible && visible) {
        const assignedClient = projectObj?.assigned_client;

        setState({
          assignedClient: assignedClient?._id || assignedClient,
        });
      } else if (prevProps.current?.loading && !projectObj?.loading) {
        if (!projectObj?.error) {
          props.handleModal();
        }
      }
    } else {
      setState({});
    }

    return () => {
      prevProps.current = {
        visible: props.visible,
        loading: projectObj?.loading,
      };
    };
  }, [visible, projectObj, props]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e?.target?.value ?? e;

      setState((prevState) => ({ ...prevState, [name]: value }));
      setError({});
    },
    []
  );

  const onFilterOption = useCallback((input, option) => {
    const user = option.props.user;
    const fullName = user.fullName.toLowerCase();
    const email = user.email.toLowerCase();
    const mInput = input.toLowerCase();

    return fullName.indexOf(mInput) >= 0 || email.indexOf(mInput) >= 0;
  }, []);

  // const hasError = useCallback(() => {
  //   const error = {};

  //   if (!state.assignedClient) {
  //     error.assignedClient = "Please select client";
  //   }

  //   setError(error);

  //   return !!Object.keys(error).length;
  // }, [state]);

  const onSubmit = useCallback(() => {
    // if (!hasError()) {
    const payload = {
      _id: projectId,
      assigned_client: state.assignedClient ?? null,
    };

    dispatch(projectActions.onUpdateOneRequest(payload));
    // }
  }, [dispatch, projectId, state]);

  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        {isProjectEditable && (
          <Button
            className="new_button mr5"
            type="primary"
            onClick={onSubmit}
            size="large"
            loading={projectObj?.loading}
          >
            Update
          </Button>
        )}
        <Button
          className="new_button"
          type="primary-outline"
          onClick={props?.handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [props?.handleModal, isProjectEditable, onSubmit, projectObj?.loading]);

  return (
    <CustomModal
      title="Assign Client"
      className="select-manager-modal-container"
      visible={visible}
      footer={renderFooter}
      onCancel={props.handleModal}
    >
      <Col>
        {/* assign manager start */}
        <Col className="mb15">
          <Col className="mb5">
            <label className="label">Client</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>

          <Col>
            <Select
              size="large"
              className="fw wpsb"
              placeholder="Select Client"
              value={state.assignedClient}
              suffixIcon={<CaretDownOutlined className="f15" />}
              onChange={handleChange("assignedClient")}
              optionFilterProp="children"
              filterOption={onFilterOption}
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              showSearch
            >
              {users.map((userMap, index) => {
                const user = new User().fromMap(userMap);

                return (
                  <Select.Option key={index} value={user._id} user={user}>
                    <Avatar
                      size="small"
                      style={{ backgroundColor: getAvatarColor(user._id) }}
                    >
                      {getNameInitials(
                        user.fullName ? user.fullName : user.email
                      )}
                    </Avatar>

                    <span className="ml10">
                      {user.fullName
                        ? `${user.fullName} (${user.email})`
                        : user.email}
                    </span>
                  </Select.Option>
                );
              })}
            </Select>

            {/* <Col className="error">{error.assignedClient}</Col> */}
          </Col>
        </Col>
        {/* assign manager end */}
      </Col>
    </CustomModal>
  );
};

export default React.memo(SelectClientModal);
