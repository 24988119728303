import React, { Component } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { Button, DatePicker, Input, InputNumber, Select, Upload } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import "./formstyle.css";

const ReactGridLayout = WidthProvider(RGL);

export default class GridLayoutFormBuilder extends Component {
  state = {
    activeId: null,
  };

  onLayoutChange = (layout) => {
    this.props.onLayoutChange(layout);
  };

  getFieldData = (e, type, field) => {
    e.preventDefault();
    let el = document.getElementsByClassName("app-content-block");
    let top = type === "textarea" ? 43 : 83;
    // console.log(e.target)
    let pos = {
      top: top, //(top+el[0].scrollTop) - top,
      left: e.clientX - (field.dataType === "number" ? 450 : 600),
    };
    this.setState({ activeId: field.id });
    this.props.togglePropertiesModal(pos, true, field);
  };

  render() {
    const { allFields, layout, deleteField } = this.props;

    // console.log('++++++++>',this.state.activeId)

    return (
      <ReactGridLayout
        rowHeight={10}
        cols={24}
        width={10}
        layout={layout}
        onLayoutChange={(e) => this.onLayoutChange(e)}
        draggableHandle=".gridDragHandler"
        draggableCancel=".MyDragCancel"
      >
        {allFields &&
          Array.isArray(allFields) &&
          allFields.map((field) => {
            switch (field.dataType) {
              case "text":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <Input
                      size="large"
                      className="custom-input"
                      allowClear
                      placeholder={
                        field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText
                      }
                    />
                  </div>
                );
              case "number":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <br />
                    <InputNumber
                      size="large"
                      className="custom-input fw"
                      allowClear
                      placeholder={
                        field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText
                      }
                    />
                  </div>
                );
              case "dropdown":
              case "select":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <Select
                      className="custom-select fw"
                      value={undefined}
                      size="large"
                      allowClear
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={
                        field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText
                      }
                    >
                      {field.options.map((opt) => (
                        <Select.Option disabled key={opt}>
                          {opt}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                );
              case "textarea":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <Input.TextArea
                      placeholder={
                        field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText
                      }
                      autosize={{ minRows: 3, maxRows: 6 }}
                      className="custom-input"
                    />
                  </div>
                );
              case "date":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <br />
                    <DatePicker
                      size="large"
                      placeholder={
                        field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText
                      }
                      className="fw custom-input"
                    />
                  </div>
                );

              case "file":
                return (
                  <div
                    className={
                      field.id === this.state.activeId
                        ? "item gridDragHandler active"
                        : "item gridDragHandler"
                    }
                    key={field.id}
                  >
                    <span className="edit">
                      <EditOutlined
                        onClick={(e) =>
                          this.getFieldData(e, field.dataType, field)
                        }
                      />
                      <DeleteOutlined onClick={(e) => deleteField(field.id)} />
                    </span>
                    <label>
                      {field.alias_name ? field.alias_name : field.name}
                      {field.mandatory && <sup>*</sup>}
                    </label>
                    <br />
                    <Upload>
                      <Button size="large">
                        <UploadOutlined />
                        {field.alias_help_text
                          ? field.alias_help_text
                          : field.helpText}
                      </Button>
                    </Upload>
                  </div>
                );

              default:
              // return (
              //     <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
              //   <span className='edit'>
              //     <EditOutlined onClick={e=>this.getFieldData(e, field.dataType, field)}/>
              //     <DeleteOutlined onClick={e => deleteField(field.id)}/>
              //   </span>
              //         <label>
              //             {field.alias_name ? field.alias_name : field.name}
              //             {
              //                 field.mandatory && <sup>*</sup>
              //             }
              //         </label>
              //         <Input size='large' className='custom-input' allowClear placeholder={field.alias_help_text ? field.alias_help_text : field.helpText} />
              //     </div>
              // )
            }
          })}
      </ReactGridLayout>
    );
  }
}
